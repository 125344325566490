import SirketTeminatCoreModel from './sirket-teminat-core-model';


export default class SirketTeminatResultModel {
  anahtarKaybi: SirketTeminatCoreModel[];
  imm: SirketTeminatCoreModel[];
  ferdiKaza: SirketTeminatCoreModel[];
  depremSel: SirketTeminatCoreModel[];
  hukuksalKoruma: SirketTeminatCoreModel[];
  ikameArac: SirketTeminatCoreModel[];
  meslek: SirketTeminatCoreModel[];
  maneviTazminat: SirketTeminatCoreModel[];
  yetkiliServis: SirketTeminatCoreModel[];
  kiralikArac: SirketTeminatCoreModel[];
  camMuafiyeti: SirketTeminatCoreModel[];
  yanlisAkaryakitDolumu: SirketTeminatCoreModel[];
}
