import {UrunWSBilgiModel} from '../../Urun/urun-wsbilgi-model';
import {Trafikonbilgi} from '../../OnBilgi/Trafik/trafikonbilgi';
import {TrafikUrunResultModel} from '../../Urun/trafik-urun-result-model';

export class TrafikTeklifRequestModel {
  onBilgi: Trafikonbilgi;
  urun: TrafikUrunResultModel;
  urunWSBilgi: UrunWSBilgiModel;
  uniqueTeklifNo: string;
}
