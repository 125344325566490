export class TeklifListRequestModel {
  sigortaliKimlikNo: string;
  plaka: string;
  hizliTeklifNo: string;
  baslangicTarihi: any;
  bitisTarihi: any;
  sorgulayanKullaniciId: number;
  filtreIsOrtagiId: number;
  sadeceBasariliTeklifleriGetir: boolean;
  sorguTipi: number;
  authToken: any;
}

