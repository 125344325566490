import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Sehir } from "../models/Adres/sehir";
import { Ilce } from "../models/Adres/ilce";
import { Marka } from "../models/Arac/marka";
import { Model } from "../models/Arac/model";
import { SigortaSirket } from "../models/SigortaSirket/sigorta-sirket";
import {StandartTeminatListRM} from '../models/Teminat/Kasko/StandartTeminat/standart-teminat-list-rm';
import KaskoUrunResultModel from '../models/Urun/kasko-urun-result-model';
import SirketTeminatResultModel from '../models/Teminat/Kasko/SirketTeminat/sirket-teminat-result-model';
import EslestirilenTeminatList from '../models/Teminat/Kasko/StandartTeminat/eslestirilen-teminat-list';
import {TrafikUrunResultModel} from '../models/Urun/trafik-urun-result-model';
import {kaskoCoreApiBaseUrl, trafikCoreApiBaseUrl, uiHelperApiBaseUrl} from '../../environments/environment';
import KaskoOnBilgi from '../models/OnBilgi/Kasko/kaskoonbilgi';
import StdteminateslestirmeRequestmodel from '../models/Teminat/Kasko/StandartTeminat/stdteminateslestirme-requestmodel';
import EslestirilenOdemeList from '../models/Odeme/standartOdeme/eslestirilenOdemeList';
import { StandartOdemeListRM } from '../models/Odeme/standartOdeme/standartOdemeListRm';
import { StandartOdeme } from '../models/Odeme/standartOdeme/standartOdeme';
import StdOdemeEslestirmeRequestModel from '../models/Odeme/standartOdeme/stdOdemeEslestirmeRequestModel';



@Injectable({
  providedIn: 'root'
})
export class UihelperService {
kaskoOnBilgi: KaskoOnBilgi;
standartTeminatlar: StandartTeminatListRM;
kaskoUrunResult: KaskoUrunResultModel[];
sirketTeminatResultModel: SirketTeminatResultModel;

  constructor(private http: HttpClient) {
    this.kaskoOnBilgi = new KaskoOnBilgi();
    this.standartTeminatlar = new StandartTeminatListRM();
    this.kaskoUrunResult = [];
    this.sirketTeminatResultModel = new SirketTeminatResultModel();
  }

  sehirleriGetir(): Observable<Sehir[]> {
    return this.http.get<Sehir[]>(`${uiHelperApiBaseUrl}api/IlleriGetir`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  ileGoreIlceGetir(ilKodu: any): Observable<Ilce[]> {
    return this.http.post<Ilce[]>(`${uiHelperApiBaseUrl}api/IleGoreIlceGetir`,
      {
        IlKodu: ilKodu
      }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  markalariGetir(): Observable<Marka[]> {
    return this.http.get<Marka[]>(`${uiHelperApiBaseUrl}api/MarkalariGetir`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  markayaGoreModelGetir(aracMarkaKodu: string): Observable<Model[]> {
    return this.http.post<Model[]>(`${uiHelperApiBaseUrl}api/MarkayaGoreModelGetir`,
      {
        MarkaKodu: aracMarkaKodu
      }).pipe(
      map((response: Model[]) => {
        return response;
      })
    );
  }


  sigortaSirketleriniGetir(): Observable<SigortaSirket[]> {
    return this.http.get<SigortaSirket[]>(`${uiHelperApiBaseUrl}api/SigortaSirketleriniGetir`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }


  standartTeminatlariGetir(): Observable<StandartTeminatListRM> {
    return this.http.get<StandartTeminatListRM>(`${kaskoCoreApiBaseUrl}api/StandartTeminatlariGetir`).pipe(
      map((response: StandartTeminatListRM) => {
        this.standartTeminatlar.anahtarKaybi = response.anahtarKaybi;
        this.standartTeminatlar.depremSel = response.depremSel;
        this.standartTeminatlar.ferdiKaza = response.ferdiKaza;
        this.standartTeminatlar.hukuksalKoruma = response.hukuksalKoruma;
        this.standartTeminatlar.ikameArac = response.ikameArac;
        this.standartTeminatlar.imm = response.imm;
        this.standartTeminatlar.maneviTazminat = response.maneviTazminat;
        this.standartTeminatlar.meslek = response.meslek;
        this.standartTeminatlar.yetkiliServis = response.yetkiliServis;
        this.standartTeminatlar.kiralikArac = response.kiralikArac;

        return this.standartTeminatlar;
      })
    );
  }
  standartOdemeleriGetir(): Observable<StandartOdeme[]> {
    return this.http.get<StandartOdeme[]>(`${kaskoCoreApiBaseUrl}api/StandartOdemeleriGetir`).pipe(
      map((response: StandartOdeme[]) => {
        console.log(response)
        return response;
      })
    );
  }

  standartTeminatEslestirmeleriniGetir(reuqestModel: StdteminateslestirmeRequestmodel): Observable<EslestirilenTeminatList> {
    return this.http.post<EslestirilenTeminatList>(`${kaskoCoreApiBaseUrl}api/StandartTeminatEslestir`,
      {
        AracTarifeGrupKodu: reuqestModel.AracTarifeGrupKodu,
        StandartTeminatIdArray: reuqestModel.StandartTeminatIdArray
      }
      ).pipe(
      map((response: EslestirilenTeminatList) => {
        console.log(response)
        return response;
      })
    );
  }
  standartOdemeEslestirmeleriniGetir(reuqestModel: StdOdemeEslestirmeRequestModel): Observable<EslestirilenOdemeList> {
    return this.http.post<EslestirilenOdemeList>(`${kaskoCoreApiBaseUrl}api/StandartOdemeEslestir`,
      {
        standartOdemeIdArray: reuqestModel.StandartOdemeIdArray
      }
      ).pipe(
      map((response: EslestirilenOdemeList) => {
        console.log(response)
        return response;
      })
    );
  }
  

  kaskoUrunleriniGetir(): Observable<KaskoUrunResultModel[]> {
    return this.http.get<KaskoUrunResultModel[]>(`${kaskoCoreApiBaseUrl}api/KaskoUrunleriniGetir`).pipe(
      map((response: KaskoUrunResultModel[]) => {
        return response;
      })
    );
  }
  spKaskoUrunleriniGetir(token:string): Observable<KaskoUrunResultModel[]> {
    return this.http.post<KaskoUrunResultModel[]>(`${kaskoCoreApiBaseUrl}api/SpKaskoUrunleriniGetir`,
      {
        AuthToken:token
      }
    ).pipe(
      map((response: KaskoUrunResultModel[]) => {
        return response;
      })
    );
  }
  trafikUrunleriniGetir(): Observable<TrafikUrunResultModel[]> {
    return this.http.get<TrafikUrunResultModel[]>(`${trafikCoreApiBaseUrl}api/TrafikUrunleriniGetir`).pipe(
      map((response: TrafikUrunResultModel[]) => {
        return response;
      })
    );
  }
  SpTrafikUrunleriniGetir(token:string): Observable<TrafikUrunResultModel[]> {
    return this.http.post<TrafikUrunResultModel[]>(`${trafikCoreApiBaseUrl}api/SpTrafikUrunleriniGetir`,
    {
      AuthToken:token
    }
    ).pipe(
      map((response: TrafikUrunResultModel[]) => {
        return response;
      })
    );
  }

  localUniqueTeklifNoUret(bransKodu: string): string{
    var result = '';
    var chars = '0123456789';
    for (var i = 8; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return `${bransKodu}${result}`;
  }
}
