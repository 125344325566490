import { Title } from '@angular/platform-browser';
import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuthService } from './service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {  faBuilding, faCarCrash, faChevronCircleDown, faClipboardList, faHandsHelping, faSignOutAlt, faThList, faTrafficLight, faUser, } from '@fortawesome/free-solid-svg-icons';
import { HomeComponent } from './components/home/home.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  helpMenuVisbility=false
  subMenuVisbility=false
  sidenavOpened=false
  caretCircleDownIcon=true
  @ViewChild(HomeComponent) home;
  faBuilding=faBuilding
  faCaretCircleDown=faChevronCircleDown
  faTraffic=faTrafficLight
  faKasko=faCarCrash
  faTeklifListesi=faThList
  faLogin=faUser
  faLogout=faSignOutAlt
  faHandsHelping=faHandsHelping
  faClipboardList=faClipboardList
  kullaniciAdi: string
  ngOnInit(): void {
    this.getUser()
  }
  headerTitle: string
  loadingPage=false
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private router: Router, 
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private title: Title,
) { }
  name: any
  navbarAtributeVisibility=true

  logout() {
    this.auth.logOut();
  }
  /*Rootun hangi komponentte olduğunu öğrendiğimiz komponent*/
  onActivate(componentReference) {
    this.headerTitle=componentReference.headerTitle
    if (componentReference.headerTitle==="Login"||componentReference.headerTitle==="") {
      this.navbarAtributeVisibility=false
    }
    else{
      this.navbarAtributeVisibility=true
    }
  }
  
  isLoggedin(): boolean {
    // return this.auth.acountLoggedIn()  
    return !!localStorage.getItem("access_token")
  }

  getUser() {
    this.kullaniciAdi = localStorage.getItem("userName")
  }
  /* Sidebarı açan metod */
  drawerOpen(){
    if (this.navbarAtributeVisibility) {
    this.sidenavOpened=!this.sidenavOpened;
    this.caretCircleDownIcon=true;
    this.subMenuVisbility=false;
    this.helpMenuVisbility=false;    
      document.getElementById("drawerOpen").click()
    }
  }
  subMenuOpen(){
    if (this.subMenuVisbility===true) {
      this.subMenuVisbility=false
    }else{
      this.subMenuVisbility=true;
    }
  }
  openRequest(){
    window.open('https://sigortasepeti.teamwork.com/support', '_blank')
  }
}

