import StandartTeminat from './standartteminat';

export class StandartTeminatListRM {
  anahtarKaybi: StandartTeminat[];
  imm: StandartTeminat[];
  ferdiKaza: StandartTeminat[];
  depremSel: StandartTeminat[];
  hukuksalKoruma: StandartTeminat[];
  ikameArac: StandartTeminat[];
  meslek: StandartTeminat[];
  maneviTazminat: StandartTeminat[];
  yetkiliServis: StandartTeminat[];
  kiralikArac: StandartTeminat[];

  constructor() {
    this.anahtarKaybi = [];
    this.imm = [];
    this.ferdiKaza = [];
    this.depremSel = [];
    this.hukuksalKoruma = [];
    this.ikameArac = [];
    this.meslek = [];
    this.maneviTazminat = [];
    this.yetkiliServis = [];
    this.kiralikArac = [];

  }
}
