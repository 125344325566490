import SirketTeminatCoreModel from '../SirketTeminat/sirket-teminat-core-model';
// @ts-ignore
import {Hata} from '../../../OnBilgi/hata';

export default class EslestirilenTeminatList {
  eslestirilenTeminatlar: SirketTeminatCoreModel[];
  basariliMi: any;
  hata: Hata;

  constructor() {
    this.eslestirilenTeminatlar = [];
    this.hata = new Hata();
  }
}
