import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { LoginRequestModel } from 'src/app/models/Auth/Login/login-request-model';
import { AuthService } from 'src/app/service/auth.service';
import { LoginResponseModel } from 'src/app/models/Auth/Login/login-response-model';
import {    faExclamationCircle, faKey, faUser } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  faUser=faUser
  faPassword=faKey
  exclamationCircle=faExclamationCircle

  isValidFormSubmitted = null;
  matSpinner:boolean=false
  headerTitle="Login"
  uyeGirisFormGroup: FormGroup;
  loginRM: LoginRequestModel;
  loginResponseModel: LoginResponseModel;
  reCaptchaKey: any;
  userName:string
  validationMessage:string

  constructor( private _authService: AuthService, private _snackBar: MatSnackBar, private _formBuilder: FormBuilder, private router: Router,private title:Title) {
    title.setTitle("SigortaSepeti")
    this.loginRM = new LoginRequestModel();
    this.loginResponseModel = new LoginResponseModel();
  }

  ngOnInit(): void {
    this.uyeGirisFormGroup = new FormGroup({
      emailInputElement: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern("^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$")
        ])),
      sifreInputElement: new FormControl('',
        Validators.compose([
          Validators.required
        ])),
      recaptchaReactive: new FormControl('',Validators.required)
    });

  }

  hide = true;

  resolved(captchaResponse: string) {
    this.loginRM.captchaToken = captchaResponse;
  }

  Login(): void{
    this.isValidFormSubmitted = false;
    if (this.uyeGirisFormGroup.invalid) {
      this.validationCall()
      return
    }
    this.validationMessage=""
    this.matSpinner=true
    this.isValidFormSubmitted = false;

    this.userName=this.loginRM.email
    console.log(this.userName)
    localStorage.setItem("userName",this.userName);
    this._authService.login(this.loginRM).subscribe(
      (data) => {
        this.loginResponseModel = data;

        if (data.result) {
          localStorage.setItem('access_token', this.loginResponseModel.token);
          this.matSpinner=false
          this.router.navigate(["onlineislemler"]);
        }else{
          this.matSpinner=false

          console.log(this.loginResponseModel)
          this._snackBar.open('' + this.loginResponseModel.errorMessage, 'Kapat', {
            duration: 5000,
          });
        }
      },
      (error) => {
        this.matSpinner=false

        this.loginResponseModel = error;
        console.log(this.loginResponseModel)
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 5000,
        });
      }
    );
  }
  validationCall(){
    if (this.uyeGirisFormGroup.get('recaptchaReactive').hasError('required')) {
      this.validationMessage="Lütfen recaptcha bilgisi giriniz"
    }
    if (this.uyeGirisFormGroup.get('sifreInputElement').hasError('required')) {
      this.validationMessage="Lütfen şifre bilginizi giriniz"
    }
    if (this.uyeGirisFormGroup.get('emailInputElement').hasError('pattern')) {
      this.validationMessage="Lütfen formatlı mail bilgisi giriniz"
    }
    if (this.uyeGirisFormGroup.get('emailInputElement').hasError('required')) {
      this.validationMessage="Lütfen mail bilgisi giriniz"
    }
  }
}
