import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SirketEkranLinkResponse } from 'src/app/models/SigortaSirket/sirket-ekran-link-response';
import { SirketAuthService } from '../../service/sirket-auth.service';

@Component({
  selector: 'app-sirketler',
  templateUrl: './sirketler.component.html',
  styleUrls: ['./sirketler.component.css']
})
export class SirketlerComponent implements OnInit {
  headerTitle = " "
  link="Link"
  faSearch=faSearch
  faTimes=faTimes
  token:string
  text:string

  ekranBilgiList:SirketEkranLinkResponse[]
  constructor(private title:Title,private sirketAuthApiBaseUrl:SirketAuthService) { 
    title.setTitle("SigortaSepeti");
    this.token=localStorage.getItem("access_token")
    this.SirketEkranBilgileriniGetir()
  }
  ngOnInit(): void { }
  
  resetText(){ this.text=''}

  SirketEkranBilgileriniGetir(){    
    this.sirketAuthApiBaseUrl.SirketEkranBilgileriniGetir(this.token).subscribe(response=>{
      this.ekranBilgiList = response.ekranBilgiList;
      this.sortedString(this.sortIsOrtagi)
    })
  }

  sendToken(adres:string){
    if(adres!=null){
      window.open(adres, "_blank")
    }    
  }

  sortIsOrtagi:Sort={
    active: "linkAdi",
    direction: "asc"
  }
  sortedString(sort: Sort) {//İş Ortağı sort eden metod
    var data = this.ekranBilgiList.slice();
    if (!sort.active || sort.direction === '') {
      this.ekranBilgiList = data;
      return;
    }    
    this.ekranBilgiList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'linkAdi': return this.compare(a.linkAdi, b.linkAdi, isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a=='string' && typeof b=='string') {
      return a.localeCompare(b,"tr") * (isAsc ? 1 : -1);      
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
