import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import KaskoTeklifRequestModel from '../models/Teklif/Kasko/kasko-teklif-request-model';
import {Observable} from 'rxjs';
import {kaskoCoreApiBaseUrl} from '../../environments/environment';
import {map} from 'rxjs/operators';
import { UniqueTeklifNoRequestModel } from '../models/Teklif/Trafik/unique-teklif-no-request-model';
import KaskoOnBilgi from '../models/OnBilgi/Kasko/kaskoonbilgi';

@Injectable({
  providedIn: 'root'
})
export class KaskoteklifService {

  constructor(private http: HttpClient) { }

  kaskoTeklifAl(kaskoTeklifRequest: KaskoTeklifRequestModel): Observable<any> {
    return this.http.post<any>(`${kaskoCoreApiBaseUrl}api/Kasko/TeklifAl`,
      {
        onBilgi: kaskoTeklifRequest.onBilgi,
        urun: kaskoTeklifRequest.urun,
        urunWsBilgi: kaskoTeklifRequest.urunWSBilgi,
        uniqueTeklifNo: kaskoTeklifRequest.uniqueTeklifNo
      }
    ).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  kaskoUniqueTeklifNoAl(requestModel: UniqueTeklifNoRequestModel): Promise<any> {
    return this.http.post<any>(`${kaskoCoreApiBaseUrl}api/KaskoUniqueTeklifNoUret`,
      {
        authToken: requestModel.authToken,
        sigortaliId: requestModel.sigortaliId,
        sigortaliAracId: requestModel.sigortaliAracId
      }
    ).toPromise();
  }

}
