import Arac from "../arac";
import { Hata } from "../hata";
import { Sigortali } from "../sigortali";


export default class KaskoOnBilgi {
  Sigortali: Sigortali;
  Arac: Arac;
  Hata: Hata;
  BasariliMi: boolean;
  PoliceVadesiGecerliMi: any;
  Token: string;
  ClaimType: any;
  ToplamSorguSuresi: any;
  SorgulayanKullaniciId: any;
  TeklifId: any;  
}
