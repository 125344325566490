import { Router } from '@angular/router';
import { Component, enableProdMode, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import 'jspdf-autotable';
import { Title } from '@angular/platform-browser';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import { TeklifListRequestModel } from 'src/app/models/Teklif/List/teklif-list-request-model';
import { IsOrtagiFilterResultModel } from 'src/app/models/Teklif/List/is-ortagi-filter-result-model';
import { SigortaliFilterResultModel } from 'src/app/models/Teklif/List/sigortali-filter-result-model';
import { LoadingService } from 'src/app/service/loadService/loading.service';
import { TekliflistserviceService } from 'src/app/service/tekliflistservice.service';
import { AuthService } from 'src/app/service/auth.service';
import { TeklifListRM } from 'src/app/models/Teklif/List/teklif-list-rm';
import { Sort } from '@angular/material/sort';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: 'app-tekliflist',
  templateUrl: './tekliflist.component.html',
  styleUrls: ['./tekliflist.component.css']
})
export class TekliflistComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  headerTitle = "Teklif Listesi"

  onSpinner = false;
  isAsc: boolean = true
  kimlikNo: string;
  plaka: string;
  hizliTeklifNo: string;
  selectedSorguTipi: number;
  sigortaliSelectsVsblty = false

  baslangicTarihi: any;
  bitisTarihi: any;
  selectedTarih: any
  selectedSadeceBasariliTeklifleriGetir = true;
  teklifListeleRequestModel: TeklifListRequestModel;
  myControl = new FormControl();
  teklifListForm: FormGroup;
  isOrtagiFilterData: IsOrtagiFilterResultModel[];
  selectedIsOrtagiFilter: IsOrtagiFilterResultModel;
  sigortaliFilterData: SigortaliFilterResultModel[];
  sigortaliFilterDataTmp: SigortaliFilterResultModel[];
  filteredOptions!: Observable<SigortaliFilterResultModel[]>;

  dataSource: TeklifListRM[];
  sorguTipleri = [
    { id: 0, tanim: 'Trafik' },
    { id: 1, tanim: 'Kasko' },
    { id: 2, tanim: 'Hepsi' },
  ];
  defaultDate = new Date
  date = new Date
  yesterdayDate = new Date
  curr = new Date; // get current date
  first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
  monday = this.first + 1
  last = this.first + 5; // last day is the first day + 6

  oneMonthAgo = new Date(this.date.setDate(this.date.getDate() - 29));
  yesterday = new Date(this.yesterdayDate.setDate(this.yesterdayDate.getDate() - 1));
  firstdayOfWeek = new Date(this.curr.setDate(this.monday));
  lastdayOfWeek = new Date(this.curr.setDate(this.last));
  lastFift = new Date()
  lastFifteen = new Date(this.lastFift.setDate(this.lastFift.getDate() - 14));
  lastSev = new Date()
  lastSeven = new Date(this.lastSev.setDate(this.lastSev.getDate() - 6));

  dateForLastWeek = new Date; // get current date
  firstDay = this.dateForLastWeek.getDate() - this.dateForLastWeek.getDay(); // First day is the day of the month - the day of the week
  mondayForLastWeek = this.first - 6;
  lastWeekSunday = new Date(this.dateForLastWeek.setDate(this.first));
  lastWeekMonday = new Date(this.dateForLastWeek.setDate(this.mondayForLastWeek));

  dateForLastMontFinish = new Date();
  lastMonthFinish = new Date(this.dateForLastMontFinish.setMonth(this.dateForLastMontFinish.getMonth() - 1));
  dateForLastMontbegin = new Date();
  lastMonthBegin = new Date(this.dateForLastMontbegin.setMonth(this.dateForLastMontbegin.getMonth() - 2));
  thisYear = new Date().getFullYear()
  thisMounth = new Date().getMonth()
  girilenTarihAraligi = [
    { id: 0, tanim: 'Seçiniz' },
    { id: 1, tanim: 'Bugün', baslangicTarihi: new Date(this.defaultDate.toString().slice(0, 15)), bitisTarihi: new Date(this.defaultDate.toString().slice(0, 15)) },
    { id: 2, tanim: 'Dün', baslangicTarihi: new Date(this.yesterday.toString().slice(0, 15)), bitisTarihi: new Date(this.yesterday.toString().slice(0, 15)) },
    { id: 3, tanim: 'Bu Hafta (Pazartesi-Bugün)', baslangicTarihi: new Date(this.firstdayOfWeek.toString().slice(0, 15)), bitisTarihi: new Date() },
    { id: 4, tanim: 'Son 7 Gün', baslangicTarihi: new Date(this.lastSeven.toString().slice(0, 15)), bitisTarihi: new Date() },
    { id: 5, tanim: 'Geçen Hafta(Pazatesi-Pazar)', baslangicTarihi: new Date(this.lastWeekMonday.toString().slice(0, 15)), bitisTarihi: new Date(this.lastWeekSunday.toString().slice(0, 15)) },
    { id: 6, tanim: 'Son 15 Gün', baslangicTarihi: new Date(this.lastFifteen.toString().slice(0, 15)), bitisTarihi: new Date() },
    { id: 7, tanim: 'Bu Ay', baslangicTarihi: new Date(this.thisYear, this.thisMounth, 1), bitisTarihi: new Date(this.thisYear, this.thisMounth + 1, 0) },
    { id: 8, tanim: 'Son 30 Gün', baslangicTarihi: this.oneMonthAgo, bitisTarihi: new Date() },
    { id: 9, tanim: 'Geçen Ay', baslangicTarihi: new Date(this.thisYear, this.thisMounth - 1, 1), bitisTarihi: new Date(this.thisYear, this.thisMounth, 0) },
    { id: 10, tanim: 'Bu Yıl', baslangicTarihi: new Date(this.thisYear, 0, 1), bitisTarihi: new Date(this.thisYear, 12, 0) },
    { id: 11, tanim: 'Geçen Yıl', baslangicTarihi: new Date(this.thisYear - 1, 0, 1), bitisTarihi: new Date(this.thisYear - 1, 12, 0) },

  ];

  range = new FormGroup({
    baslangicTarihiFormElement: new FormControl(),
    bitisTarihiFormElement: new FormControl(),
    tarihAraligi: new FormControl()
  });

  constructor(
    public loadingService: LoadingService,
    private title: Title,
    private _formBuilder: FormBuilder,
    private _teklifListService: TekliflistserviceService,
    private _snackBar: MatSnackBar,
    @Inject(HttpClient) httpClient: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {
    title.setTitle("SigortaSepeti")
    this.isOrtagiFilterData = [];
    this.selectedIsOrtagiFilter = new IsOrtagiFilterResultModel();
  }
  plakaRegex = "((\\s){0,25})(0[1-9]|[1-7][0-9]|8[01])(([A-Za-z])(\\d{4,5})((\\s){0,25})|([A-Za-z]{2})(\\d{3,4})((\\s){0,25})|([A-Za-z]{3})(\\d{2,3}))((\\s){0,25})"
  teklifNoPattern = "((\\s){0,25})([A-Za-z]{1})(\\d{8})((\\s){0,25})$";
  authToken = localStorage.getItem('access_token');
  ngOnInit(): void {
    this.girilenTarihAraligi.forEach(tarihAraligi => {
      if (tarihAraligi.id === 7) {
        this.selectedTarih = tarihAraligi
      }
    })

    this.selectedSorguTipi = 2;//default hepsi seçeneğinin gelmesi için 2 yazıldı

    this.teklifListForm = this._formBuilder.group({
      bransh: ['', Validators.required],
      isOrtagiControl: [''],
      tarihAraligi: [''],
      plakaInput: new FormControl("", Validators.compose([Validators.pattern(this.plakaRegex)])),
      hizliTeklifNoInput: new FormControl("", Validators.compose([Validators.pattern(this.teklifNoPattern)]))
    });

    

    this._teklifListService.isOrtagiListResultGetir(this.authToken).subscribe(
      (data) => {
        /*console.log(data);*/
        this.isOrtagiFilterData = data.slice();
        this.sortedString(this.sortIsOrtagi)
        console.log(this.isOrtagiFilterData);
        this.isOrtagiFilterData.forEach(item => {
          if (item.id == -1) {
            this.selectedIsOrtagiFilter = item;
          }
        })
      }
    );
  }

  brutPrimColumnCustomizeText(cellInfo) {
    console.log(cellInfo)
    return cellInfo.valueText + " TL";
  }
  private _filterKimlikNo(value: string): SigortaliFilterResultModel[] {
    const filterValue = value.toLowerCase();
    return this.sigortaliFilterDataTmp.filter(option => option.kimlikNo.toLowerCase().includes(filterValue));
  }

  private _filterAdUnvan(value: string): SigortaliFilterResultModel[] {
    const filterValue = value.toLowerCase();
    return this.sigortaliFilterDataTmp.filter(option => option.adUnvan.toLowerCase().includes(filterValue));
  }
  onSigortaliChange(sigortali: string) {
    console.log(sigortali)
    this.sigortaliSelectsVsblty = sigortali?.length >= 3 ? true : false
    if (sigortali?.length > 3 ) {
      if (isNaN(parseInt(sigortali)))
        this.sigortaliFilterData = this._filterAdUnvan(sigortali);
      else
        this.sigortaliFilterData = this._filterKimlikNo(sigortali);
    }
    if (sigortali?.length==3) {
      this._teklifListService.sigortaliFilterResultGetir(this.authToken,sigortali).subscribe(
        (data) => {
          console.log(data)
          this.sigortaliFilterData = data;
          this.sigortaliFilterDataTmp = data;
        }
      );
    }
  }
  onChangeBaslangicTarihi(bslTarih: any): void {
    console.log(bslTarih)
    this.baslangicTarihi = bslTarih;
    let equalHistoryNum = 0
    this.girilenTarihAraligi.forEach(tarihAraligi => {
      if (tarihAraligi.baslangicTarihi === this.baslangicTarihi) {
        equalHistoryNum++
      }
    })

    if (equalHistoryNum === 0) {
      console.log(equalHistoryNum)
      this.girilenTarihAraligi.forEach(tarihAraligi => {
        if (tarihAraligi.id === 0) {
          this.selectedTarih = tarihAraligi
        }
      })
    }
  }

  onChangeBitisTarihi(btsTarih: any): void {
    this.bitisTarihi = btsTarih;
    let equalHistoryNum = 0
    this.girilenTarihAraligi.forEach(tarihAraligi => {
      if (tarihAraligi.bitisTarihi === this.bitisTarihi) {
        equalHistoryNum++
      }
    })

    if (equalHistoryNum === 0) {
      console.log(equalHistoryNum)
      this.girilenTarihAraligi.forEach(tarihAraligi => {
        if (tarihAraligi.id === 0) {
          this.selectedTarih = tarihAraligi
        }
      })
    }
  }

  teklifListesiniTemizle(): void {
    this.dataSource = [];
  }
  tokenGecerliMi() {
    let reqToken = localStorage.getItem('access_token');
    if (reqToken === null || reqToken === '' || reqToken == undefined) {
      this.router.navigate(["login"]);
    }
    this.authService.isValidateToken(reqToken).subscribe(
      (data) => {
        if (data.result) {
          console.log("Token geçerli")
          return true;
        } else {
          this.router.navigate(["login"]);
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
  }

  filtrelemeFormunuTemizle(): void {
    this.tokenGecerliMi()
    this.selectedSorguTipi = 2;
    this.kimlikNo = '';
    this.plaka = '';
    this.hizliTeklifNo = '';
    this.girilenTarihAraligi.forEach(tarihAraligi => {
      if (tarihAraligi.id === 7) {
        this.selectedTarih = tarihAraligi
      }
    })
    this.isOrtagiFilterData.forEach(item => {
      if (item.id == -1) {
        this.selectedIsOrtagiFilter = item;
      }
    })
    this.dataSource = null
  };
  expandAllMasterRows() {
    this.dataGrid.instance.expandAll(-1);
  }
  collapseAllMasterRows() {
    this.dataGrid.instance.collapseAll(-1);
  }
  filtreleOnClick(): void {
    this.tokenGecerliMi()
    this.teklifListesiniTemizle();
    if (!this.teklifListForm.valid) {
      return
    }
    console.log(this.kimlikNo + "  " + this.plaka + "  " + this.hizliTeklifNo)

    this.collapseAllMasterRows() //exppand etmesi için çalıştırıldı
    if ((this.kimlikNo == '' || this.kimlikNo == undefined) && (this.plaka == '' || this.plaka == undefined) && (this.hizliTeklifNo == '' || this.hizliTeklifNo == undefined) && (this.baslangicTarihi == undefined || this.bitisTarihi == undefined)) {
      return;
    }
    this.onSpinner = true;
    this.teklifListeleRequestModel = new TeklifListRequestModel();

    if (typeof this.kimlikNo === 'string' && this.kimlikNo != '' || typeof this.plaka === 'string' && this.plaka != '' || typeof this.hizliTeklifNo === 'string' && this.hizliTeklifNo != '') {
      this.baslangicTarihi = new Date(2020, 0, 1)
      this.bitisTarihi = new Date()
      this.selectedSorguTipi = 2;
      this.isOrtagiFilterData.forEach(item => {
        if (item.id == -1) {
          this.selectedIsOrtagiFilter = item;
        }
      })
    }

    if (typeof this.kimlikNo === 'string' && this.kimlikNo != '') {
      this.teklifListeleRequestModel.sigortaliKimlikNo = this.kimlikNo.slice(0, 11).trim();
      // this.teklifListeleRequestModel.sigortaliKimlikNo =this.teklifListeleRequestModel.sigortaliKimlikNo.slice(0,10).trim();
      console.log(this.teklifListeleRequestModel.sigortaliKimlikNo)
    }
    if (typeof this.plaka === 'string' && this.plaka != '') {
      this.teklifListeleRequestModel.plaka = this.plaka.trim();
      /*console.log(this.teklifListeleRequestModel.plaka)*/
    }
    if (typeof this.hizliTeklifNo === 'string' && this.hizliTeklifNo != '') {
      this.teklifListeleRequestModel.hizliTeklifNo = this.hizliTeklifNo.trim();
      /*console.log(this.teklifListeleRequestModel.hizliTeklifNo)*/
    }

    this.teklifListeleRequestModel.baslangicTarihi = this.baslangicTarihi;
    this.teklifListeleRequestModel.bitisTarihi = this.bitisTarihi;

    this.teklifListeleRequestModel.authToken = localStorage.getItem('access_token');
    this.teklifListeleRequestModel.sadeceBasariliTeklifleriGetir = this.selectedSadeceBasariliTeklifleriGetir;

    this.teklifListeleRequestModel.sorguTipi = this.selectedSorguTipi;
    this.teklifListeleRequestModel.filtreIsOrtagiId = this.selectedIsOrtagiFilter.id;

    this._teklifListService.teklifResultGetir(this.teklifListeleRequestModel).subscribe((data) => {
      if (typeof data === 'object' && data != null) {
        this.dataSource = data;
        console.log(this.dataSource);
        this.onSpinner = false;
      }
    },
      (error) => {
        this.onSpinner = false;
      })
  }

  dateTimeWithHour(dateTime: any) {
    var tarih = new Date(dateTime)
    if (tarih.getDate().toString().length != 1) {
      var setTarih = tarih.getDate() + "." + tarih.getMonth() + "." + tarih.getFullYear() + " " + tarih.getHours() + ":" + tarih.getMinutes()
    }
    else if (tarih.getDate().toString().length == 1) {
      var setTarih = "0" + tarih.getDate() + "." + tarih.getMonth() + "." + tarih.getFullYear() + " " + tarih.getHours() + ":" + tarih.getMinutes()
    }

    console.log(setTarih)
    return setTarih
  }
  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Teklif Listesi');
    worksheet.getRow(1).getCell(5).value = 'Teklif Listesi';
    worksheet.getRow(1).getCell(5).font = { bold: true, size: 16, underline: 'double' };
    worksheet.getRow(1).getCell(5).alignment = { horizontal: 'center' };
    worksheet.getRow(1).getCell(5).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: "e6bebe" } };

    worksheet.getRow(1).getCell(1).value = this.dateTimeWithHour(new Date);
    worksheet.getRow(1).getCell(1).font = { bold: true, size: 11 };
    worksheet.getRow(1).getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: "e6bebe" } };

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      // autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 1 },
      customizeCell: ({ gridCell, excelCell }) => {
        excelCell.font = { bgColor: { argb: 'e6bebe' } }

        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: 'pattern', pattern: 'solid', fgColor: { argb: "BEDFE6" }
          }
        }
        if (gridCell.rowType === "group") {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: "e6bebe" } };
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Teklif Listesi.xlsx');
      });
    });
    e.cancel = true;
  }

  onTarihAraligiChange(girilenTarihAraligi: any): void {
    console.log(girilenTarihAraligi)

    if (girilenTarihAraligi != null && girilenTarihAraligi != undefined && typeof girilenTarihAraligi === 'object') {
      this.selectedTarih = girilenTarihAraligi
      this.baslangicTarihi = girilenTarihAraligi.baslangicTarihi
      this.bitisTarihi = girilenTarihAraligi.bitisTarihi
    }
  }

  // Prim İçin karşılaştırma yapan metod
  sortStringsConsideringCulture(a, b) {
    if (a.length != b.length) {
      return (a.length < b.length ? -1 : 1) * (this.isAsc ? 1 : -1);
    }
    return (a < b ? -1 : 1) * (this.isAsc ? 1 : -1);
  }
  sortIsOrtagi: Sort = {
    active: "kisaAdi",
    direction: "asc"
  }
  sortedString(sort: Sort) {//İş Ortağı sort eden metod
    var dat = this.isOrtagiFilterData.slice();
    if (!sort.active || sort.direction === '') {
      this.isOrtagiFilterData = dat;
      return;
    }

    this.isOrtagiFilterData = dat.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'kisaAdi': return this.compare(a.kisaAdi, b.kisaAdi, isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a == 'string' && typeof b == 'string') {
      return a.localeCompare(b, "tr") * (isAsc ? 1 : -1);
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
