
<div style="width: 450px; ">
  <div style="text-align: center; width: 100%; height: 80px;"><fa-icon [icon]="faUnlock" style="color: #009688; font-size: 55px; "></fa-icon></div> 

    <mat-card class="col-md-12" class="cardBorder">
      <mat-card-header class="col-md-12" 
      style=" background-color: #009688;color:rgb(253, 253, 253); margin: auto; height: 40px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
      <mat-label class="h5 col-md-3" style="margin-top: auto;">Şifremi Olustur</mat-label>
    </mat-card-header>
        <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="validationMessage">
            <fa-icon [icon]="exclamationCircle"></fa-icon>
            <div style="margin-left: 10px;">
              <b>{{validationMessage}}</b>
            </div>
        </div>
      <mat-card-content style="padding: 16px;">
        <form [formGroup]="sifremiOlusturFormGroup" (ngSubmit)="sifreOlustur()" #form="ngForm" style="width: 300px;">
         
            <mat-form-field appearance="outline">
                <mat-label>Şifre</mat-label>
                <span matPrefix ><fa-icon [icon]="faPassword" style="color: #009688; font-size: 15px;margin-right: 5px;">
                </fa-icon> &nbsp;</span>
                <input  matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="sifreOlusturRM.sifre"
                  formControlName="sifreInputElement" >
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Şifreyi Tekrar Giriniz</mat-label>
                <span matPrefix ><fa-icon [icon]="faPassword" style="color: #009688; font-size: 15px;margin-right: 5px;">
                </fa-icon> &nbsp;</span>
                <input  matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="sifreOlusturRM.sifreTekrarla"
                  formControlName="sifreTekrarlaInputElement" >
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>         
                 
  
          <div>
            <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
              siteKey="6Lc7EIUaAAAAALLlnRFAJcS_UfcaaNhbTCCU5YVa">
            </re-captcha>
          </div>
         
          <div style="width: 300px;">
            <button type="submit" mat-raised-button color="primary"
              style="margin-top: 10px;height: 35px; float: right; width: 140px;  background-color: #009688;">
              Şifremi Yenile <span class="material-icons">forward</span>
            </button>
          </div>
  
        </form>
      </mat-card-content>
    </mat-card>
    <br>
    <mat-progress-bar *ngIf="matSpinner" mode="indeterminate"></mat-progress-bar>
  </div>