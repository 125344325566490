export class LoginResponseModel {
  token: string;
  result: boolean;
  errorMessage: string;

  constructor() {
    this.token = "";
    this.result = false;
    this.errorMessage = "";
  }
}
