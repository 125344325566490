import { PhonePipe } from './../../pipes/phone.pipe';
import jspdf, { jsPDF } from 'jspdf';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef, Pipe } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';

import { FormBuilder, FormControl, NgForm, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AbstractControl } from '@angular/forms';

import { Sort } from '@angular/material/sort';
import { trafikCoreApiBaseUrl } from 'src/environments/environment';
import { faCaretSquareUp, faEnvelope, faExclamationCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
import { TrafikUrunResultModel } from 'src/app/models/Urun/trafik-urun-result-model';
import { UniqueTeklifNoRequestModel } from 'src/app/models/Teklif/Trafik/unique-teklif-no-request-model';
import { Trafikonbilgi } from 'src/app/models/OnBilgi/Trafik/trafikonbilgi';
import { Sehir } from 'src/app/models/Adres/sehir';
import { Ilce } from 'src/app/models/Adres/ilce';
import { Model } from 'src/app/models/Arac/model';
import { Marka } from 'src/app/models/Arac/marka';
import { AracTarifeGrup } from 'src/app/models/Arac/arac-tarife-grup';
import { SigortaSirket } from 'src/app/models/SigortaSirket/sigorta-sirket';
import { ModelYil } from 'src/app/models/Arac/model-yil';
import AracKullanimSekli from 'src/app/models/Arac/arac-kullanim-sekli';
import { OncekiPolice } from 'src/app/models/OncekiPolice/onceki-police';
import { TrafikTeklifRequestModel } from 'src/app/models/Teklif/Trafik/trafik-teklif-request-model';
import { LoadingService } from 'src/app/service/loadService/loading.service';
import { UihelperService } from 'src/app/service/uihelper.service';
import { TrafikteklifService } from 'src/app/service/trafikteklif.service';
import { OnBilgiService } from 'src/app/service/onbilgi.service';
import { AuthService } from 'src/app/service/auth.service';
import { Subscription } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-trafikteklif',
  templateUrl: './trafikteklif.component.html',
  styleUrls: ['./trafikteklif.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})

export class TrafikteklifComponent implements OnInit {
  @ViewChild("onBilgiSorgulamaNgFom") onBilgiSorgulamaNgFom: NgForm;
  @ViewChild("sigortaliVeAracBilgileriNgFom") sigortaliVeAracBilgileriNgFom: NgForm;

  headerTitle = "Trafik Sigortası"
  isValidFormSubmitted = null; //On bilgi sorguladığında validasyon çalışşın diye
  sigortaliVeAracBilgileriSubmit = null //On Bilgi Sigortalı ve Araç Bilgileri validasyon görünürlüğü
  selection = new SelectionModel<TrafikUrunResultModel[]>(true, []);
  dataSource: TrafikUrunResultModel[];
  tmpDataSource: TrafikUrunResultModel[];
  columnsToDisplay = ['checkBox', 'status', 'bilgi', 'sigortaSirketKisaAdi', 'urunAdi', 'teklifNo', 'brutPrim', 'print', 'islem'];
  expandedElement: TrafikUrunResultModel | null | undefined;

  trafikUniqueTeklifNoRequest: UniqueTeklifNoRequestModel;

  authToken: any;
  validationMessage: string = ""
  kimlikNo: string;
  plaka: string;
  ruhsat: string;

  tmpKimlikNo: string;
  tmpPlaka: string;
  tmpRuhsat: string;
  errorDetail: any;
  exclamationCircle = faExclamationCircle;
  faCaretSquareUp = faCaretSquareUp;
  faPhone = faPhone;
  faEnvelope = faEnvelope

  teklifIconVisibility = false;
  onSpinner = true;
  onTeklifSpinner = true;
  isLinear = false;
  isEditable = true;
  isOptional = false;
  onBilgiSorguStatus = true;
  yenidenSorguStatus = false;
  onBilgiSorgulamaFormGroup: FormGroup;
  teklifAlmaFormGroup: FormGroup;
  sigortaliVeAracBilgileri: FormGroup;
  standartTeminatSecimFormGroup: FormGroup;

  sigortaliAdUnvanModel: string;
  selectedSigortaliMahalle: string;
  selectedSigortaliCadde: string;
  selectedSigortaliSokak: string;
  selectedGSM: string;
  selectedSigortaliEmail: string;

  selectedDogumTarihi: Date
  selectedAracTrafigeCikisTarihi: Date;
  selectedAracTescilTarihi: Date;
  selectedAracMotorNo: string;
  selectedAracSasiNo: string;
  selectedAracYolcuSayisi: number;
  selectedAracKodu: string;
  disableButton: boolean = false;
  scroll: boolean = false
  //region Nesne tanımlamaları

  responseModel: Trafikonbilgi;
  trafikUrunResult: TrafikUrunResultModel[];

  ilModel: Sehir[];

  selectedIlModel: Sehir;

  ilceModel: Ilce[];
  selectedIlceModel: Ilce;

  modellerData: Model[];
  modellerDataTmp: Model[]
  selectedAracModel: Model;

  markalarData: Marka[];
  selectedAracMarkaModel: Marka;

  aracTarifeGruplari: AracTarifeGrup[];
  selectedAracTarifeGrup: AracTarifeGrup;

  aracKullanimSekilleriData: AracKullanimSekli[];
  selectedAracKullanimSekli: AracKullanimSekli;

  modelYillariData: ModelYil[];
  selectedAracModelYili: ModelYil;

  sigortaSirketleriData: SigortaSirket[];
  oncekiPoliceModel: OncekiPolice;

  subscriptions: Subscription[] = []
  subscription: Subscription

  sorted: Sort = {
    active: "brutPrim",
    direction: "asc"
  };
  //endregion
  trafikTeklifRequestModel: TrafikTeklifRequestModel;
  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private title: Title,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _uiHelperService: UihelperService,
    private _onBilgiService: OnBilgiService,
    private _trafikTeklifService: TrafikteklifService,
    private authService: AuthService
  ) {
    title.setTitle("SigortaSepeti-Trafik");

    this.dataSource = [];
    this.responseModel = new Trafikonbilgi();
    this.trafikUrunResult = [];
    this.trafikTeklifRequestModel = new TrafikTeklifRequestModel();

    this.trafikUniqueTeklifNoRequest = new UniqueTeklifNoRequestModel();
    this.ilModel = [];
    // this.selectedIlModel = new Sehir();
    this.ilceModel = [];
    // this.selectedIlceModel = new Ilce();
    this.aracTarifeGruplari = [
      { kodu: '01', adi: 'Otomobil' },
      { kodu: '02', adi: 'Taksi' },
      { kodu: '03', adi: 'Minibüs (Sürücü Dahil 10-17 Koltuk)' },
      { kodu: '04', adi: 'Otobüs (Sürücü Dahil 18-30 Koltuk) ' },
      { kodu: '05', adi: 'Otobüs (Sürücü Dahil 31 ve Üstü Koltuk)' },
      { kodu: '06', adi: 'Kamyonet' },
      { kodu: '07', adi: 'Kamyon' },
      { kodu: '08', adi: 'İş Makinesi' },
      { kodu: '09', adi: 'Traktör' },
      { kodu: '10', adi: 'Römork' },
      { kodu: '11', adi: 'Motosiklet ve Yük Motosikleti' },
      { kodu: '12', adi: 'Tanker' },
      { kodu: '13', adi: 'Çekici' },
      { kodu: '14', adi: 'Özel Amaçlı Taşıtlar' },
      { kodu: '15', adi: 'Tarım Makinesi' },
    ];
    // this.selectedAracTarifeGrup = new AracTarifeGrup();
    this.aracKullanimSekilleriData = [
      { kodu: 0, adi: "HUSUSİ-ÖZEL", aktifMi: true },
      { kodu: 1, adi: "TİCARİ", aktifMi: true }
    ];
    this.selectedAracKullanimSekli = new AracKullanimSekli();

    this.selectedAracKullanimSekli = this.aracKullanimSekilleriData[0];

    this.modelYillariData = [
      { yil: 1950 },
      { yil: 1951 },
      { yil: 1952 },
      { yil: 1953 },
      { yil: 1954 },
      { yil: 1955 },
      { yil: 1956 },
      { yil: 1957 },
      { yil: 1958 },
      { yil: 1959 },
      { yil: 1960 },
      { yil: 1961 },
      { yil: 1962 },
      { yil: 1963 },
      { yil: 1964 },
      { yil: 1965 },
      { yil: 1966 },
      { yil: 1967 },
      { yil: 1968 },
      { yil: 1969 },
      { yil: 1970 },
      { yil: 1971 },
      { yil: 1972 },
      { yil: 1973 },
      { yil: 1974 },
      { yil: 1975 },
      { yil: 1976 },
      { yil: 1977 },
      { yil: 1978 },
      { yil: 1979 },
      { yil: 1980 },
      { yil: 1981 },
      { yil: 1982 },
      { yil: 1983 },
      { yil: 1984 },
      { yil: 1985 },
      { yil: 1986 },
      { yil: 1987 },
      { yil: 1988 },
      { yil: 1989 },
      { yil: 1990 },
      { yil: 1991 },
      { yil: 1992 },
      { yil: 1993 },
      { yil: 1994 },
      { yil: 1995 },
      { yil: 1996 },
      { yil: 1997 },
      { yil: 1998 },
      { yil: 1999 },
      { yil: 2000 },
      { yil: 2001 },
      { yil: 2002 },
      { yil: 2003 },
      { yil: 2004 },
      { yil: 2005 },
      { yil: 2006 },
      { yil: 2007 },
      { yil: 2008 },
      { yil: 2009 },
      { yil: 2010 },
      { yil: 2011 },
      { yil: 2012 },
      { yil: 2013 },
      { yil: 2014 },
      { yil: 2015 },
      { yil: 2016 },
      { yil: 2017 },
      { yil: 2018 },
      { yil: 2019 },
      { yil: 2020 },
      { yil: 2021 },
    ];
    // this.selectedAracModelYili = new ModelYil();
    this.markalarData = [];
    // this.selectedAracMarkaModel = new Marka();
    this.modellerData = [];
    this.modellerDataTmp = [];
    // this.selectedAracModel = new Model();
    this.sigortaSirketleriData = [];
    this.oncekiPoliceModel = new OncekiPolice();
  }
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  plakaRegex = "((\\s){0,25})(0[1-9]|[1-7][0-9]|8[01])(([A-Za-z])(\\d{4,5})((\\s){0,25})|([A-Za-z]{2})(\\d{3,4})((\\s){0,25})|([A-Za-z]{3})(\\d{2,3}))((\\s){0,25})"
  ruhsatRegex = "((\\s){0,25})([A-Za-z]{2})(\\d{6})((\\s){0,25})$";
  tcRegex = "(((\\s){0,25})([0-9]{10,11})((\\s){0,25}))"
  dogumTarihiValidator = []

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true)
    this.scrollForStep1()
    this.onBilgiSorgulamaFormGroup = this._formBuilder.group({
      kimlikNoInputElement: new FormControl("", Validators.compose([Validators.required, Validators.pattern(this.tcRegex)])),
      plakaInputElement: new FormControl("", Validators.compose([Validators.pattern(this.plakaRegex), Validators.required]),),
      ruhsatInputCtrl: new FormControl("", Validators.compose([Validators.required, Validators.pattern(this.ruhsatRegex)]))
    });

    this.sigortaliVeAracBilgileri = this._formBuilder.group({
      sigortaliGSMInputElement: ['', Validators.compose([Validators.pattern('(\\d{1})( \\(\)(\\d{3})(\\)\ )(\\d{3} )(\\d{2} )(\\d{2})'), Validators.required])],
      sigortaliEmailInputElement: ['', Validators.compose([Validators.email, Validators.required])
        /*  Validators.compose([
         Validators.email,
         Validators.pattern(
           /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/gm
         ),
         Validators.required
       ]) */
      ],
      sigortaliAdUnvanInputElement: ['', Validators.required],
      dogumTarihiDTPElement: ['', this.dogumTarihiValidator],
      sehirlerControl: ['', Validators.required],
      ilcelerControl: ['', Validators.required],
      sigortaliMahalleInputElement: [''],
      sigortaliCaddeInputElement: [''],
      sigortaliSokakInputElement: [''],
      // aracModelYiliInputElement: ['', Validators.required],
      aracTrafigeCikisTarihiDTPElement: ['', Validators.required],
      aracTescilTarihiDTPElement: ['', Validators.required],
      aracMotorNoInputElement: ['', Validators.required],
      aracSasiNoInputElement: ['', Validators.required],
      aracTarifeGrupKoduControl: ['', Validators.required],
      aracKullanimSekliControl: ['', Validators.required],
      aracYolcuSayisiInputElement: ['', Validators.compose([Validators.required, Validators.pattern("^[1-9][0-9]*$")])],
      aracMarkalarControl: ['', Validators.required],
      aracModellerControl: ['', Validators.required],
      oncekiSigortaSirketiControl: [''],
      eskiPoliceNoInputElement: [''],
      eskiPoliceAcenteNoInputElement: [''],
      eskiPoliceYenilemeNoInputElement: [''],
      oncekiPoliceBitisTarihiDTPElement: [''],
      aracModelYiliControl: ['', Validators.required],
      aracKoduControl: ['']
    });

    this.teklifAlmaFormGroup = this._formBuilder.group({
      /*urunTeminatIMMControl: ['', Validators.required]*/
      /*standartTeminatIMMControl: ['', Validators.required]*/
    });


    //region Servis İstekleri
    this._uiHelperService.sehirleriGetir().subscribe(
      (data) => {
        this.ilModel = data.slice();
        this.sortCity(this.sortCities)
      },
      (error) => {
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 3000,
        });
      }
    );

    this._uiHelperService.markalariGetir().subscribe(
      (data) => {
        this.markalarData = data;
      },
      (error) => {
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 3000,
        });
      }
    );

    this._uiHelperService.sigortaSirketleriniGetir().subscribe(
      (data) => {
        this.sigortaSirketleriData = data;
      },
      (error) => {
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 3000,
        });
      }
    );
    this.trafikUrunleriniGetir();    //endregion
  }


  trafikUrunleriniGetir(): void {
    let token = localStorage.getItem("access_token")
    this._uiHelperService.SpTrafikUrunleriniGetir(token).subscribe(
      (data) => {
        this.tmpDataSource = data;
        this.dataSource = data;
      },
      (error) => {
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 3000,
        });
      }
    );
  }

  //Secilen checkboxları temizlemeye yarayan fonksiyondur.
  urunCheckboxlariniTemizle(): void {
    this.dataSource.forEach(urun => {
      if (urun.listeSecimiAktifMi) {
        this.selection.deselect(urun.id);
        urun.listeSecimiAktifMi = false;
      }
    });
  }
  //Secilen ürün teklif bilgilerini temizlemeye yarayan fonksiyondur.
  secilenTeklifBilgileriniTemizle(): void {
    this.dataSource.forEach(urun => {
      if (urun.listeSecimiAktifMi) {
        if (urun.brutPrim != '0' || urun.brutPrim != null) {
          urun.brutPrim = "";
          urun.teklifNo = "";
        }
      }
    });
  }

  //Ürün teklif bilgilerini temizlemeye yarayan fonksiyondur.
  teklifBilgileriniTemizle(): void {
    this.dataSource.forEach(urun => {
      urun.brutPrim = "";
      urun.teklifNo = "";
    });
    this.teklifIconVisibility = false
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.dataSource.forEach(urun =>
      document.getElementById('urunSpinner-' + urun.id)?.setAttribute('style', 'display: none;')
    );
  }
  
  async trafikTeklifAl() {
    if (localStorage.getItem("access_token") == null || localStorage.getItem("_grecaptcha") == null || localStorage.getItem("userName") == null) {
      this.router.navigate(["login"]);
    }
    this.tokenGecerliMi()

    this.sigortaliVeAracBilgileriSubmit = true
    this.validationCall()
    if (this.onBilgiSorgulamaFormGroup.valid === false || this.sigortaliVeAracBilgileri.valid === false) {
      return
    }
    this.validationMessage = ""
    this.secilenTeklifBilgileriniTemizle();

    this.trafikUniqueTeklifNoRequest = new UniqueTeklifNoRequestModel();
    this.trafikUniqueTeklifNoRequest.authToken = this.localTokenBilgisiniGetir();
    this.onSpinner = false
    await this._onBilgiService.trafikOnBilgiKaydet(this.responseModel).then(onBilgi => {
      this.responseModel.Sigortali.Kisi.Id = onBilgi.sigortali.kisi.id;
      this.responseModel.Arac.Id = onBilgi.arac.id
      console.log(onBilgi.sigortali.kisi.id + " Araç id =  " + onBilgi.arac.id)
      this.trafikUniqueTeklifNoRequest.sigortaliId = onBilgi.sigortali.kisi.id;
      this.trafikUniqueTeklifNoRequest.sigortaliAracId = onBilgi.arac.id;
    });

    await this._trafikTeklifService.trafikUniqueTeklifNoAl(this.trafikUniqueTeklifNoRequest).then(
      (response) => {
        this.onSpinner = true
        if (response != null && response != undefined) {
          if (response.randomTeklifNo != null && response.randomTeklifNo != undefined && response.randomTeklifNo != "") {
            this.trafikTeklifRequestModel = new TrafikTeklifRequestModel();
            this.trafikTeklifRequestModel.uniqueTeklifNo = response.randomTeklifNo;
          }
        }
      });

    if (this.trafikTeklifRequestModel.uniqueTeklifNo != null && this.trafikTeklifRequestModel.uniqueTeklifNo != undefined) {
      this.dataSource.forEach(urun => {
        if (urun.listeSecimiAktifMi === true) {
          document.getElementById('urunDone-' + urun.id)?.setAttribute('style', 'display: none;');
          document.getElementById('urunError-' + urun.id)?.setAttribute('style', 'display: none;');
          document.getElementById('urunWarning-' + urun.id)?.setAttribute('style', 'display: none;');
          document.getElementById('urunSpinner-' + urun.id)?.setAttribute('style', '');
          this.teklifIconVisibility = true;

          this.trafikTeklifRequestModel.onBilgi = this.responseModel;
          this.trafikTeklifRequestModel.onBilgi.Token = this.localTokenBilgisiniGetir();
          this.trafikTeklifRequestModel.urun = urun;

          this.subscription = this._trafikTeklifService.trafikTeklifAl(this.trafikTeklifRequestModel).subscribe(
            (data) => {
              urun.uyariMesaji = data.uyariMesaji
              if (data.basariliMi) {
                console.log(data);
                urun.brutPrim = data.brutPrim;
                urun.teklifNo = data.teklifNo;
                console.log(urun);
                document.getElementById('urunSpinner-' + urun.id)?.setAttribute('style', 'display: none;');
                document.getElementById('urunDone-' + urun.id)?.setAttribute('style', '');
                document.getElementById('urunError-' + urun.id)?.setAttribute('style', 'display: none;');
                if (urun.uyariMesaji != null && urun.uyariMesaji != undefined) {
                  document.getElementById('urunWarning-' + urun.id)?.setAttribute('style', '');
                  document.getElementById('urunWarning-' + urun.id)?.setAttribute('data-original-title', urun.uyariMesaji);
                  $('[data-toggle="tooltip"]').tooltip();
                }
                // this.sortData(this.sorted)
              } else {
                console.log(data);
                document.getElementById('urunSpinner-' + urun.id)?.setAttribute('style', 'display: none;');
                document.getElementById('urunError-' + urun.id)?.setAttribute('style', '');

                document.getElementById('urunError-' + urun.id)?.setAttribute('data-original-title', data.hata.hataDetayi);
                $('[data-toggle="tooltip"]').tooltip();
                console.log(urun.sigortaSirketKisaAdi + "Hatası :  " + data.hata.hataDetayi)
              }
            },
            (error) => {
              document.getElementById('urunError-' + urun.id)?.setAttribute('data-placement', 'right');
              document.getElementById('urunError-' + urun.id)?.setAttribute('data-original-title', error.title + ' ' + error.errors);

              document.getElementById('urunSpinner-' + urun.id)?.setAttribute('style', 'display: none;');
              document.getElementById('urunDone-' + urun.id)?.setAttribute('style', 'display: none;');
              document.getElementById('urunError-' + urun.id)?.setAttribute('style', '');
              $('[data-toggle="tooltip"]').tooltip();
            }
          )
          this.subscriptions.push(this.subscription)
        }
      });
    }
  }
  //region Ürün listesi checkboxları için kullanılan metodlar
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  onScroll = (s) => {
    let st = s.target.scrollTop
    if (st > 150) {
      this.scroll = true
    }
    else
      this.scroll = false
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (!this.isAllSelected()) {
      /*console.log('isAllSelected')*/
      this.dataSource.forEach(lstUrun => {

        this.selection.select(lstUrun.id);
        for (let urun of this.dataSource) {
          if (lstUrun.id === urun.id) {
            urun.listeSecimiAktifMi = true;
          }
        }
      });
    } else {
      /*console.log('isAllSelected else')*/
      this.selection.clear();
      this.dataSource.forEach(lstUrun => {

        this.selection.deselect(lstUrun.id);
        for (let urun of this.dataSource) {
          if (lstUrun.id === urun.id) {
            urun.listeSecimiAktifMi = false;
          }
        }
      });
    }
  }

  onChangeUrunCheckBox(urun: TrafikUrunResultModel): void {
    // console.log(urun.id)
    let urunId = urun.id
    /*this.selection.toggle(urun.id);*/
    if (urun.listeSecimiAktifMi) {
      this.selection.deselect(urun.id);
      urun.listeSecimiAktifMi = false;
    } else {
      this.selection.select(urun.id);
      urun.listeSecimiAktifMi = true;
    }
    console.log(this.selection.selected)
  }

  call() {
    console.log(this.selection.selected);
    console.log(this.dataSource)
  }
  //endregion

  ilKodu = ""
  //region Trafik Ön Bilgi Doldurma metodu
  trafikOnBilgiDoldur(): void {
    setTimeout(() => {
      if (this.responseModel.Sigortali.Kisi.Adi != undefined && this.responseModel.Sigortali.Kisi.Adi != '') {
        this.sigortaliVeAracBilgileri.controls.sigortaliAdUnvanInputElement.setValue(
          this.responseModel.Sigortali.Kisi.Adi +
          ' ' +
          this.responseModel.Sigortali.Kisi.Soyadi
        );
      }
      if (this.responseModel.Sigortali.Kisi.DogumTarihi != undefined && this.responseModel.Sigortali.Kisi.DogumTarihi != null) {
        this.sigortaliVeAracBilgileri.controls.dogumTarihiDTPElement.setValue(
          this.responseModel.Sigortali.Kisi.DogumTarihi
        );
      }

      if (this.responseModel.Sigortali.Kisi.Adres.IlKodu != null && this.responseModel.Sigortali.Kisi.Adres.IlKodu != undefined) {
        // tek haneli il kodlarının başına 0 eklenerek set edilmesi sağlanıyor..
        if (this.responseModel.Sigortali.Kisi.Adres.IlKodu.toString().length == 1) {
          this.selectedIlModel.ilKodu =
            '0' + this.responseModel.Sigortali.Kisi.Adres.IlKodu.toString();
          this.sigortaliVeAracBilgileri.controls.sehirlerControl.setValue(
            this.selectedIlModel.ilKodu
          );
        }
        this.ilModel.forEach(il => {
          if (this.responseModel.Sigortali.Kisi.Adres.IlKodu === "0" + il.ilKodu || this.responseModel.Sigortali.Kisi.Adres.IlKodu === il.ilKodu) {
            this.selectedIlModel = il;
          }
        });
      }
      if (this.responseModel.Sigortali.Kisi.Adres.Ilce != null && this.responseModel.Sigortali.Kisi.Adres.Ilce != undefined) {
        this.sigortaliVeAracBilgileri.controls.ilcelerControl.setValue(
          this.responseModel.Sigortali.Kisi.Adres.Ilce
        );
      }
      if (this.responseModel.Sigortali.Kisi.Adres.Mahalle != null && this.responseModel.Sigortali.Kisi.Adres.Mahalle != undefined) {
        this.sigortaliVeAracBilgileri.controls.sigortaliMahalleInputElement.setValue(
          this.responseModel.Sigortali.Kisi.Adres.Mahalle
        );
      }
      if (this.responseModel.Sigortali.Kisi.Adres.Cadde != null && this.responseModel.Sigortali.Kisi.Adres.Cadde != undefined) {
        this.sigortaliVeAracBilgileri.controls.sigortaliCaddeInputElement.setValue(
          this.responseModel.Sigortali.Kisi.Adres.Cadde
        );
      }
      if (this.responseModel.Sigortali.Kisi.Adres.Sokak != null && this.responseModel.Sigortali.Kisi.Adres.Sokak != undefined) {
        this.sigortaliVeAracBilgileri.controls.sigortaliSokakInputElement.setValue(
          this.responseModel.Sigortali.Kisi.Adres.Sokak
        );
      }

      if (this.responseModel.Arac.TarifeGrupKodu != null && this.responseModel.Arac.TarifeGrupKodu != undefined) {
        this.sigortaliVeAracBilgileri.controls.aracTarifeGrupKoduControl.setValue(
          this.responseModel.Arac.TarifeGrupKodu
        );
      }

      if (this.responseModel.Arac.KullanimSekli != null && this.responseModel.Arac.KullanimSekli != undefined) {
        this.selectedAracKullanimSekli = this.aracKullanimSekilleriData[this.responseModel.Arac.KullanimSekli];
        this.sigortaliVeAracBilgileri.controls.aracKullanimSekliControl.setValue(
          this.selectedAracKullanimSekli
        );
      }

      if (this.markalarData != [] && this.markalarData != null && this.markalarData != undefined) {
        this.markalarData.forEach(marka => {
          if (this.responseModel.Arac.MarkaKodu != undefined && this.responseModel.Arac.MarkaKodu != null) {
            if (this.responseModel.Arac.MarkaKodu.toString() === marka.kodu) {
              if (marka != null && marka != undefined) {
                // document.getElementById("marka").setAttribute('style', 'pointer-events:none');
                this.sigortaliVeAracBilgileri.controls.aracMarkalarControl.setValue(
                  marka
                );
              }
              this.sigortaliVeAracBilgileri.controls.aracModellerControl.setValue(
                this.responseModel.Arac.AracKodu
              );
              this.sigortaliVeAracBilgileri.controls.aracKoduControl.setValue(
                this.responseModel.Arac.AracKodu
              );
            }
          }
        });
      }

      if (this.responseModel.Arac.ModelYili != null && this.responseModel.Arac.ModelYili != undefined) {
        this.sigortaliVeAracBilgileri.controls.aracModelYiliControl.setValue(
          this.responseModel.Arac.ModelYili
        );
      }
      if (this.responseModel.Arac.TrafigeCikisTarihi != null && this.responseModel.Arac.TrafigeCikisTarihi != undefined) {
        this.sigortaliVeAracBilgileri.controls.aracTrafigeCikisTarihiDTPElement.setValue(
          this.responseModel.Arac.TrafigeCikisTarihi
        );
      }
      if (this.responseModel.Arac.TescilTarihi != null && this.responseModel.Arac.TescilTarihi != undefined) {
        this.sigortaliVeAracBilgileri.controls.aracTescilTarihiDTPElement.setValue(
          this.responseModel.Arac.TescilTarihi
        );
      }

      if (this.responseModel.Arac.MotorNo != null && this.responseModel.Arac.MotorNo != undefined) {
        this.sigortaliVeAracBilgileri.controls.aracMotorNoInputElement.setValue(
          this.responseModel.Arac.MotorNo
        );
      }

      if (this.responseModel.Arac.SasiNo != null && this.responseModel.Arac.SasiNo != undefined) {
        this.sigortaliVeAracBilgileri.controls.aracSasiNoInputElement.setValue(
          this.responseModel.Arac.SasiNo
        );
      }

      if (this.responseModel.Arac.YolcuSayisi != null && this.responseModel.Arac.YolcuSayisi != undefined && this.responseModel.Arac.YolcuSayisi != 0) {
        this.sigortaliVeAracBilgileri.controls.aracYolcuSayisiInputElement.setValue(
          this.responseModel.Arac.YolcuSayisi
        );
      }
      if (
        this.responseModel.Sigortali.OncekiPolice.PoliceNo != null &&
        this.responseModel.Sigortali.OncekiPolice.PoliceNo != undefined
      ) {
        this.sigortaliVeAracBilgileri.controls.eskiPoliceNoInputElement.setValue(
          this.responseModel.Sigortali.OncekiPolice.PoliceNo
        );
        document.getElementById("sigortaSirketiEskiPoliceNo").setAttribute('style', 'pointer-events:none');

        this.sigortaliVeAracBilgileri.controls.eskiPoliceAcenteNoInputElement.setValue(
          this.responseModel.Sigortali.OncekiPolice.AcenteKod
        );
        document.getElementById("sigortaSirketiEskiAcentaNo").setAttribute('style', 'width:100px;pointer-events:none');
        this.sigortaliVeAracBilgileri.controls.eskiPoliceYenilemeNoInputElement.setValue(
          this.responseModel.Sigortali.OncekiPolice.YenilemeNo
        );
        document.getElementById("sigortaSirketiEskiYenilemeNo").setAttribute('style', 'width:50px;pointer-events:none');
        this.sigortaliVeAracBilgileri.controls.oncekiPoliceBitisTarihiDTPElement.setValue(
          this.responseModel.Sigortali.OncekiPolice.BitisTarihi
        );
        document.getElementById("oncekiPoliceBitisTarihiFormField").setAttribute('style', 'width: 110px;pointer-events:none');
        this.sigortaliVeAracBilgileri.controls.oncekiSigortaSirketiControl.setValue(
          this.responseModel.Sigortali.OncekiPolice.SirketKodu
        );
        document.getElementById("sigortaSirketiEskiPolice").setAttribute('style', 'width: 270px;pointer-events:none');
      }
      Object.keys(this.sigortaliVeAracBilgileri.controls).forEach(key => {
        this.sigortaliVeAracBilgileri.get(key).updateValueAndValidity();
      });
    }, 10);
  }
  //endregion

  //region Sigortalı Bilgileri onChanges metodları
  onSigortaliAdUnvanChange(girilenSigortaliAd: any): void {
    this.sigortaliAdUnvanModel = girilenSigortaliAd;
    if (
      this.sigortaliAdUnvanModel != '' &&
      this.sigortaliAdUnvanModel != undefined
    ) {
      this.responseModel.Sigortali.Kisi.Unvan = this.sigortaliAdUnvanModel;
    }
  }
  onDogumTarihiChange(girilenDogumCikisTarihi: any): void {
    if (this.kimlikNo != undefined && this.kimlikNo != null && this.kimlikNo != '' && this.kimlikNo.length == 11) {
      this.sigortaliVeAracBilgileri.controls['dogumTarihiDTPElement'].setValidators(Validators.required)
      this.sigortaliVeAracBilgileri.controls['dogumTarihiDTPElement'].updateValueAndValidity();
    }
    else {
      this.sigortaliVeAracBilgileri.controls['dogumTarihiDTPElement'].setValidators([]);
      this.sigortaliVeAracBilgileri.controls['dogumTarihiDTPElement'].updateValueAndValidity();
    }
    if (
      girilenDogumCikisTarihi != null &&
      girilenDogumCikisTarihi != undefined
    ) {
      var tarih = new Date(girilenDogumCikisTarihi)
      this.selectedDogumTarihi = girilenDogumCikisTarihi
      this.responseModel.Sigortali.Kisi.DogumTarihi = new Date(tarih.getFullYear(), tarih.getMonth(), tarih.getDate() + 1);
    }
  }

  onSehirChange(secilenSehirData: any): void {
    this.selectedIlModel = secilenSehirData;
    if (
      this.selectedIlModel != undefined &&
      secilenSehirData != null &&
      typeof secilenSehirData === 'object'
    ) {
      this._uiHelperService.ileGoreIlceGetir(this.selectedIlModel.ilKodu).subscribe(
        (data) => {
          this.ilceModel = JSON.parse(JSON.stringify(data));
          this.sortDistrict(this.sortDistricts)
        },
        (error) => {
          this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
            duration: 3000,
          });
        }
      );
      this.responseModel.Sigortali.Kisi.Adres.IlKodu = this.selectedIlModel.ilKodu;
      this.responseModel.Sigortali.Kisi.Adres.Il = this.selectedIlModel.ilAdi
      // console.log(this.responseModel.Sigortali.Kisi.Adres.Il+this.responseModel.Sigortali.Kisi.Adres.IlKodu )
    }
  }

  // TODO: ilçe adı ön bilgiye yazdırıldığı için bu alanda daha sonra kodu yazdırılması için çalışma yapılacak.
  onIlceChange(secilenIlceData: any): void {
    this.selectedIlceModel = secilenIlceData;
    if (
      secilenIlceData != '' &&
      secilenIlceData != undefined &&
      secilenIlceData != 'object'
    ) {
      this.responseModel.Sigortali.Kisi.Adres.Ilce = secilenIlceData;
      // console.log(this.responseModel.Sigortali.Kisi.Adres.Ilce)
    }
  }

  onSigortaliMahalleChange(girilenSigortaliMahalle: any): void {
    this.selectedSigortaliMahalle = girilenSigortaliMahalle;
    if (
      this.selectedSigortaliMahalle != '' &&
      this.selectedSigortaliMahalle != undefined &&
      typeof girilenSigortaliMahalle != 'object'
    ) {
      this.responseModel.Sigortali.Kisi.Adres.Mahalle = this.selectedSigortaliMahalle;
      // console.log(this.responseModel.Sigortali.Kisi.Adres.Mahalle)
    }
  }

  onSigortaliCaddeChange(girilenSigortaliCadde: any): void {
    this.selectedSigortaliCadde = girilenSigortaliCadde;
    if (
      this.selectedSigortaliCadde != '' &&
      this.selectedSigortaliCadde != undefined &&
      typeof girilenSigortaliCadde != 'object'
    ) {
      this.responseModel.Sigortali.Kisi.Adres.Cadde = this.selectedSigortaliCadde;
      // console.log(this.responseModel.Sigortali.Kisi.Adres.Cadde)
    }
  }

  onSigortaliSokakChange(girilenSigortaliSokak: any): void {
    this.selectedSigortaliSokak = girilenSigortaliSokak;
    if (
      this.selectedSigortaliSokak != '' &&
      this.selectedSigortaliSokak != undefined &&
      typeof girilenSigortaliSokak != 'object'
    ) {
      this.responseModel.Sigortali.Kisi.Adres.Sokak = this.selectedSigortaliSokak;
      // console.log(this.responseModel.Sigortali.Kisi.Adres.Sokak)
    }
  }

  onSigortaliGSMChange(girilenGSM: string): void {
    if (
      girilenGSM != '' &&
      girilenGSM != undefined &&
      typeof girilenGSM != 'object'
    ) {
      this.selectedGSM = new PhonePipe().transform(girilenGSM)
      var resultGsm = this.selectedGSM.split(" ").join("").replace("(", "").replace(")", "").substring(1)
      this.responseModel.Sigortali.Kisi.GsmNo = resultGsm
    }
  }


  onSigortaliEmailChange(girilenSigortaliEmail: any): void {
    this.selectedSigortaliEmail = girilenSigortaliEmail;
    if (
      this.selectedSigortaliEmail != '' &&
      this.selectedSigortaliEmail != undefined &&
      typeof girilenSigortaliEmail != 'object'
    ) {
      this.responseModel.Sigortali.Kisi.Email = this.selectedSigortaliEmail;
      // console.log(this.responseModel.Sigortali.Kisi.Email)
    }
  }
  //endregion

  //region Araç Bilgileri onChanges metodları
  onKullanimTarziChange(secilenTarzData: any): void {
    if (
      secilenTarzData != '' &&
      secilenTarzData != undefined &&
      typeof secilenTarzData != 'object'
    ) {
      this.responseModel.Arac.TarifeGrupKodu = secilenTarzData;
      // console.log(this.responseModel.Arac.TarifeGrupKodu)
    }
  }

  onKullanimSekliChange(secilenKullanimSekliData: any): void {
    if (this.responseModel.Arac != undefined) {
      if (secilenKullanimSekliData != null && secilenKullanimSekliData != undefined && typeof secilenKullanimSekliData === "object") {
        this.selectedAracKullanimSekli = secilenKullanimSekliData;
        this.responseModel.Arac.KullanimSekli = secilenKullanimSekliData.kodu;
        // console.log(this.responseModel.Arac.KullanimSekli)
      }
    }
    /*this.selectedAracKullanimSekli = secilenKullanimSekliData;
    if ( this.selectedAracKullanimSekli != undefined &&  this.selectedAracKullanimSekli!=null &&  typeof  this.selectedAracKullanimSekli === 'object') {
      this.responseModel.Arac.KullanimSekli = this.selectedAracKullanimSekli.kodu;
      console.log(this.responseModel.Arac.KullanimSekli)
    }*/
  }

  onMarkaChange(secilenMarkaData: any): void {
    this.selectedAracMarkaModel = secilenMarkaData;
    if (
      this.selectedAracMarkaModel != undefined &&
      secilenMarkaData != null &&
      typeof secilenMarkaData == 'object'
    ) {
      this._uiHelperService
        .markayaGoreModelGetir(this.selectedAracMarkaModel.kodu)
        .subscribe(
          (data: any) => {
            this.modellerData = JSON.parse(JSON.stringify(data));
            this.modellerDataTmp = this.modellerData.slice();
            this.sortModel(this.sortModels)
          },
          (error: { title: string; errors: string }) => {
            this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
              duration: 3000,
            });
          }
        );
      this.responseModel.Arac.MarkaAdi = secilenMarkaData.adi
      this.responseModel.Arac.MarkaKodu = secilenMarkaData.kodu;
      // console.log(this.responseModel.Arac.MarkaKodu +"  "+this.responseModel.Arac.MarkaAdi )
    }
  }

  onModelChange(secilenModelData: any): void {
    if (
      secilenModelData != '' &&
      secilenModelData != undefined &&
      secilenModelData != null &&
      typeof secilenModelData != 'object'
    ) {
      this.responseModel.Arac.AracKodu = secilenModelData
      this.selectedAracModel = secilenModelData
      this.selectedAracKodu = secilenModelData
      this.responseModel.Arac.ModelKodu = secilenModelData.slice(3, secilenModelData.length)
    }
  }

  onAracTrafigeCikisTarihiChange(girilenTrafigeCikisTarihi: any): void {
    if (
      girilenTrafigeCikisTarihi != null &&
      girilenTrafigeCikisTarihi != undefined
    ) {
      var tarih = new Date(girilenTrafigeCikisTarihi)
      this.selectedAracTrafigeCikisTarihi = girilenTrafigeCikisTarihi
      this.responseModel.Arac.TrafigeCikisTarihi = new Date(tarih.getFullYear(), tarih.getMonth(), tarih.getDate() + 1);
      // console.log(this.responseModel.Arac.TrafigeCikisTarihi)
    }
  }

  onAracTescilTarihiChange(girilenTescilTarihi: any): void {
    if (
      girilenTescilTarihi != null &&
      girilenTescilTarihi != undefined
    ) {
      var tarih = new Date(girilenTescilTarihi)
      this.selectedAracTescilTarihi = girilenTescilTarihi;
      this.responseModel.Arac.TescilTarihi = new Date(tarih.getFullYear(), tarih.getMonth(), tarih.getDate() + 1);
      // console.log(this.responseModel.Arac.TescilTarihi)
    }
  }

  onAracMotorNoChange(girilenMotorNo: any): void {
    this.selectedAracMotorNo = girilenMotorNo;
    if (
      this.selectedAracMotorNo != '' &&
      this.selectedAracMotorNo != undefined &&
      typeof girilenMotorNo != 'object'
    ) {
      this.responseModel.Arac.MotorNo = this.selectedAracMotorNo;
      // document.getElementById("motorNo").setAttribute("style","pointer-events:none;")
      // console.log(this.responseModel.Arac.MotorNo)
    }
  }

  onAracSasiNoChange(girilenSasiNo: any): void {
    this.selectedAracSasiNo = girilenSasiNo;
    if (
      this.selectedAracSasiNo != '' &&
      this.selectedAracSasiNo != undefined &&
      typeof girilenSasiNo != 'object'
    ) {
      this.responseModel.Arac.SasiNo = this.selectedAracSasiNo;
      // document.getElementById("sasiNo").setAttribute("style","pointer-events:none;")
      // console.log(this.responseModel.Arac.SasiNo)
    }
  }

  onAracYolcuSayisiChange(girilenAracYolcuSayisi: any): void {
    this.selectedAracYolcuSayisi = girilenAracYolcuSayisi;
    if (
      this.selectedAracYolcuSayisi != null &&
      this.selectedAracYolcuSayisi != undefined &&
      typeof girilenAracYolcuSayisi != 'object'
    ) {
      this.responseModel.Arac.YolcuSayisi = this.selectedAracYolcuSayisi;
      // console.log(this.responseModel.Arac.YolcuSayisi)
    }
  }

  onAracModelYiliChange(secilenAracModelYili: any): void {
    // console.log(secilenAracModelYili)
    if (
      secilenAracModelYili != '' &&
      secilenAracModelYili != undefined &&
      secilenAracModelYili != null &&
      typeof secilenAracModelYili != 'object'
    ) {
      this.responseModel.Arac.ModelYili = secilenAracModelYili;
      // console.log(this.responseModel.Arac.ModelYili)
    }
  }
  //endregion

  //region Onceki Poliçe onChanges metodları
  onOncekiSigortaSirketiChange(secilenSirketData: any) {
    this.oncekiPoliceModel.sigortaSirket.tramerKodu = secilenSirketData;
    if (
      this.oncekiPoliceModel.sigortaSirket.tramerKodu != '' &&
      this.oncekiPoliceModel.sigortaSirket.tramerKodu != undefined &&
      typeof secilenSirketData != 'object'
    ) {
      if (this.responseModel.Sigortali.OncekiPolice.SirketKodu != '') {
        this.responseModel.Sigortali.OncekiPolice.SirketKodu = this.oncekiPoliceModel.sigortaSirket.tramerKodu;
        // console.log(this.responseModel.Sigortali.OncekiPolice.SirketKodu)
      }
    }
  }

  onOncekiPoliceNoChange(girilenPoliceNo: any): void {
    this.oncekiPoliceModel.policeNo = girilenPoliceNo;
    if (
      this.oncekiPoliceModel.policeNo != '' &&
      this.oncekiPoliceModel.policeNo != undefined &&
      typeof girilenPoliceNo != 'object'
    ) {
      this.responseModel.Sigortali.OncekiPolice.PoliceNo = this.oncekiPoliceModel.policeNo;
      // console.log(this.responseModel.Sigortali.OncekiPolice.PoliceNo)
    }
  }

  onOncekiPoliceSigortaAcenteNoChange(girilenAcenteNo: any): void {
    this.oncekiPoliceModel.acenteNo = girilenAcenteNo;
    if (
      this.oncekiPoliceModel.acenteNo != '' &&
      this.oncekiPoliceModel.acenteNo != undefined &&
      typeof girilenAcenteNo != 'object'
    ) {
      this.responseModel.Sigortali.OncekiPolice.AcenteKod = this.oncekiPoliceModel.acenteNo;
      // console.log(this.responseModel.Sigortali.OncekiPolice.AcenteKod)
    }
  }

  onOncekiPoliceSigortaYenilemeNoChange(girilenYenilemeNo: any): void {
    this.oncekiPoliceModel.yenilemeNo = girilenYenilemeNo;
    if (
      this.oncekiPoliceModel.yenilemeNo != '' &&
      this.oncekiPoliceModel.yenilemeNo != undefined &&
      typeof girilenYenilemeNo != 'object'
    ) {
      this.responseModel.Sigortali.OncekiPolice.YenilemeNo = this.oncekiPoliceModel.yenilemeNo;
      // console.log( this.responseModel.Sigortali.OncekiPolice.YenilemeNo)
    }
  }

  onOncekiPoliceBitisTarihiChange(secilenBitisTarihi: any): void {
    if (
      secilenBitisTarihi != null &&
      secilenBitisTarihi != undefined
    ) {
      var tarih = new Date(secilenBitisTarihi)
      this.oncekiPoliceModel.bitisTarihi = secilenBitisTarihi
      this.responseModel.Sigortali.OncekiPolice.BitisTarihi = new Date(tarih.getFullYear(), tarih.getMonth(), tarih.getDate() + 1);
      // console.log(this.responseModel.Sigortali.OncekiPolice.BitisTarihi)
    }
  }
  //endregion

  // Readonly olan fieldları Readonly halden Çıkarma
  resetForReadonlyField() {
    document.getElementById("oncekiPoliceBitisTarihiFormField").setAttribute('style', 'width: 110px;');
    document.getElementById("sigortaSirketiEskiPolice").setAttribute('style', 'width: 270px;');
    document.getElementById("sigortaSirketiEskiAcentaNo").setAttribute('style', 'width: 100px;');
    document.getElementById("sigortaSirketiEskiPoliceNo").setAttribute('style', '');
    document.getElementById("sigortaSirketiEskiYenilemeNo").setAttribute('style', 'width: 50px;');
  }

  localTokenBilgisiniGetir(): string {
    let token = localStorage.getItem('access_token');
    if (token != null) {
      return token;
    } else {
      return "";
    }
  }
  tokenGecerliMi() {
    let reqToken = localStorage.getItem('access_token');
    if (reqToken === null || reqToken === '' || reqToken == undefined) {
      this.router.navigate(["login"]);
    }
    this.authService.isValidateToken(reqToken).subscribe(
      (data) => {
        if (data.result) {
          console.log("Token geçerli")
          return true;
        } else {
          this.router.navigate(["login"]);
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
  }
  //Formlara reset atan ve hataları nulla eşitleyen metod
  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }
  //region Trafik Ön Bilgi Sorgulama Metodu
  trafikOnBilgiSorgula(): void {
    if (localStorage.getItem("access_token") == null || localStorage.getItem("_grecaptcha") == null || localStorage.getItem("userName") == null) {
      this.router.navigate(["login"]);
    }
    this.tokenGecerliMi()
    this.responseModel = new Trafikonbilgi()
    this.resetForm(this.sigortaliVeAracBilgileri)
    this.validationCall()
    this.isValidFormSubmitted = false
    if (this.onBilgiSorgulamaFormGroup.invalid) {
      return
    }
    this.disableButton = false
    this.isValidFormSubmitted = true

    this.validationMessage = ""
    this.resetForReadonlyField();
    this.urunCheckboxlariniTemizle();
    this.teklifBilgileriniTemizle()
    // this.trafikUrunleriniGetir();

    this.onSpinner = false;
    this.onBilgiSorguStatus = true;

    this.kimlikNo = this.onBilgiSorgulamaFormGroup.get(
      'kimlikNoInputElement'
    ).value;
    this.plaka = this.onBilgiSorgulamaFormGroup.get('plakaInputElement').value;
    this.ruhsat = this.onBilgiSorgulamaFormGroup.get('ruhsatInputCtrl').value;
    let authToken = this.localTokenBilgisiniGetir();

    this._onBilgiService
      .trafikOnBilgiGetir(this.kimlikNo, this.plaka, this.ruhsat, authToken)
      .subscribe(
        (data) => {
          if (data.BasariliMi === false) {
            console.log('BasariliMi = false durumu:' + JSON.stringify(data));
            this.responseModel = data;
            this.trafikOnBilgiDoldur();
            this.onSpinner = true;
            this.onBilgiSorguStatus = true;
            this._snackBar.open(this.responseModel.Hata.hataDetayi, 'Kapat', {
              duration: 3000,
            });
          } else {
            this.onSpinner = true;
            this.responseModel = data;
            console.log('response model: ', this.responseModel);
            console.log(this.responseModel.Arac.MarkaKodu);
            this.trafikOnBilgiDoldur();
            this.onBilgiSorguStatus = true;

            if (!this.responseModel.PoliceVadesiGecerliMi) {
              this.disableButton = true
              this._snackBar.open(
                'Önceki poliçe bitiş tarihi 30 günden fazla olduğundan teklif çalışılamaz!',
                'Kapat',
                {
                  duration: 6000,
                }
              );
              return;
            }
            setTimeout(function () {
              document.getElementById('btnDevamEt')?.click();
            }, 100);
          }
        },
        (error) => {
          // this.hideCustomSpinner();
          this.onSpinner = true;
          this.onBilgiSorguStatus = true;
          // console.log(error);
          this._snackBar.open(error, 'Kapat', {
            duration: 3000,
          });
          setTimeout(function () {
            document.getElementById('btnDevamEt')?.click();
          }, 100);
        }
      );
  }
  //endregion
  scrollForStep1() {
    $("mat-sidenav-content").scrollTop(0);
  }
  //scroll kaydırma
  scrollForStep2() {
    $("mat-sidenav-content").scrollTop(200)
  }

  //Sorgulaya bastıktan sonra step üçün sonuna getiren metod
  scrollForStep3() {
    $("mat-sidenav-content").scrollTop(780)
  }

  herseyiTemizle() {
    this.disableButton = false
    if (localStorage.getItem("access_token") == null || localStorage.getItem("_grecaptcha") == null || localStorage.getItem("userName") == null) {
      this.router.navigate(["login"]);
    }
    this.tokenGecerliMi()
    this.dataSource = this.tmpDataSource;
    this.teklifBilgileriniTemizle()
    this.urunCheckboxlariniTemizle();
    this.resetForReadonlyField();
    this.responseModel = new Trafikonbilgi()
    this.urunCheckboxlariniTemizle();
    this.dataSource.forEach(urun => {
      document.getElementById('urunDone-' + urun.id)?.setAttribute('style', 'display: none;');
      document.getElementById('urunError-' + urun.id)?.setAttribute('style', 'display: none;');
      document.getElementById('urunSpinner-' + urun.id)?.setAttribute('style', 'display: none;');
      document.getElementById('urunWarnig-' + urun.id)?.setAttribute('style', 'display: none;');

    })
    document.getElementById("btnFormCleaner").click();
    setTimeout(() => {
      this.sigortaliVeAracBilgileriSubmit = null
      this.isValidFormSubmitted = null
      this.onBilgiSorgulamaNgFom.onReset();
      this.sigortaliVeAracBilgileriNgFom.onReset()
      this.validationMessage = ""
    }, 100);
  }

  dateTimeOnlyDate(dateTime: any) {
    var tarih = new Date(dateTime)
    if (tarih.getDate().toString().length != 1) {
      if ((tarih.getMonth() + 1).toString().length === 1) {
        var setTarih = tarih.getDate() + "." + "0" + (tarih.getMonth() + 1) + "." + tarih.getFullYear()
      }
      else
        var setTarih = tarih.getDate() + "." + (tarih.getMonth() + 1) + "." + tarih.getFullYear()
    }
    else if (tarih.getDate().toString().length == 1) {
      if ((tarih.getMonth() + 1).toString().length === 1) {
        var setTarih = "0" + tarih.getDate() + "." + "0" + (tarih.getMonth() + 1) + "." + tarih.getFullYear()
      }
      else
        var setTarih = "0" + tarih.getDate() + "." + (tarih.getMonth() + 1) + "." + tarih.getFullYear()
    }
    return setTarih
  }

  dateTimeWithHour(dateTime: any) {
    var yearMounthDate = this.dateTimeOnlyDate(dateTime)
    var tarih = new Date(dateTime)
    if ((tarih.getMinutes()).toString().length == 1) {
      var setTarih = yearMounthDate + " " + tarih.getHours() + ":" + "0" + tarih.getMinutes()
    } else
      var setTarih = yearMounthDate + " " + tarih.getHours() + ":" + tarih.getMinutes()
    return setTarih
  }
  sendToPdf() {
    this.tokenGecerliMi()    
    if (this.onBilgiSorgulamaFormGroup.valid === false || this.sigortaliVeAracBilgileri.valid === false) {
      this._snackBar.open("Pdf'e kaydetmek için önce teklif almanız gerekmektedir", 'Kapat', {
        duration: 5000,
      });
    }
    let pdf = new jsPDF('p', 'cm', 'a4');
    pdf.addFont("../../assets/fonts/roboto/Roboto-Regular.ttf", "Roboto-Regular", "normal")
    pdf.addFont("../../assets/fonts/roboto/Roboto-Bold.ttf", "Roboto-Bold", "normal")
    pdf.setFont("Roboto-Regular")
    const smallFontSize: number = 9
    const farToLeftForInput: number = 5.2
    const farToLeftForAracBilgileri: number = 14
    const farToLeftForAracBilgileriInput: number = 17.2
    pdf.setFontSize(smallFontSize)
    var teklifAlmaTarih = this.dateTimeWithHour(new Date())
    pdf.text(teklifAlmaTarih, 16.5, 1.5);
    pdf.setFontSize(smallFontSize)
    // pdf.setFont("Roboto-Bold")
    pdf.setFontSize(18)
    pdf.setLineWidth(0.025);
    pdf.text("Trafik Sigortası Teklifleri", 7, 1);
    pdf.setFontSize(15)
    pdf.text("Sigortalı Bilgileri", 2, 2)
    pdf.line(2, 2.2, 19, 2.2)
    pdf.setFontSize(smallFontSize)

    pdf.text("Tc Kimlik / Vergi No ", 2, 3)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.kimlikNo, farToLeftForInput, 3)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Sigortalı Adı / Ünvanı ", 2, 3.5)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.sigortaliAdUnvanModel, farToLeftForInput, 3.5)
    // pdf.setFont("Roboto-Bold")
    pdf.text("İl / ilçe  ", 2, 4)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.responseModel.Sigortali.Kisi.Adres.Il + " / " + this.responseModel.Sigortali.Kisi.Adres.Ilce, farToLeftForInput, 4)

    pdf.setLineWidth(0.025);
    pdf.setFontSize(15)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Araç Bigileri", 2, 5);
    pdf.line(2, 5.2, 19, 5.2)
    pdf.setFontSize(smallFontSize)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Plaka ", 2, 6)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.plaka, farToLeftForInput, 6)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Motor No ", 2, 6.5)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.selectedAracMotorNo, farToLeftForInput, 6.5)
    pdf.setFontSize(smallFontSize)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Şasi No ", 2, 7)
    pdf.setFont("Roboto-Regular", "normal")
    pdf.text(": " + this.selectedAracSasiNo, farToLeftForInput, 7)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Marka Adı ", 2, 7.5)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.selectedAracMarkaModel.adi, farToLeftForInput, 7.5)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Model Adı ", 2, 8)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.responseModel.Arac.ModelAdi, farToLeftForInput, 8)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Kullanım Şekli ", 2, 8.5)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.selectedAracKullanimSekli.adi, farToLeftForInput, 8.5)

    pdf.setFontSize(smallFontSize)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Ruhsat Belge Seri No", farToLeftForAracBilgileri, 6)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.ruhsat, farToLeftForAracBilgileriInput, 6)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Araç Kodu  ", farToLeftForAracBilgileri, 6.5)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.responseModel.Arac.AracKodu, farToLeftForAracBilgileriInput, 6.5)
    pdf.setFontSize(smallFontSize)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Model Yılı ", farToLeftForAracBilgileri, 7)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.responseModel.Arac.ModelYili.toString(), farToLeftForAracBilgileriInput, 7)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Tescil Tarihi   ", farToLeftForAracBilgileri, 7.5)
    pdf.setFont("Roboto-Regular")

    var tesciltarihi = this.dateTimeOnlyDate(this.selectedAracTescilTarihi)
    pdf.text(": " + tesciltarihi.toString(), farToLeftForAracBilgileriInput, 7.5)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Yolcu Sayısı ", farToLeftForAracBilgileri, 8)
    pdf.setFont("Roboto-Regular")
    pdf.text(": " + this.selectedAracYolcuSayisi.toString(), farToLeftForAracBilgileriInput, 8)

    pdf.setFontSize(15)
    // pdf.setFont("Roboto-Bold")
    pdf.text("Sigorta Şirketi", 2, 9.5)
    pdf.text("Prim", 12, 9.5)
    pdf.line(2, 9.7, 19, 9.7)
    pdf.setFontSize(12)
    pdf.setLineWidth(0.015)
    let i = 0
    this.dataSource.forEach(urun => {
      if (urun.brutPrim != "" && urun.brutPrim != undefined) {
        if (urun.listeSecimiAktifMi == false && this.selection.selected) {
          this.selection.selected.forEach(selectedUrunler => {
            for (let a = 0; a < selectedUrunler.length; a++) {
              if (selectedUrunler[a] === urun.id) {
                pdf.text(urun.sigortaSirketKisaAdi, 2, 10.5 + i)
                pdf.text(urun.brutPrim + " TL", 13.1, 10.5 + i, { align: 'right' })
                pdf.line(2, 10.8 + i, 15, 10.8 + i)
                i = i + 1
              }
            }
          })
        }
        else {
          pdf.text(urun.sigortaSirketKisaAdi, 2, 10.5 + i)
          pdf.text(urun.brutPrim + " TL", 13.1, 10.5 + i, { align: 'right' })
          pdf.line(2, 10.8 + i, 15, 10.8 + i)
          i = i + 1
        }
      }
    });
    pdf.setFont("Roboto-Regular")
    pdf.setFontSize(10)
    pdf.line(2, 28, 19, 28)
    pdf.text("Esentepe Mah. İnönü Cad. Kartal Kule Plaza", 2.5, 28.5)
    pdf.text("No :147 /Kat :4 D: 40", 2.5, 28.9)
    pdf.text("Kartal / İSTANBUL", 2.5, 29.3)

    pdf.text("+90 (216) 232 23 13", 10, 28.5)
    pdf.text("info@polimetre.net", 15, 28.5)

    pdf.save('TekliflerTablosu.pdf');
  }
  sortData(sort: Sort) { //teklifler için sorting yapan metod
    var data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sigortaSirketKisaAdi': return this.compare(a.sigortaSirketKisaAdi, b.sigortaSirketKisaAdi, isAsc);
        case 'urunAdi': return this.compare(a.sigortaSirketiSembolAdi, b.sigortaSirketiSembolAdi, isAsc);
        case 'teklifNo': return this.compare(a.teklifNo, b.teklifNo, isAsc);
        case 'brutPrim': return this.compareWithPrim(a.brutPrim, b.brutPrim, isAsc);

        default: return 0;
      }
    });
  }
  sortModels: Sort = {
    active: "modelAdi",
    direction: "asc"
  }
  sortModel(sort: Sort) {//Modeli sort eden metod
    var dat = this.modellerDataTmp.slice();
    if (!sort.active || sort.direction === '') {
      this.modellerDataTmp = dat;
      return;
    }
    this.modellerDataTmp = dat.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'modelAdi': return this.compare(a.modelAdi, b.modelAdi, isAsc);
        default: return 0;
      }
    });
  }

  sortCities: Sort = {
    active: "ilAdi",
    direction: "asc"
  }
  sortCity(sort: Sort) {//İli sort eden metod
    var dat = this.ilModel.slice();
    if (!sort.active || sort.direction === '') {
      this.ilModel = dat;
      return;
    }

    this.ilModel = dat.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ilAdi': return this.compare(a.ilAdi, b.ilAdi, isAsc);
        default: return 0;
      }
    });
  }
  sortDistricts: Sort = {
    active: "ilceAdi",
    direction: "asc"
  }
  sortDistrict(sort: Sort) {//İli sort eden metod
    var dat = this.ilceModel.slice();
    if (!sort.active || sort.direction === '') {
      this.ilceModel = dat;
      return;
    }

    this.ilceModel = dat.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ilceAdi': return this.compare(a.ilceAdi, b.ilceAdi, isAsc);
        default: return 0;
      }
    });
  }
  // Prim İçin karşılaştırma yapan metod
  compareWithPrim(a: string, b: string, isAsc: boolean) {
    if (isAsc == false) {
      if (a.length != b.length) {
        return (a.length < b.length ? -1 : 1) * (-1);
      }
      return (a < b ? -1 : 1) * (-1);
    }
    else {
      if (a.length != b.length && a.length != 0 && b.length != 0) {
        return (a.length > b.length ? -1 : 1) * (-1);
      } else if (a.length === b.length && a.length != 0 && b.length != 0) {
        return (a > b ? -1 : 1) * (-1);
      }
      return (a.length < b.length ? -1 : 1) * (-1);
    }
  }
  // Diğer Bilgiler için karşılaştırma yapan metod
  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a == 'string' && typeof b == 'string') {
      return a.localeCompare(b, "tr") * (isAsc ? 1 : -1);
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  validationCall() {
    Object.keys(this.onBilgiSorgulamaFormGroup.controls).forEach(key => {
      this.onBilgiSorgulamaFormGroup.controls[key].markAsTouched();
    });
    if (this.onBilgiSorgulamaFormGroup.get('kimlikNoInputElement').hasError('required')) {
      this.validationMessage = "Lütfen Kimlik No bilgisi giriniz"
      $("mat-sidenav-content").scrollTop(0);
      return
    }
    if (this.onBilgiSorgulamaFormGroup.get('kimlikNoInputElement').hasError('pattern')) {
      this.validationMessage = "Kimlik No bilgisi 10 ya da 11 numara yazılmalıdır"
      $("mat-sidenav-content").scrollTop(0);
      return
    }
    if (this.onBilgiSorgulamaFormGroup.get('plakaInputElement').hasError('required')) {
      this.validationMessage = "Lütfen Plaka bilgisi giriniz"
      $("mat-sidenav-content").scrollTop(0);
      return
    }
    if (this.onBilgiSorgulamaFormGroup.get('plakaInputElement').hasError('pattern')) {
      this.validationMessage = "Lütfen geçerli bir plaka giriniz"
      $("mat-sidenav-content").scrollTop(0);
      return
    }
    if (this.onBilgiSorgulamaFormGroup.get('ruhsatInputCtrl').hasError('required')) {
      this.validationMessage = "Lütfen Ruhsat Belge No bilgisi giriniz"
      $("mat-sidenav-content").scrollTop(0);
      return
    }
    if (this.onBilgiSorgulamaFormGroup.get('ruhsatInputCtrl').hasError('pattern')) {
      this.validationMessage = "Ruhsat Belge No 8 karakterden oluşmalıdır"
      $("mat-sidenav-content").scrollTop(0);
      return
    }
    if (this.sigortaliVeAracBilgileriSubmit) {
      Object.keys(this.sigortaliVeAracBilgileri.controls).forEach(key => {
        this.sigortaliVeAracBilgileri.controls[key].markAsTouched();
      });
      if (this.sigortaliVeAracBilgileri.get('sigortaliAdUnvanInputElement').hasError('required')) {
        this.validationMessage = "Lütfen Sigortalı Ad/Unvan bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.kimlikNo.length == 11 && this.sigortaliVeAracBilgileri.get('dogumTarihiDTPElement').hasError('required')) {
        this.validationMessage = "Lütfen Doğum Tarihi bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('sehirlerControl').hasError('required')) {
        this.validationMessage = "Lütfen İl bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('ilcelerControl').hasError('required')) {
        this.validationMessage = "Lütfen İlçe bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('sigortaliGSMInputElement').hasError('required')) {
        this.validationMessage = 'Lütfen telefon numarası giriniz'
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('sigortaliGSMInputElement').hasError('pattern')) {
        this.validationMessage = 'Telefon numarası "0 (xxx) xxx xx xx" formatında olmak zorunda'
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('sigortaliEmailInputElement').hasError('required')) {
        this.validationMessage = "Lütfen mail adresi giriniz "
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('sigortaliEmailInputElement').hasError('pattern')) {
        this.validationMessage = "Lütfen geçerli bir mail adresi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracMarkalarControl').hasError('required')) {
        this.validationMessage = "Lütfen Marka bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracModellerControl').hasError('required')) {
        this.validationMessage = "Lütfen Model bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracModelYiliControl').hasError('required')) {
        this.validationMessage = "Lütfen Model Yılı bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracYolcuSayisiInputElement').hasError('required')) {
        this.validationMessage = "Lütfen Yolcu Sayısı bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracYolcuSayisiInputElement').hasError('pattern')) {
        this.validationMessage = "Lütfen Yolcu Sayısı bilgisi 0 sayısıyla başlayamaz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracKullanimSekliControl').hasError('required')) {
        this.validationMessage = "Lütfen Kullanım Şekli bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracTarifeGrupKoduControl').hasError('required')) {
        this.validationMessage = "Lütfen Kullanım Tarzı bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }

      if (this.sigortaliVeAracBilgileri.get('aracTrafigeCikisTarihiDTPElement').hasError('required')) {
        this.validationMessage = "Lütfen Trafiğe Çıkış bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }

      if (this.sigortaliVeAracBilgileri.get('aracTescilTarihiDTPElement').hasError('required')) {
        this.validationMessage = "Lütfen Tescil Tarihi bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracMotorNoInputElement').hasError('required')) {
        this.validationMessage = "Lütfen Motor No bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
      if (this.sigortaliVeAracBilgileri.get('aracSasiNoInputElement').hasError('required')) {
        this.validationMessage = "Lütfen Şasi No bilgisi giriniz"
        $("mat-sidenav-content").scrollTop(0);
        return
      }
    }
    if (this.sigortaliVeAracBilgileri.valid == false || this.onBilgiSorgulamaFormGroup.valid == false) {
      $("mat-sidenav-content").scrollTop(0);
    }
  }
}