import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { faBuilding, faCarCrash, faCoffee, faHeartbeat, faHome, faMobile, faRoute, faTrafficLight, faWheelchair } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/service/loadService/loading.service';
import { AuthService } from 'src/app/service/auth.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent  {
  headerTitle= "Online İşlemler "
  faCarCrash = faCarCrash;
  faTraffic = faTrafficLight
  faHome = faHome
  faBuilding = faBuilding
  faHeartBeat = faHeartbeat
  faWheelchair = faWheelchair
  faRoute = faRoute
  faMobile = faMobile
  constructor(private title: Title,
    private router: Router,
    private snackBar:MatSnackBar,
    public loadingService:LoadingService,
    private authService:AuthService
  ) {
    title.setTitle("SigortaSepeti")
  }

  disablePolice(e:string) {
    this.tokenGecerliMi()
    this.snackBar.open("Değerli kullanıcımız "+e+ " henüz yapım aşamasında.", 'Kapat', {
      duration: 4000,
    });
  }
  tokenGecerliMi(){
    let reqToken = localStorage.getItem('access_token');
    if (reqToken === null || reqToken === ''||reqToken==undefined) {
      this.router.navigate(["login"]);
    }
     this.authService.isValidateToken(reqToken).subscribe(
      (data) => {
        if (data.result) {
          console.log("Token geçerli")
          return true;
        }else{
        this.router.navigate(["login"]); 
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
  }
}
