import { Pipe, PipeTransform } from '@angular/core';
import { SirketEkranLinkResponse } from '../models/SigortaSirket/sirket-ekran-link-response';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {
   transform(value: SirketEkranLinkResponse[], filterText: string): SirketEkranLinkResponse[] {
    filterText = filterText?filterText.toLocaleLowerCase():""
    return filterText?value
    .filter((p:SirketEkranLinkResponse)=>p.linkAdi.toLocaleLowerCase().indexOf(filterText)!==-1)
    :value;
  }
}
