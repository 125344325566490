

<div style="width: 450px;">
  <div style="text-align: center; width: 100%; height: 80px;"><fa-icon [icon]="faUnlock" style="color: #009688; font-size: 55px; "></fa-icon></div> 

    <!-- <mat-card-header class="col-md-12" 
      style=" background-color: #009688;color:rgb(253, 253, 253); margin: auto; height: 40px;border-top-left-radius: 4px;border-top-right-radius: 4px;">
      <mat-label class="h5 col-md-6" style="margin-top: auto;">Şifremi Yenile</mat-label>
    </mat-card-header> -->
    <mat-card class="col-md-12" class="cardBorder">
      <mat-card-header class="col-md-12" 
      style=" background-color: #009688;color:rgb(253, 253, 253); margin: auto; height: 40px; border-top-left-radius: 10px;border-top-right-radius: 10px; ">
      <mat-label class="h5 col-md-6" style="margin-top: auto;">Şifremi Yenile</mat-label>
    </mat-card-header>
        <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="validationMessage">
            <fa-icon [icon]="exclamationCircle"></fa-icon>
            <div style="margin-left: 10px;">
              <b>{{validationMessage}}</b>
            </div>
          </div>

      <mat-card-content style="margin-top: 10px; padding: 16px;">
        <form [formGroup]="sifremiUnuttumFormGroup" (ngSubmit)="sifreGuncelle()" #form="ngForm">
         
            <mat-form-field appearance="outline">
              <mat-label>E-Posta</mat-label>
              <span matPrefix ><fa-icon [icon]="faUser" style="color: #009688; font-size: 15px;margin-right: 5px;">
              </fa-icon> &nbsp;</span>
              <input id="emailInput" matInput placeholder="pat@example.com" [(ngModel)]="sifremiUnttumRM.email"
                formControlName="emailInputElement">
              <!--<mat-error *ngIf="emailInputElement.invalid">{{getErrorMessage()}}</mat-error>-->
            </mat-form-field>
                 
  
          <div>
            <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
              siteKey="6Lc7EIUaAAAAALLlnRFAJcS_UfcaaNhbTCCU5YVa">
            </re-captcha>
          </div>
         
          <div style="width: 300px;">
            <button type="submit" mat-raised-button color="primary"
              style="margin-top: 10px;height: 35px; float: right; width: 110px;  background-color: #009688;">
              Devam Et <span class="material-icons">forward</span>
            </button>
          </div>
  
        </form>
      </mat-card-content>
    </mat-card>
    <br>
    <mat-progress-bar *ngIf="matSpinner" mode="indeterminate"></mat-progress-bar>
  </div>