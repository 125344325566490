import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {kaskoCoreApiBaseUrl, onBilgiBaseUrl, trafikCoreApiBaseUrl} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Trafikonbilgi} from '../models/OnBilgi/Trafik/trafikonbilgi';
import KaskoOnBilgi from '../models/OnBilgi/Kasko/kaskoonbilgi';

@Injectable({
  providedIn: 'root'
})
export class OnBilgiService {

  constructor(private http: HttpClient) { }

  kaskoOnBilgiGetir(kimlikNo: any, plaka: any, ruhsat: any, token: any): Observable<KaskoOnBilgi>{
    return this.http.post<KaskoOnBilgi>(`${onBilgiBaseUrl}OnBilgi/Kasko/Hybrid?sorguTipi=1`, {
      KimlikNo:  kimlikNo,
      Plaka:  plaka,
      Ruhsat:  ruhsat,
      token: token
    }).pipe(
      map((response: KaskoOnBilgi) =>{
        return response;
      })
    );
  }

  kaskoOnBilgiKaydet(kaskoOnBilgi: KaskoOnBilgi): Promise<any>{
    return this.http.post<any>(`${kaskoCoreApiBaseUrl}api/OnBilgiKaydet`, { onBilgi: kaskoOnBilgi }).toPromise()
  }

  trafikOnBilgiGetir(kimlikNo: any, plaka: any, ruhsat: any, token: any): Observable<Trafikonbilgi>{
    return this.http.post<Trafikonbilgi>(`${onBilgiBaseUrl}OnBilgi/Trafik/Hybrid?sorguTipi=1`, {
      KimlikNo:  kimlikNo,
      Plaka:  plaka,
      Ruhsat:  ruhsat,
      token: token
    }).pipe(
      map((response: Trafikonbilgi) =>{
        return response;
      })
    );
  }

  trafikOnBilgiKaydett(trafikOnBilgi: Trafikonbilgi): Observable<any>{
    return this.http.post<any>(`${trafikCoreApiBaseUrl}api/OnBilgiKaydet`, { onBilgi: trafikOnBilgi }).pipe(
      map((response: any) =>{
        return response;
      })
    );
  }

   trafikOnBilgiKaydet(trafikOnBilgi: Trafikonbilgi):Promise<any>{
    return this.http.post<any>(`${trafikCoreApiBaseUrl}api/OnBilgiKaydet`, { onBilgi: trafikOnBilgi }).toPromise()    
  }
}
