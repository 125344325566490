import { SifreOlusturRequestModel } from 'src/app/models/Auth/SifreOlustur/sifre-olustur-request-model';
import { SifreOlusturResponseModel } from '../../models/Auth/SifreOlustur/sifre-olustur-response-model';
import { AuthService } from 'src/app/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faExclamationCircle, faKey, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sifre-olustur',
  templateUrl: './sifre-olustur.component.html',
  styleUrls: ['./sifre-olustur.component.css']
})
export class SifreOlusturComponent implements OnInit {
  headerTitle = ""

  exclamationCircle=faExclamationCircle
  faPassword=faKey
  faUnlock=faUnlockAlt
  hide = true;
  matSpinner:boolean=false
  validationMessage:string
  sifreOlusturRM:SifreOlusturRequestModel
  sifremiOlusturFormGroup:FormGroup
  sifreOlusturResponseModel:SifreOlusturResponseModel
  encodedToken:any
  params:any
  mail:string
  constructor( private _activatedRoute:ActivatedRoute,private _authService:AuthService,private _snackBar:MatSnackBar,private _router:Router) {
    this.sifreOlusturRM=new SifreOlusturRequestModel()
    this.sifreOlusturResponseModel=new SifreOlusturResponseModel()
   }

   
  ngOnInit(): void {
    this.encodedToken=this._activatedRoute.snapshot.paramMap.get('Token');
 
      console.log(this.encodedToken)

    this.sifremiOlusturFormGroup = new FormGroup({
      sifreTekrarlaInputElement: new FormControl('',
        Validators.compose([
          Validators.required,
        ])),
      sifreInputElement: new FormControl('',
        Validators.compose([
          Validators.required
        ])),
      recaptchaReactive: new FormControl('',Validators.required)
    });
  }
  resolved(captchaResponse: string) {
    this.sifreOlusturRM.googleCaptchaToken = captchaResponse;
  }
  sifreOlustur(){
    if (this.sifremiOlusturFormGroup.invalid||this.sifreOlusturRM.sifre!=this.sifreOlusturRM.sifreTekrarla) {
      this.validationCall()
      return
    }
    this.validationMessage=""
    this.matSpinner=true

    this._authService.sifreOlustur(this.sifreOlusturRM,this.encodedToken).subscribe(
      (data) => {
        console.log(data)
        this.sifreOlusturResponseModel = data;

        if (data.result) {
          console.log(this.sifreOlusturResponseModel)
          setTimeout(() => {
            this._router.navigate(["login"]);
          }, 4000);
          this._snackBar.open( data.message,"Kapat", {duration: 5000,});
          this.matSpinner=false
        }else{
          this.matSpinner=false
    
          console.log(this.sifreOlusturResponseModel)
          this._snackBar.open('' + this.sifreOlusturResponseModel.message, 'Kapat', {
            duration: 5000,
          });
        }
      },
      (error) => {
        this.matSpinner=false  
        this.sifreOlusturResponseModel = error;
        console.log(this.sifreOlusturResponseModel)
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 5000,
        });
      }
    )
  }
  validationCall(){    
    if (this.sifremiOlusturFormGroup.get('recaptchaReactive').hasError('required')) {
      this.validationMessage="Lütfen recaptcha bilgisi giriniz"
    }
    if (this.sifreOlusturRM.sifre!=this.sifreOlusturRM.sifreTekrarla) {
      this.validationMessage="Şifreleriniz farklı"
    }
    if (this.sifremiOlusturFormGroup.get('sifreTekrarlaInputElement').hasError('required')) {
      this.validationMessage="Lütfen şifrenizi tekrarlama alanını doldurunuz"
    } 
    if (this.sifremiOlusturFormGroup.get('sifreInputElement').hasError('required')) {
      this.validationMessage="Lütfen şifre bilgisi giriniz"
    }   
  }
}
