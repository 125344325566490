import { InterceptorService } from './service/loadService/interceptor.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from "./material/material.module";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UihelperService } from "./service/uihelper.service";
import {AuthService} from './service/auth.service';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxTextBoxModule
} from 'devextreme-angular';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {DxoTabPanelOptions} from 'devextreme-angular/ui/nested/base/tab-panel-options';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { KaskoteklifComponent } from './components/kaskoteklif/kaskoteklif.component';
import { TrafikteklifComponent } from './components/trafikteklif/trafikteklif.component';
import { TekliflistComponent } from './components/tekliflist/tekliflist.component';
import { LoginGuard } from './components/login/login.guard';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './pipes/customDataAdaptor';
import { SifremiUnuttumComponent } from './components/sifremi-unuttum/sifremi-unuttum.component';
import { SifreOlusturComponent } from './components/sifre-olustur/sifre-olustur.component';
import { FilterPipePipe } from './pipes/filter-pipe.pipe';
import { SirketlerComponent } from './components/sirketler/sirketler.component';
import { PhonePipe } from './pipes/phone.pipe';
import { KaskoDegeriPipe } from './pipes/kasko-degeri.pipe';

const appRoutes: Routes= [
  // { path: '', component: HomeComponent},
  { path: 'login', component: LoginComponent},
  { path: 'sifre/yenile', component: SifremiUnuttumComponent},
    { path: 'sirketler', component: SirketlerComponent, canActivate: [LoginGuard]},
  { path: 'sifre/olustur/:Token', component: SifreOlusturComponent},
  { path: 'onlineislemler', component: HomeComponent, canActivate: [LoginGuard]},
  { path: 'kasko', component: KaskoteklifComponent, canActivate: [LoginGuard] },
  { path: 'trafik', component: TrafikteklifComponent, canActivate: [LoginGuard]},
  { path: 'tekliflistesi', component: TekliflistComponent, canActivate: [LoginGuard] },
  /*{ path: 'tekliflistele', component: TekliflisteleComponent, canActivate: [LoginGuard] },*/
  { path: '**', component: HomeComponent , canActivate: [LoginGuard] }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    KaskoteklifComponent,
    TrafikteklifComponent,
    TekliflistComponent,
    NumberFormatPipe,
    SifremiUnuttumComponent,
    SifreOlusturComponent,
    FilterPipePipe,
    SirketlerComponent,
    PhonePipe,
    KaskoDegeriPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RecaptchaModule,   // bu recaptcha ana modülüdür
    RecaptchaFormsModule,  // bu form doğrulama için modüldür
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTabPanelModule,
    DxFormModule,
    DxSelectBoxModule,
    DxTabPanelModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxiItemModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    DxDateBoxModule,
    FontAwesomeModule,
  ],
  providers: [ 
  UihelperService, AuthService, LoginGuard,{provide:HTTP_INTERCEPTORS,useClass:InterceptorService,multi:true},
  {provide: DateAdapter, useClass: CustomDateAdapter }],
  bootstrap: [AppComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule); zaten sağlayıcı olarak AppComponent komponent bu kodla tutuluyor bootstrap: [AppComponent]
