<div style="width: 450px; margin-top: 3%;">

  <mat-card class="col-md-12" class="cardBorder">
    <mat-card-header class="col-md-12"
      style=" background-color: #009688;color:rgb(253, 253, 253); margin: auto; height: 40px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
      <mat-label class="h5 col-md-6" style="margin-top: auto;">Kullanıcı Girişi</mat-label>
    </mat-card-header>
    <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="validationMessage">
      <fa-icon [icon]="exclamationCircle"></fa-icon>
      <div style="margin-left: 10px;">
        <b>{{validationMessage}}</b>
      </div>
    </div>
    <mat-card-content style="padding: 16px; ">
      <form [formGroup]="uyeGirisFormGroup" (ngSubmit)="Login()" #form="ngForm">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>E-Posta</mat-label>
            <span matPrefix>
              <fa-icon [icon]="faUser" style="color: #009688; font-size: 15px;margin-right: 5px;">
              </fa-icon> &nbsp;
            </span>
            <input id="emailInput" matInput placeholder="pat@example.com" [(ngModel)]="loginRM.email"
              formControlName="emailInputElement">
            <!--<mat-error *ngIf="emailInputElement.invalid">{{getErrorMessage()}}</mat-error>-->
          </mat-form-field>
       
          <!--<mat-label class="col-sm-1 h5">E-Mail: </mat-label>-->
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Şifre</mat-label>
            <span matPrefix>
              <fa-icon [icon]="faPassword" style="color: #009688; font-size: 15px;margin-right: 5px;">
              </fa-icon> &nbsp;
            </span>
            <input id="sifreInput" matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="loginRM.sifre"
              formControlName="sifreInputElement">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>        
        </div>

        <div>
          <re-captcha class="g-recaptcha" id="reCaptcha" (resolved)="resolved($event)" formControlName="recaptchaReactive"style="transform:scale(0.50);-webkit-transform:scale(0.50);transform-origin:0 0;-webkit-transform-origin:0 0;"
           siteKey="6Lc7EIUaAAAAALLlnRFAJcS_UfcaaNhbTCCU5YVa">
          </re-captcha>
        </div>
      
        
        <div style="width: 300px;">
          <a routerLink="/sifre/yenile" style="float: left;margin-top: 17px; color: #009688;">Şifremi Unuttum</a>
          <button type="submit" mat-raised-button color="primary"
            style="margin-top: 10px;height: 35px; float: right; width: 130px;  background-color: #009688;">
            Giriş <span class="material-icons">login</span>
          </button>
        </div>


        <!--<mat-label class="col-sm-1 h5">Şifre: </mat-label>-->
        <!-- <section class="example-section" > -->
        <!--<mat-checkbox color="primary" class="example-margin">Beni Hatırla</mat-checkbox>-->
        <!-- <div class="example-button-row">
              <button type="submit" mat-raised-button  color="primary" style="margin-top: 10px;height: 35px; ">
              <p style="position: absolute; margin-left: -5px;">Giriş</p>  <span class="material-icons" style="margin-left: 30px;">login</span>
              </button>
            </div>
          </section> -->

      </form>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-progress-bar *ngIf="matSpinner" mode="indeterminate"></mat-progress-bar>
</div>