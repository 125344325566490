import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SigortaliFilterResultModel} from '../models/Teklif/List/sigortali-filter-result-model';
import {Observable} from 'rxjs';
import {uiHelperApiBaseUrl} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {TeklifListRequestModel} from '../models/Teklif/List/teklif-list-request-model';
import {TeklifListRM} from '../models/Teklif/List/teklif-list-rm';
import {IsOrtagiFilterResultModel} from '../models/Teklif/List/is-ortagi-filter-result-model';

@Injectable({
  providedIn: 'root'
})
export class TekliflistserviceService {

  constructor(private http: HttpClient) {


  }

  sigortaliFilterResultGetir(token: string,contains:string): Observable<SigortaliFilterResultModel[]> {
    return this.http.post<any>(`${uiHelperApiBaseUrl}api/SigortaliFilterResultGetir`, {
      token: token,
      contains:contains
    }).pipe(map(
      (response: SigortaliFilterResultModel[]) => {
        return response;
      }
    ));
  }

  isOrtagiListResultGetir(token: any): Observable<IsOrtagiFilterResultModel[]> {
    return this.http.post<any>(`${uiHelperApiBaseUrl}api/IsOrtagiFilterResultGetir`, {
      authToken: token
    }).pipe(map(
      (response: IsOrtagiFilterResultModel[]) => {
        return response;
      }
    ));
  }

  teklifResultGetir(teklifListRequestModel: TeklifListRequestModel): Observable<TeklifListRM[]>{
    return this.http.post<any>(`${uiHelperApiBaseUrl}api/TeklifListele`, {
      SigortaliKimlikNo: teklifListRequestModel.sigortaliKimlikNo,
      Plaka: teklifListRequestModel.plaka,
      HizliTeklifNo: teklifListRequestModel.hizliTeklifNo,
      BaslangicTarihi: teklifListRequestModel.baslangicTarihi,
      BitisTarihi: teklifListRequestModel.bitisTarihi,
      sadeceBasariliTeklifleriGetir: teklifListRequestModel.sadeceBasariliTeklifleriGetir,
      authToken: teklifListRequestModel.authToken,
      sorguTipi: teklifListRequestModel.sorguTipi,
      filtreIsOrtagiId: teklifListRequestModel.filtreIsOrtagiId
    }).pipe(map(
      (response: any) => {
        return response;
      }
    ));
  }

}
