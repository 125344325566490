// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true
};

export const uiHelperApiBaseUrl   = `http://wsprod.sigortasepeti.com/uihelperapi/`;
export const authApiBaseUrl       = `http://wsprod.sigortasepeti.com/authapi/`;
export const onBilgiBaseUrl       = `http://wsprod.sigortasepeti.com/onbilgiapi/`;
export const kaskoCoreApiBaseUrl  = `http://wsprod.sigortasepeti.com/kaskoapi/`;
export const trafikCoreApiBaseUrl = `http://wsprod.sigortasepeti.com/trafikapi/`;
export const sirketAuthApiBaseUrl  = `https://wsprod.sigortasepeti.com/sirketauthapi/`;

//export const uiHelperApiBaseUrl   = `http://wstest.sigortasepeti.com/uihelperapi/`;
//export const authApiBaseUrl       = `http://wstest.sigortasepeti.com/authapi/`;
//export const onBilgiBaseUrl       = `http://wstest.sigortasepeti.com/onbilgiapi/`;
//export const kaskoCoreApiBaseUrl  = `http://wstest.sigortasepeti.com/kaskoapi/`;
//export const trafikCoreApiBaseUrl  = `http://wstest.sigortasepeti.com/trafikapi/`;


//export const uiHelperApiBaseUrl   = `https://wsdev.sigortasepeti.com/uihelperapi/`;
//export const authApiBaseUrl       = `https://wsdev.sigortasepeti.com/authapi/`;
//export const onBilgiBaseUrl       = `https://wsdev.sigortasepeti.com/onbilgiapi/`;
//export const kaskoCoreApiBaseUrl  = `https://wsdev.sigortasepeti.com/kaskoapi/`;
//export const trafikCoreApiBaseUrl  = `https://wsdev.sigortasepeti.com/trafikapi/`;
//export const sirketAuthApiBaseUrl  = `https://wsdev.sigortasepeti.com/sirketauthapi/`;


//export const kaskoCoreApiBaseUrl  = `https://localhost:44326/`;
// export const trafikCoreApiBaseUrl = `https://localhost:44385/`;
//export const sirketAuthApiBaseUrl  = `https://localhost:44326/`;
//export const onBilgiBaseUrl       = `https://localhost:44326/`;
//export const uiHelperApiBaseUrl   = `https://localhost:44326/`;
//export const authApiBaseUrl       = `https://localhost:44326/`;

