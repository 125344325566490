<section style="background-color: rgba(68, 68, 68, 0.5); height: 100%; position: fixed; width: 100%; z-index: 998;"
  *ngIf="(loadingService.isLoading|async)||onSpinner==false">
  <mat-spinner [diameter]="50" style="  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;"></mat-spinner>
</section>
<button mat-icon-button class="upTopIcon" *ngIf="scroll" (window:scroll)="onScroll($event)">
  <fa-icon [icon]="faCaretSquareUp" (click)="scrollForStep1()"></fa-icon>  
</button>
<div class="container col-lg-10" >
  <mat-card>
    <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="validationMessage">
      <fa-icon [icon]="exclamationCircle"></fa-icon>
      <div style="margin-left: 10px;">
        <b>{{validationMessage}}</b>
      </div>
    </div>
    <mat-vertical-stepper [linear]="isLinear" #stepper style="background-color: #f8f7f7ec;">
      <mat-step [stepControl]="onBilgiSorgulamaFormGroup" [optional]="isOptional">
        <ng-template matStepLabel style="height: 10px;">
          <h5 ><b>Kimlik No, Plaka ve Ruhsat Bilgileri</b></h5>
        </ng-template>
        <mat-card class="cardBorder">
          <mat-card-content>
            <form [formGroup]="onBilgiSorgulamaFormGroup" #onBilgiSorgulamaNgFom="ngForm">
              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Kimlik No: </mat-label>
                <input maxlength="11" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  id="kimlikNoInput" matInput style="border-color: aquamarine;" formControlName="kimlikNoInputElement"
                  placeholder="Kimlik No">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Plaka: </mat-label>
                <input maxlength="10" oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')" class="upperCase" id="plakaInput" matInput
                  formControlName="plakaInputElement" placeholder="Plaka">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Ruhsat Belge No: </mat-label>
                <input maxlength="8" oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')" class="upperCase" id="ruhsatInput" matInput
                  formControlName="ruhsatInputCtrl" placeholder="Ruhsat Belge No">
              </mat-form-field>
              <br class="brForSorgulaButton">
              <button style="margin-left: 15px;" id="sorgulaButton" class="buttonHight" (click)="kaskoOnBilgiSorgula()" mat-raised-button
                color="primary"><span class="material-icons iconhover">search</span>Sorgula</button>
              <button style="margin-left: 15px;" class="buttonHight" mat-raised-button color="primary"
                (click)="herseyiTemizle()"><span
                  class="material-icons iconhover">cleaning_services</span>Temizle</button>
              <section *ngIf="!onSpinner" class="example-section" style="margin-top: 10px;">
                <div *ngIf="!onSpinner" id="loader ">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                
              </section>
              <div style="display: none;">
                <button (click)="scrollForStep2()" id="btnDevamEt" mat-button matStepperNext
                  style="display: none;">Devam Et</button>
                <button id="btnFormCleaner" mat-button (click)="stepper.reset()" style="display: none;">Temizle</button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </mat-step>

      <mat-step [stepControl]="sigortaliVeAracBilgileri" [optional]="isOptional">
        <form [formGroup]="sigortaliVeAracBilgileri" #sigortaliVeAracBilgileriNgFom="ngForm">
          <ng-template matStepLabel>
            <h5 ><b>Sigortalı ve Araç Bilgileri</b></h5>
          </ng-template>
          <br>
          <mat-card class="cardBorder">
            <mat-card-header class="cardHeader">
              <p class="cardHeaderTitle">Sigortalı Bilgileri</p>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field appearance="outline" id="sigortaliAdUnvanFormField">
                <mat-label>Sigortalı Ad / Ünvan:</mat-label>
                <input  oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '')" matInput name="sigortaliAdUnvanInputElement" formControlName="sigortaliAdUnvanInputElement"
                  [ngModel]="sigortaliAdUnvanModel" (ngModelChange)="onSigortaliAdUnvanChange($event)"
                  placeholder="Ad/Ünvan">
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label class="formLabel">Dğm Trh:</mat-label>
                <input name="dogumTarihiDTPElement" matInput formControlName="dogumTarihiDTPElement"
                  [ngModel]="selectedDogumTarihi"  (ngModelChange)="onDogumTarihiChange($event)"
                  [matDatepicker]="dogumTarihiPicker">
                <mat-datepicker-toggle matSuffix [for]="dogumTarihiPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #dogumTarihiPicker></mat-datepicker>
              </mat-form-field>
              <br>
              <mat-form-field appearance="outline">
                <mat-label>İl:</mat-label>
                <mat-select name="sehirlerControl" formControlName="sehirlerControl" [ngModel]="selectedIlModel"
                  (ngModelChange)="onSehirChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let sehir of ilModel" [value]="sehir">
                    {{sehir.ilAdi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label class="formLabel">İlçe:</mat-label>
                <mat-select name="ilcelerControl" formControlName="ilcelerControl" [ngModel]="selectedIlceModel"
                  (ngModelChange)="onIlceChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let ilce of ilceModel" [value]="ilce.ilceAdi">
                    {{ilce.ilceAdi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Mahalle:</mat-label>
                <input name="sigortaliMahalleInputElement" matInput formControlName="sigortaliMahalleInputElement"
                  placeholder="Mahalle" [ngModel]="selectedSigortaliMahalle"
                  (ngModelChange)="onSigortaliMahalleChange($event)">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Cadde:</mat-label>
                <input name="sigortaliCaddeInputElement" matInput formControlName="sigortaliCaddeInputElement"
                  placeholder="Cadde" [ngModel]="selectedSigortaliCadde"
                  (ngModelChange)="onSigortaliCaddeChange($event)">
              </mat-form-field>
              <br>

              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Sokak:</mat-label>
                <input name="sigortaliSokakInputElement" matInput formControlName="sigortaliSokakInputElement"
                  placeholder="Sokak" [ngModel]="selectedSigortaliSokak"
                  (ngModelChange)="onSigortaliSokakChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label class="formLabel">GSM(Telefon) No:</mat-label>
                <input oninput="this.value = this.value=='0'?'':this.value" type="tel" name="sigortaliGSMInputElement" matInput formControlName="sigortaliGSMInputElement"
                placeholder="0 (xxx) xxx xx xx" [ngModel]="selectedGSM" (ngModelChange)="onSigortaliGSMChange($event)" maxlength="17">
                  <fa-icon [icon]="faPhone" matSuffix style="color: #009688;font-size:15px;position: absolute;   top: -17px; right: 10px; "></fa-icon>
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Email:</mat-label>
                <input type="email" name="sigortaliEmailInputElement" matInput
                  formControlName="sigortaliEmailInputElement" placeholder="Email" [ngModel]="selectedSigortaliEmail"
                  (ngModelChange)="onSigortaliEmailChange($event)">
                  <fa-icon [icon]="faEnvelope" matSuffix style="color: #009688;font-size:15px;position: absolute;   top: -17px; right: 10px; "></fa-icon>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <br>

          <mat-card class="cardBorder">
            <mat-card-header class="cardHeader">
              <p  class="cardHeaderTitle">Araç Bilgileri</p>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field appearance="outline" id="marka">
                <mat-label class="formLabel">Marka:</mat-label>
                <mat-select name="aracMarkalarControl" formControlName="aracMarkalarControl"
                  [ngModel]="selectedAracMarkaModel" (ngModelChange)="onMarkaChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let marka of markalarData" [value]="marka">
                    {{marka.adi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 318px;" id="model">
                <mat-label class="formLabel">Model:</mat-label>
                <mat-select name="aracModellerControl" formControlName="aracModellerControl"
                  [ngModel]="selectedAracModel" (ngModelChange)="onModelChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let model of modellerData" [value]="model.aracKodu">
                    {{model.modelAdi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label >Araç Kodu</mat-label>
                <input matInput formControlName="aracKoduControl" placeholder="Araç kodu" 
                [ngModel]="selectedAracKodu"  readonly="readonly">
              </mat-form-field>
              
              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label class="formLabel">Model Yılı:</mat-label>
                <mat-select name="aracModelYiliControl" formControlName="aracModelYiliControl"
                  [ngModel]="selectedAracModelYili" (ngModelChange)="onAracModelYiliChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let modelYili of modelYillariData" [value]="modelYili.yil">
                    {{modelYili.yil}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              
              <br>
              <mat-form-field appearance="outline">
                <mat-label class="formLabel">Kullanım Şekli:</mat-label>
                <mat-select name="aracKullanimSekliControl" formControlName="aracKullanimSekliControl"
                  [ngModel]="selectedAracKullanimSekli" (ngModelChange)="onKullanimSekliChange($event)">
                  <mat-option *ngFor="let kullanimSekli of aracKullanimSekilleriData" [value]="kullanimSekli">
                    {{kullanimSekli.adi.toUpperCase()}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" [style.width.px]=318>
                <mat-label class="formLabel">Kullanım Tarzı:</mat-label>
                <mat-select name="aracTarifeGrupKoduControl" formControlName="aracTarifeGrupKoduControl"
                  [ngModel]="selectedAracTarifeGrup" (ngModelChange)="onKullanimTarziChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let tarz of aracTarifeGruplariTmp" [value]="tarz.kodu">
                    {{tarz.adi.toUpperCase()}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label class="formLabel">Trf Çkş Trh:</mat-label>
                <input name="aracTrafigeCikisTarihiDTPElement" matInput
                  formControlName="aracTrafigeCikisTarihiDTPElement" [ngModel]="selectedAracTrafigeCikisTarihi"
                  (ngModelChange)="onAracTrafigeCikisTarihiChange($event)" [matDatepicker]="trafigeCikisPicker">
                <mat-datepicker-toggle matSuffix [for]="trafigeCikisPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #trafigeCikisPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label class="formLabel">Tscl Trh:</mat-label>
                <input name="aracTescilTarihiDTPElement" matInput formControlName="aracTescilTarihiDTPElement"
                  [ngModel]="selectedAracTescilTarihi" (ngModelChange)="onAracTescilTarihiChange($event)"
                  [matDatepicker]="tescilPicker">
                <mat-datepicker-toggle matSuffix [for]="tescilPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #tescilPicker></mat-datepicker>
              </mat-form-field>
              <br>


              <mat-form-field appearance="outline" id="motorNo">
                <mat-label class="formLabel">Motor No:</mat-label>
                <input oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" name="aracMotorNoInputElement" matInput formControlName="aracMotorNoInputElement"
                  placeholder="Motor No" [ngModel]="selectedAracMotorNo" (ngModelChange)="onAracMotorNoChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline" id="sasiNo">
                <mat-label class="formLabel">Şasi No:</mat-label>
                <input oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" name="aracSasiNoInputElement" matInput formControlName="aracSasiNoInputElement"
                  placeholder="Şasi No" [ngModel]="selectedAracSasiNo" (ngModelChange)="onAracSasiNoChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label class="formLabel">Kasko Değeri:</mat-label>
                <input style="text-align: right" name="aracKaskoDegeriIputElement" matInput
                  formControlName="aracKaskoDegeriIputElement" placeholder="Kasko Değeri"
                  [ngModel]="selectedAracKaskoDegeri " (ngModelChange)="onAracKaskoDegeriChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label class="formLabel">Yolcu Sayısı:</mat-label>
                <input oninput="this.value = this.value.replace(/[^0-9]/g, '')" style="margin-top: -2.5px !important;" type="number" name="aracYolcuSayisiInputElement" matInput
                  formControlName="aracYolcuSayisiInputElement" placeholder="Yolcu Sayısı"
                  [ngModel]="selectedAracYolcuSayisi" (ngModelChange)="onAracYolcuSayisiChange($event)">
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <br>
          <mat-card class="cardBorder">
            <mat-card-header class="cardHeader">
              <p class="cardHeaderTitle" >Önceki Poliçe Bilgileri</p>
            </mat-card-header>
            <mat-card-content>

              <mat-form-field appearance="outline" style="width: 270px;" id="sigortaSirketiEskiPolice">
                <mat-label class="formLabel">Sigorta Şirketi:</mat-label>
                <mat-select name="oncekiSigortaSirketiControl" formControlName="oncekiSigortaSirketiControl"
                  [ngModel]="oncekiPoliceModel.sigortaSirket" (ngModelChange)="onOncekiSigortaSirketiChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let oncekiSirket of sigortaSirketleriData" [value]="oncekiSirket.tramerKodu">
                    {{oncekiSirket.adi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 100px;" id="sigortaSirketiEskiAcentaNo">
                <mat-label class="formLabel">Acente No:</mat-label>
                <input name="eskiPoliceAcenteNoInputElement" matInput formControlName="eskiPoliceAcenteNoInputElement"
                  placeholder="Acente No" [ngModel]="oncekiPoliceModel.acenteNo"
                  (ngModelChange)="onOncekiPoliceSigortaAcenteNoChange($event)">
              </mat-form-field>
              <mat-form-field appearance="outline" id="sigortaSirketiEskiPoliceNo">
                <mat-label class="formLabel">Poliçe No:</mat-label>
                <input name="eskiPoliceNoInputElement" matInput formControlName="eskiPoliceNoInputElement"
                  placeholder="Poliçe No" [ngModel]="oncekiPoliceModel.policeNo"
                  (ngModelChange)="onOncekiPoliceNoChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 50px;" id="sigortaSirketiEskiYenilemeNo">
                <mat-label class="formLabel">Y. No:</mat-label>
                <input oninput="this.value = this.value.replace(/[^0-9]/g, '')" name="eskiPoliceYenilemeNoInputElement" matInput
                  formControlName="eskiPoliceYenilemeNoInputElement" placeholder="Yen No"
                  [ngModel]="oncekiPoliceModel.yenilemeNo"
                  (ngModelChange)="onOncekiPoliceSigortaYenilemeNoChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline" style=" width: 110px;" id="oncekiPoliceBitisTarihiFormField">
                <mat-label class="formLabel">Ön Pol Btş Trh:</mat-label>
                <input name="oncekiPoliceBitisTarihiDTPElement" matInput
                  formControlName="oncekiPoliceBitisTarihiDTPElement" [ngModel]="oncekiPoliceModel.bitisTarihi"
                  (ngModelChange)="onOncekiPoliceBitisTarihiChange($event)"
                  [matDatepicker]="oncekiPoliceBitisTarihiPicker">
                <mat-datepicker-toggle matSuffix [for]="oncekiPoliceBitisTarihiPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #oncekiPoliceBitisTarihiPicker></mat-datepicker>
              </mat-form-field>
            </mat-card-content>
          </mat-card>



          <section class="example-section">
            <button mat-raised-button color="primary" class="buttonHight" matStepperPrevious
              style="display: none;">Önceki Adıma Dön</button>

            <button id="onbilgiGuncelleNextbutton" mat-raised-button color="primary" class="buttonHight"
              (click)=" scrollForStep3()" matStepperNext style="display: none;">Devam Et</button>
          </section>
        </form>
      </mat-step>

      <mat-step [stepControl]="standartTeminatSecimFormGroup" [optional]="isOptional">
        <ng-template matStepLabel>
          <h5><b>Teminat Bilgileri</b></h5>
        </ng-template>
        <form [formGroup]="standartTeminatSecimFormGroup">
          <mat-card class="cardBorder">
            
            <mat-card-content>
              <mat-form-field appearance="outline" class="teminatFormFields">
                <mat-label>Meslek :</mat-label>
                <mat-select formControlName="standartTeminatMSLControl" [ngModel]="selectedStandartTeminatMSL"
                  (ngModelChange)="onStandartTeminatMSLChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let standartMSL of standartTeminatlarData.meslek" [value]="standartMSL">
                    {{standartMSL.tanim}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="teminatFormFields">
                <mat-label>IMM:</mat-label>
                <mat-select formControlName="standartTeminatIMMControl" [ngModel]="selectedStandartTeminatIMM"
                  (ngModelChange)="onStandartTeminatIMMChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let standartIMM of standartTeminatlarData.imm" [value]="standartIMM">
                    {{standartIMM.tanim}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="teminatFormFields">
                <mat-label>İkame araç :</mat-label>
                <mat-select formControlName="standartTeminatIKMControl" [ngModel]="selectedStandartTeminatIKM"
                  (ngModelChange)="onStandartTeminatIKMChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let standartIKM of standartTeminatlarData.ikameArac" [value]="standartIKM">
                    {{standartIKM.tanim}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="teminatFormFields">
                <mat-label>Yetkili servis : </mat-label>
                <!-- <mat-label>Yetkili Servis:</mat-label> -->
                <mat-select formControlName="standartTeminatYSControl" [ngModel]="selectedStandartTeminatYS"
                  (ngModelChange)="onStandartTeminatYSChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let standartYS of standartTeminatlarData.yetkiliServis" [value]="standartYS">
                    {{standartYS.tanim}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" [style.width.px]=400>
                <mat-label>Kiralık araç mı? : </mat-label>
                <mat-select formControlName="standartTeminatKAControl" [ngModel]="selectedStandartTeminatKA"
                  (ngModelChange)="onStandartTeminatKAChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let standartKA of standartTeminatlarData.kiralikArac" [value]="standartKA">
                    {{standartKA.tanim}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" [style.width.px]=400>
                <mat-label>Taksit Sayısı</mat-label>
                <mat-select formControlName="standartOdemeControl" [ngModel]="selectedStandartOdeme"
                  (ngModelChange)="onStandartOdemeChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let standart of standartOdemelerData" [value]="standart">
                    {{standart.tanim}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
             
              <br>
              <button mat-raised-button class="buttonLeft" matStepperNext (click)="standartTeminatlariEslestir()" (click)="standartOdemeleriEslestir()"
                (click)="this.scrollForStep4()">
                Teminatları Güncelle
                <span class="material-icons" style="transform: rotate(90deg)">
                  forward
                </span>
              </button>
              <!--region Seçimleri Kapatılan Standart Teminat Seçenekleri-->
              <!--<div class="container">
                        <mat-label class="col-md-6 h6">Ferdi Kaza: </mat-label>

                        <mat-form-field appearance="fill" class="col-md-6">
                          &lt;!&ndash; <mat-label>Ferdi Kaza:</mat-label> &ndash;&gt;
                          <mat-select formControlName="standartTeminatFKControl"
                          [ngModel]="selectedStandartTeminatFK"
                          (ngModelChange)="onStandartTeminatFKChange($event)">
                            <mat-option></mat-option>
                              <mat-option *ngFor="let standartFK of standartTeminatlarData.ferdiKaza" [value]="standartFK">
                                {{standartFK.tanim}}
                              </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>-->

              <!--<div class="container">
                      <mat-label class="col-md-6 h6">Hukuksal Koruma: </mat-label>

                      <mat-form-field appearance="fill" class="col-md-6">
                        &lt;!&ndash; <mat-label>Hukuksal Koruma:</mat-label> &ndash;&gt;
                        <mat-select formControlName="standartTeminatHKControl"
                        [ngModel]="selectedStandartTeminatHK"
                        (ngModelChange)="onStandartTeminatHKChange($event)">
                          <mat-option></mat-option>
                            <mat-option *ngFor="let standartHK of standartTeminatlarData.hukuksalKoruma" [value]="standartHK">
                              {{standartHK.tanim}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>-->

              <!--<div class="container">
                      <mat-label class="col-md-6 h6">Anahtar Kaybı: </mat-label>

                      <mat-form-field appearance="fill" class="col-md-6">
                        &lt;!&ndash; <mat-label>Anahtar Kaybı:</mat-label> &ndash;&gt;
                        <mat-select formControlName="standartTeminatAKControl"
                        [ngModel]="selectedStandartTeminatAK"
                        (ngModelChange)="onStandartTeminatAKChange($event)">
                          <mat-option></mat-option>
                            <mat-option *ngFor="let standartAK of standartTeminatlarData.anahtarKaybi" [value]="standartAK">
                              {{standartAK.tanim}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>-->

              <!--<div class="container">
                      <mat-label class="col-md-6 h6">Deprem Sel: </mat-label>

                      <mat-form-field appearance="fill" class="col-md-6">
                        &lt;!&ndash; <mat-label>Deprem Sel:</mat-label> &ndash;&gt;
                        <mat-select formControlName="standartTeminatDSControl"
                        [ngModel]="selectedStandartTeminatDS"
                        (ngModelChange)="onStandartTeminatDSChange($event)">
                          <mat-option></mat-option>
                            <mat-option *ngFor="let standartDS of standartTeminatlarData.depremSel" [value]="standartDS">
                              {{standartDS.tanim}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>-->

              <!--<div class="container">
                      <mat-label class="col-md-6 h6">Manevi Tazminat: </mat-label>

                      <mat-form-field appearance="fill" class="col-md-6">
                        &lt;!&ndash; <mat-label>Manevi Tazminat:</mat-label> &ndash;&gt;
                        <mat-select formControlName="standartTeminatMTControl"
                        [ngModel]="selectedStandartTeminatMT"
                        (ngModelChange)="onStandartTeminatMTChange($event)">
                          <mat-option></mat-option>
                            <mat-option *ngFor="let standartMT of standartTeminatlarData.maneviTazminat" [value]="standartMT">
                              {{standartMT.tanim}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>-->
              <!--endregion-->
              <!--<div class="container">

                        <form  #form="ngForm" ngNativeValidate>
                          <mat-label class="col-md-6 h6">Kiralık Araç Mı? : </mat-label>
                          <mat-slide-toggle formControlName="standartTeminatKAControl" color="primary"
                                            [(ngModel)]="isChecked"
                          > {{isChecked === true ? 'Evet' : 'Hayır'}}
                          </mat-slide-toggle>
                        </form>

                      </div>-->


            </mat-card-content>
          </mat-card>
        </form>
        <section class="example-section">
          <button mat-raised-button class="buttonHight" color="primary" matStepperPrevious style="display: none;">Önceki
            Adıma Dön</button>
        </section>
      </mat-step>

      <mat-step [stepControl]="teklifAlmaFormGroup" [optional]="isOptional">
        <ng-template matStepLabel>
          <h5><b>Teklifler</b></h5>
        </ng-template>
        <form [formGroup]="teklifAlmaFormGroup">
          <mat-card class="cardBorder">
            <mat-card-content>

              <div class="container col-md-12">
                <button class="buttonHight" mat-raised-button (click)="kaskoTeklifAl()"
                  [disabled]="disableButton===true">Teklif Al</button>

                <table style="margin-top: 8px;" mat-table [dataSource]="dataSource" multiTemplateDataRows
                  class="mat-elevation-z8" id="ExampleMaterialTable" matSort (matSortChange)="sortData($event)">
                  <!--['-', 'status', 'sigortaSirketKisaAdi', 'urunAdi', 'teklifNo', 'brutPrim', 'print', 'İşlem'];-->

                  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; index as i">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-label *ngIf="i === 0">{{column === 'checkBox' ? '' : ''}}</mat-label>
                      <div style=" width:15px">
                        <mat-label *ngIf="i === 1">{{column === 'status' ? '' : ''}}</mat-label>
                      </div>
                      <mat-label *ngIf="i === 2">{{column === 'bilgi' ? '' : ''}}</mat-label>
                      <mat-label mat-sort-header="sigortaSirketKisaAdi" *ngIf="i === 3">{{column ===
                        'sigortaSirketKisaAdi' ? 'Sigorta Şirketi' : 'Sigorta Şirketi'}}</mat-label>
                      <mat-label mat-sort-header="urunAdi" *ngIf="i === 4">{{column === 'urunAdi' ? 'Ürün' : 'Ürün'}}
                      </mat-label>
                      <mat-label mat-sort-header="teklifNo" *ngIf="i === 5">{{column === 'teklifNo' ? 'Teklif No' :
                        'Teklif No'}}</mat-label>
                      <mat-label mat-sort-header="brutPrim" style=" float: right; width: 80px;" *ngIf="i ===6">{{column
                        === 'brutPrim' ? 'Prim(Peşin)' : 'Prim(Peşin)'}}</mat-label>
                      <mat-label *ngIf="i === 7">{{column === 'print' ? 'Yazdır' : 'Yazdır'}}</mat-label>
                      <mat-label *ngIf="i === 8">{{column === 'islem' ? 'İşlem' : 'İşlem'}}</mat-label>
                      <mat-label *ngIf="i === 9">{{column === 'downİcon' ? ' ' : ' '}}</mat-label>
                      <mat-checkbox id="checkboxTooltip" matTooltip="Hepsini Seç" matTooltipPosition='left'
                        *ngIf="i === 0" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let urun">
                      <mat-checkbox *ngIf="i === 0" (click)="$event.stopPropagation()"
                        (change)="$event ? onChangeUrunCheckBox(urun) : null" [checked]="selection.isSelected(urun.id)">
                      </mat-checkbox>
                      <mat-spinner *ngIf="i === 1" id="urunSpinner-{{urun.id}}" style="display: none;" [diameter]="25">
                      </mat-spinner>
                      <span *ngIf="i === 1&&teklifIconVisibility" id="urunDone-{{urun.id}}" class="material-icons urunDoneIcon"
                        style="display: none;">done</span>
                      <span data-placement='left' data-toggle="tooltip" data-original-title="" *ngIf="i === 1&&teklifIconVisibility"
                        id="urunError-{{urun.id}}" class="material-icons urunErrorIcon"
                        style="display: none;">close</span>
                      <span data-placement='left' data-toggle="tooltip" data-original-title="" *ngIf="i === 2&&teklifIconVisibility"
                        id="urunWarning-{{urun.id}}" class="material-icons urunBilgiIcon"
                        style="display: none;">error</span>

                      <!--<mat-spinner *ngIf="i === 1" id="spinner-{{urun.id}}" style="width: 30px; height: 30px; display: none;" [diameter]="30"></mat-spinner>-->
                      <div style="display: inline !important;" align="right" *ngIf="i===6" style="font-size: 13px;">
                        {{urun[column]}} </div>

                      <div *ngIf="i!=6" style="font-size: 13px;">{{urun[column]}}
                      </div>
                      <fa-icon id="downIcon-{{urun.id}}" *ngIf="i===9" [icon]="faChevronDown" data-placement='top'
                        data-toggle="tooltip" data-original-title="Teminat detayları için tıklayınız."></fa-icon>


                    </td>

                  </ng-container>
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let urun" [attr.colspan]="columnsToDisplay.length">
                      <div class="example-element-detail"
                        [@detailExpand]="urun == expandedElement ? 'expanded' : 'collapsed'">

                        <div class="example-element-description offerDetailDiv" id="matTableDetailVisibilty-{{urun.id}}" 
                          style="display: none;">
                          <h5 style="margin-left: 5%;" *ngIf="urun.odemeler.sirketOdemeCoreModel.length > 0"><b>Ödemeler</b></h5>
                          <mat-card *ngIf="urun.odemeler.sirketOdemeCoreModel.length > 0" style="background-color: #f8f7f7ec; width: 100%; margin-bottom: 15px;">
                            <mat-card-content>
                              <form>
                                  <label class="col-4" *ngIf="urun.odemeler.sirketOdemeCoreModel.length > 0">
                                    <mat-form-field appearance="outline" class="teminatlarForProduct">
                                      <mat-label class="sigorataSirketiTeminatlari">TAKSİT: </mat-label>
                                      <mat-select name="{{  (urun.id * 1449) + urun.sigortaSirketiId + 'secilenOdeme' }}"
                                        [(ngModel)]="urun.secilenOdemeler.secilenOdeme"
                                        (ngModelChange)="sirketOdemeOnChange($event)">
                                        <mat-option>Seçiniz</mat-option>
                                        <mat-option *ngFor="let urunOdeme of urun.odemeler.sirketOdemeCoreModel" [value]="urunOdeme">
                                          {{urunOdeme.tanim}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </label>                                
                              </form>
                            </mat-card-content>
                          </mat-card> 
                          
                          <h5 style="margin-left: 5%;"><b>Teminatlar</b></h5>
                          <mat-card class="col-lg-12" style="background-color: #f8f7f7ec; width: 100%;">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <form>
                                <!--<button color="primary" mat-button matStepperNext (click)="standartTeminatEslestirmeleriniUrunlereDoldur()">Devam Et</button>-->

                                <label class="col-4" *ngIf="urun.teminatlar.imm.length > 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">İMM: </mat-label>
                                    <mat-select name="{{  (urun.id * 1450) + urun.kaskoTeminatTurId + 'secilenIMM' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenIMM"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunImm of urun.teminatlar.imm" [value]="urunImm">
                                        {{urunImm.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.ferdiKaza.length > 0">

                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Ferdi Kaza: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1451) + urun.kaskoTeminatTurId + 'secilenFerdiKaza' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenFerdiKaza"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunFK of urun.teminatlar.ferdiKaza" [value]="urunFK">
                                        {{urunFK.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.meslek.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Meslek: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1452) + urun.kaskoTeminatTurId + 'secilenMeslek' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenMeslek"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunMSL of urun.teminatlar.meslek" [value]="urunMSL">
                                        {{urunMSL.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.anahtarKaybi.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Anahtar Kaybı: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1453) + urun.kaskoTeminatTurId + 'secilenAnahtarKaybi' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenAnahtarKaybi"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunAK of urun.teminatlar.anahtarKaybi" [value]="urunAK">
                                        {{urunAK.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.depremSel.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Deprem Sel: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1454) + urun.kaskoTeminatTurId + 'secilenDepremSel' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenDepremSel"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunDS of urun.teminatlar.depremSel" [value]="urunDS">
                                        {{urunDS.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.hukuksalKoruma.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Hukuksal Koruma: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1455) + urun.kaskoTeminatTurId + 'secilenHukuksalKoruma' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenHukuksalKoruma"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunHK of urun.teminatlar.hukuksalKoruma"
                                        [value]="urunHK">
                                        {{urunHK.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-4" *ngIf="urun.teminatlar.ikameArac.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">İkame Araç: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1456) + urun.kaskoTeminatTurId + 'secilenIkameArac' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenIkameArac"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunIKM of urun.teminatlar.ikameArac" [value]="urunIKM">
                                        {{urunIKM.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.maneviTazminat.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Manevi Tazminat: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1457) + urun.kaskoTeminatTurId + 'secilenManeviTazminat' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenManeviTazminat"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunMT of urun.teminatlar.maneviTazminat"
                                        [value]="urunMT">
                                        {{urunMT.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.yetkiliServis.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Yetkili Servis: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1458) + urun.kaskoTeminatTurId + 'secilenYetkiliServis' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenYetkiliServis"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunYS of urun.teminatlar.yetkiliServis" [value]="urunYS">
                                        {{urunYS.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.kiralikArac.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Kiralık Araç: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1459) + urun.kaskoTeminatTurId + 'secilenKiralikArac' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenKiralikArac"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunKA of urun.teminatlar.kiralikArac" [value]="urunKA">
                                        {{urunKA.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.camMuafiyeti.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Cam Muafiyeti: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1460) + urun.kaskoTeminatTurId + 'secilenCamMuafiyeti' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenCamMuafiyeti"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunCam of urun.teminatlar.camMuafiyeti"
                                        [value]="urunCam">
                                        {{urunCam.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>

                                <label class="col-md-4" *ngIf="urun.teminatlar.yanlisAkaryakitDolumu.length != 0">
                                  <mat-form-field appearance="outline" class="teminatlarForProduct">
                                    <mat-label class="sigorataSirketiTeminatlari">Yanlış Akaryakıt Dolumu: </mat-label>
                                    <mat-select
                                      name="{{  (urun.id * 1421) + urun.kaskoTeminatTurId + 'secilenYanlisAkaryakitDolumu' }}"
                                      [(ngModel)]="urun.secilenTeminatlar.secilenYanlisAkaryakitDolumu"
                                      (ngModelChange)="sirketTeminatlarOnChange($event)">
                                      <mat-option>Seçiniz</mat-option>
                                      <mat-option *ngFor="let urunYad of urun.teminatlar.yanlisAkaryakitDolumu"
                                        [value]="urunYad">
                                        {{urunYad.tanim}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </label>
                              </form>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr mat-row *matRowDef="let urun; columns: columnsToDisplay;" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === urun"
                    (click)="expandedElement = expandedElement === urun ? null : urun"
                    (click)="matTableDetailVisibiltyChange(urun)">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
              </div>
            </mat-card-content>
            <button style="margin-left: 15px; margin-top: 6px;" class="buttonHight" mat-raised-button
              (click)="kaskoTeklifAl()" [disabled]="disableButton===true">Teklif Al</button>
            <button style="margin-left: 15px; margin-top: 6px;" class="buttonHight" mat-raised-button
              (click)="sendToPdf()">
              <span class="material-icons iconhover">
                picture_as_pdf
              </span>
              Pdf Kaydet</button>
            <!-- <button class="buttonHight" mat-raised-button color="primary" (click)="exportTable()">
                    <span class="material-icons iconhover" style="margin-top: -5px;">
                       cloud_download</span>Excele Aktar</button> -->
          </mat-card>
        </form>
        <br>
        <section class="example-section">
          <button class="buttonHight" color="primary" mat-raised-button matStepperPrevious style="display: none;">Önceki
            Adıma Dön</button>

        </section>

        <!--<section class="example-section">
                <div *ngIf="!onTeklifSpinner" id="teklifLoader">
                  <mat-spinner></mat-spinner>
                </div>
                &lt;!&ndash; <div id="spinner" style="display: none;">
                    <mat-spinner></mat-spinner>
                </div> &ndash;&gt;
              </section>-->

      </mat-step>
    </mat-vertical-stepper>

  </mat-card>
</div>