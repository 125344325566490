import { SifremiUnuttumResponseModel } from './../../models/Auth/PassordForget/sifremi-unuttum-response-model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SifremiUnuttumRequestModel } from 'src/app/models/Auth/PassordForget/sifremi-unuttum-request-model';
import {    faExclamationCircle,  faUnlockAlt, faUser, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sifremi-unuttum',
  templateUrl: './sifremi-unuttum.component.html',
  styleUrls: ['./sifremi-unuttum.component.css']
})
export class SifremiUnuttumComponent implements OnInit {
  headerTitle = ""

  faUser=faUser
  exclamationCircle=faExclamationCircle
  faUnlock=faUserLock
  sifremiUnuttumFormGroup:FormGroup
  validationMessage:string=""
  isValidFormSubmitted = null;
  matSpinner:boolean=false
  sifremiUnttumRM:SifremiUnuttumRequestModel
  sifremiUnttumResponseModel:SifremiUnuttumResponseModel
  constructor(private _snackBar:MatSnackBar,private _authService: AuthService, private _formBuilder: FormBuilder,private _router: Router,private title:Title) { 
    this.sifremiUnttumRM=new SifremiUnuttumRequestModel();
    this.sifremiUnttumResponseModel=new SifremiUnuttumResponseModel();

  }

  ngOnInit(): void {
    
    this.sifremiUnuttumFormGroup = new FormGroup({
      emailInputElement: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern("^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$")
        ])),
      recaptchaReactive: new FormControl('',Validators.required)
    });
  }

  sifreGuncelle(){
    if (this.sifremiUnuttumFormGroup.invalid) {
      this.validationCall()
      return
    }
    this.validationMessage=""
    this.matSpinner=true
    console.log(this.sifremiUnttumRM) 
    
    
    this._authService.sifreYenile(this.sifremiUnttumRM).subscribe(
      (data) => {
        console.log(data)
        this.sifremiUnttumResponseModel = data;

        if (data.result) {
          this._snackBar.open(
            'Şifre güncellemeniz için mail adresinize link gönderildi.' , 'Kapat'
          );
          console.log(this.sifremiUnttumResponseModel)
          this.matSpinner=false
        }else{
          this.matSpinner=false
    
          console.log(this.sifremiUnttumResponseModel)
          this._snackBar.open('' + this.sifremiUnttumResponseModel.message, 'Kapat', {
            duration: 5000,
          });
        }
      },
      (error) => {
        this.matSpinner=false  
        this.sifremiUnttumResponseModel = error;
        console.log(this.sifremiUnttumResponseModel)
        this._snackBar.open(error.title + ' ' + error.errors, 'Kapat', {
          duration: 5000,
        });
      }
    )
  }

  resolved(captchaResponse: string) {
    this.sifremiUnttumRM.googleCaptchaToken = captchaResponse;
  }

  validationCall(){    
    if (this.sifremiUnuttumFormGroup.get('recaptchaReactive').hasError('required')) {
      this.validationMessage="Lütfen recaptcha bilgisi giriniz"
    }
    if (this.sifremiUnuttumFormGroup.get('emailInputElement').hasError('pattern')) {
      this.validationMessage="Lütfen formatlı mail bilgisi giriniz"
    }
    if (this.sifremiUnuttumFormGroup.get('emailInputElement').hasError('required')) {
      this.validationMessage="Lütfen mail bilgisi giriniz"
    }
  }
}
