import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kaskoDegeri'
})
export class KaskoDegeriPipe implements PipeTransform {
  transform(value: string): string {
    switch (value.length) {
      case 4:
        value = value.indexOf("." || ",") == -1 ?  value.substring(0, 1) + "." + value.substring(1, 4)
          : value.charAt(1)=="."?value.substring(0,1)+value.substring(2,4)
          :value
        break;
      case 5:
        value = value.indexOf(".") == -1 ?  value.substring(0, 2) + "." + value.substring(2, 5)
          :value.charAt(2) == "."?value.substring(0, 1) + "."+value.substring(1, 2) + value.substring(3, 5)
          :value
        break;
      case 6:
        value = value.indexOf(".") == -1 ? value.substring(0, 3) + "." + value.substring(3, 6)
          : value.indexOf(".") == 1 ? value.substring(0, 1) + value.substring(2, 3) + "." + value.substring(3, 6)
          :  value.charAt(3)=="."?value.substring(0, 2)+"."+value.substring(2, 3)+value.substring(4, 6) 
          : value
        break;
      case 7:
        value = value.indexOf("." || ",") == -1 ? value.substring(0, 1) + "." + value.substring(1, 4) + "." + value.substring(4, 7)
          : value.indexOf(".") == 2 ? value.substring(0, 2) + value.substring(3, 4) + "." + value.substring(4, 7)
            : value
        break;
      case 8:
        value = value.indexOf("." || ",") == -1 ? value.substring(0, 2) + "." + value.substring(2, 5) + "." + value.substring(5, 8)
          : value.indexOf(".") == 3 ? value.substring(0, 1) + "." + value.substring(1, 3) + value.substring(4, 5) + "." + value.substring(5, 8)
          : value.charAt(1)=="."&&value.charAt(5)=="."?value.substring(0, 1)+value.substring(2, 4)+"."+value.substring(4, 5)+value.substring(6, 8)
          : value
        break;
      case 9:
        value = value.indexOf("." || ",") == -1 ? value.substring(0, 3) + "." + value.substring(3, 6) + "." + value.substring(6, 9)
          :  value.charAt(2)=="."&&value.charAt(6)=="."?value.substring(0, 1)+"."+value.substring(1, 2)+value.substring(3, 5)+"."
            +value.substring(5, 6)+value.substring(7, 9)
          : value
        break;
        case 10:
          value= value.charAt(1) == "."&&value.charAt(5)=="." ? value.substring(0, 1) +value.substring(2, 3)+ "." 
            + value.substring(3, 5) + value.substring(6,7) + "." + value.substring(7, 10)
            :value
        break;
    }
    return value;
  }
}
