<!-- <div align="center"> <div  style="margin-top: 45px; width: 10%; font-weight:599; font-size: 15px;border-color: #673ab7; border: 7px solid #673ab7; background-color: #673ab7; color: #fff" >Trafik Sigortası</div></div> -->
<section style="background-color: rgba(68, 68, 68, 0.5); height: 100%; position: fixed; width: 100%; z-index: 998;" *ngIf="(loadingService.isLoading|async)||onSpinner==false">
  <mat-spinner [diameter]="50" style="  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;"></mat-spinner>
</section>
<button mat-icon-button class="upTopIcon" *ngIf="scroll" (window:scroll)="onScroll($event)">
  <fa-icon [icon]="faCaretSquareUp" (click)="scrollForStep1()"></fa-icon>  
</button>
<div class="container col-lg-10">
  <mat-card>
    <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="validationMessage">
      <fa-icon   [icon]="exclamationCircle" ></fa-icon>
      <div style="margin-left: 10px;">
        <b>{{validationMessage}}</b> 
      </div>
    </div>
    <mat-vertical-stepper [linear]="isLinear" #stepper style="background-color: #f8f7f7ec;" linear>
      <mat-step [stepControl]="onBilgiSorgulamaFormGroup" [optional]="isOptional">
        <mat-card class="cardBorder">
          <!--<mat-card-header style="background-color: #f8f8f8;">
            <h3><b>Sorgulama Alanı</b></h3>
          </mat-card-header>-->
          <mat-card-content>
            <form [formGroup]="onBilgiSorgulamaFormGroup" #onBilgiSorgulamaNgFom="ngForm">
              <ng-template matStepLabel>
                <h5 ><b>Kimlik No, Plaka ve Ruhsat Bilgileri</b></h5>
              </ng-template>
              <mat-form-field appearance="outline" >
                <mat-label  class="formLabel">Kimlik No: </mat-label>
                <input maxlength="11" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  id="kimlikNoInput" matInput style="border-color: aquamarine;" formControlName="kimlikNoInputElement" placeholder="Kimlik No">                                                       
              <!-- <div *ngIf="isValidFormSubmitted != null"> <div class="validasyonMesajı"   *ngIf="onBilgiSorgulamaFormGroup.get('kimlikNoInputElement').hasError('required')||onBilgiSorgulamaFormGroup.get('kimlikNoInputElement').hasError('pattern') ">Kimlik No bilgisi 10 ya da 11 numara yazılmalıdır</div></div>  -->
              </mat-form-field>
              <mat-form-field appearance="outline" >
                <mat-label  class="formLabel">Plaka: </mat-label>
                <input maxlength="10" oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')" class="upperCase" id="plakaInput" matInput formControlName="plakaInputElement" placeholder="Plaka">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label  class="formLabel">Ruhsat Belge No: </mat-label>
                <input maxlength="8" oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')" class="upperCase" id="ruhsatInput" matInput formControlName="ruhsatInputCtrl" placeholder="Ruhsat Belge No">
              </mat-form-field>
              <br class="brForSorgulaButton">
              <button style="margin-left: 15px;"  type="submit" class="buttonHight" (click)="trafikOnBilgiSorgula()" mat-raised-button
                ><span class="material-icons iconhover">search</span>Sorgula</button>
              <button style="margin-left: 15px;" class="buttonHight" mat-raised-button  (click)="herseyiTemizle()"><span
                  class="material-icons iconhover" >cleaning_services</span>Temizle</button>

              <section *ngIf="!onSpinner" class="example-section" style="margin-top: 10px;">
                <div *ngIf="!onSpinner" id="loader ">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <!-- <div id="spinner" style="display: none;">
                      <mat-spinner></mat-spinner>
                  </div> -->
              </section>

              <button (click)="scrollForStep2()" id="btnDevamEt" mat-button matStepperNext style="display: none;">Devam
                Et</button>
              <button id="btnFormCleaner" mat-button (click)="stepper.reset()" style="display: none;">Temizle</button>

            </form>
          </mat-card-content>
        </mat-card>
      </mat-step>

      <mat-step [stepControl]="sigortaliVeAracBilgileri" [optional]="isOptional" >
        <form [formGroup]="sigortaliVeAracBilgileri" #sigortaliVeAracBilgileriNgFom="ngForm">
          <ng-template matStepLabel>
            <h5><b>Sigortalı ve Araç Bilgileri</b></h5>
          </ng-template>
          <br>
          <mat-card class="cardBorder">
            <mat-card-header class="cardHeader">
              <p class="cardHeaderTitle">Sigortalı Bilgileri</p>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field appearance="outline" id="sigortaliAdUnvanFormField">
                <mat-label>Sigortalı Ad / Ünvan:</mat-label>
                <input oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '')" matInput formControlName="sigortaliAdUnvanInputElement" [ngModel]="sigortaliAdUnvanModel"
                  (ngModelChange)="onSigortaliAdUnvanChange($event)" placeholder="Ad/Ünvan">
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label  class="formLabel">Dğm Trh:</mat-label>
                <input name="dogumTarihiDTPElement" matInput formControlName="dogumTarihiDTPElement"
                [ngModel]="selectedDogumTarihi"
                (ngModelChange)="onDogumTarihiChange($event)"
                [matDatepicker]="dogumTarihiPicker">
                <mat-datepicker-toggle matSuffix [for]="dogumTarihiPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #dogumTarihiPicker></mat-datepicker>
              </mat-form-field>
              <br>

              <mat-form-field appearance="outline">
                <mat-label>İl:</mat-label>
                <mat-select formControlName="sehirlerControl" [ngModel]="selectedIlModel"
                  (ngModelChange)="onSehirChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let sehir of ilModel" [value]="sehir">
                    {{sehir.ilAdi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>İlçe:</mat-label>
                <mat-select formControlName="ilcelerControl" [ngModel]="selectedIlceModel"
                  (ngModelChange)="onIlceChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let ilce of ilceModel" [value]="ilce.ilceAdi">
                    {{ilce.ilceAdi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field appearance="outline">
                <mat-label>Mahalle:</mat-label>
                <input matInput formControlName="sigortaliMahalleInputElement" placeholder="Mahalle"
                  [ngModel]="selectedSigortaliMahalle" (ngModelChange)="onSigortaliMahalleChange($event)">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Cadde:</mat-label>
                <input matInput formControlName="sigortaliCaddeInputElement" placeholder="Cadde"
                  [ngModel]="selectedSigortaliCadde" (ngModelChange)="onSigortaliCaddeChange($event)">
              </mat-form-field>
              <br>
              
              <mat-form-field appearance="outline">
                <mat-label>Sokak:</mat-label>
                <input matInput formControlName="sigortaliSokakInputElement" placeholder="Sokak"
                  [ngModel]="selectedSigortaliSokak" (ngModelChange)="onSigortaliSokakChange($event)">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>GSM(Telefon) No:</mat-label>
                <input oninput="this.value = this.value=='0'?'':this.value" matInput formControlName="sigortaliGSMInputElement"
                maxlength="17" placeholder="0 (xxx) xxx xx xx"
                  [ngModel]="selectedGSM" (ngModelChange)="onSigortaliGSMChange($event)">
                  <fa-icon [icon]="faPhone" matSuffix style="color: #009688;font-size:15px;position: absolute;   top: -17px; right: 10px; "></fa-icon>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Email:</mat-label>
                <input matInput formControlName="sigortaliEmailInputElement" placeholder="Email"
                  [ngModel]="selectedSigortaliEmail" (ngModelChange)="onSigortaliEmailChange($event)">
                  <fa-icon [icon]="faEnvelope" matSuffix style="color: #009688;font-size:15px;position: absolute;   top: -17px; right: 10px; "></fa-icon>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <br>
          <!--f8f8f8-->
          <mat-card class="cardBorder">
            <mat-card-header class="cardHeader">
              <p class="cardHeaderTitle">Araç Bilgileri</p>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field appearance="outline" id="marka"  >
                <mat-label>Marka:</mat-label>
                <mat-select formControlName="aracMarkalarControl" [ngModel]="selectedAracMarkaModel"
                  (ngModelChange)="onMarkaChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let marka of markalarData" [value]="marka">
                    {{marka.adi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 318px;" id="model">
                <mat-label>Model:</mat-label>
                <mat-select formControlName="aracModellerControl" [ngModel]="selectedAracModel"
                  (ngModelChange)="onModelChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let model of modellerDataTmp" [value]="model.aracKodu">
                    {{model.modelAdi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label >Araç Kodu</mat-label>
                <input matInput formControlName="aracKoduControl" placeholder="Araç kodu" 
                [ngModel]="selectedAracKodu"  readonly="readonly">
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label >Model Yılı:</mat-label>
                <mat-select formControlName="aracModelYiliControl" [ngModel]="selectedAracModelYili"
                  (ngModelChange)="onAracModelYiliChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let modelYili of modelYillariData" [value]="modelYili.yil">
                    {{modelYili.yil}}
                  </mat-option>
                </mat-select>
              </mat-form-field>      
              <br>
              <mat-form-field appearance="outline">
                <mat-label>Kullanım Şekli:</mat-label>
                <mat-select formControlName="aracKullanimSekliControl" [ngModel]="selectedAracKullanimSekli"
                  (ngModelChange)="onKullanimSekliChange($event)">
                  <mat-option *ngFor="let kullanimSekli of aracKullanimSekilleriData" [value]="kullanimSekli">
                    {{kullanimSekli.adi.toUpperCase()}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" [style.width.px]=318>
                <mat-label>Kullanım Tarzı:</mat-label>
                <mat-select formControlName="aracTarifeGrupKoduControl" [ngModel]="selectedAracTarifeGrup"
                  (ngModelChange)="onKullanimTarziChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let tarz of aracTarifeGruplari" [value]="tarz.kodu">
                    {{tarz.adi.toUpperCase()}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style=" width: 110px;">
                <mat-label>Trf Çkş Trh:</mat-label>
                <input matInput formControlName="aracTrafigeCikisTarihiDTPElement"
                  [ngModel]="selectedAracTrafigeCikisTarihi" (ngModelChange)="onAracTrafigeCikisTarihiChange($event)"
                  [matDatepicker]="trafigeCikisPicker">
                <mat-datepicker-toggle matSuffix [for]="trafigeCikisPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #trafigeCikisPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" style=" width: 110px;">
                <mat-label>Tscl Trh:</mat-label>
                <input matInput formControlName="aracTescilTarihiDTPElement" [ngModel]="selectedAracTescilTarihi"
                  (ngModelChange)="onAracTescilTarihiChange($event)" [matDatepicker]="tescilPicker">
                <mat-datepicker-toggle matSuffix [for]="tescilPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #tescilPicker></mat-datepicker>
              </mat-form-field>
              <br>
              
              <mat-form-field appearance="outline" id="motorNo">
                <mat-label  class="formLabel">Motor No:</mat-label>
                <input oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" name="aracMotorNoInputElement" matInput formControlName="aracMotorNoInputElement" placeholder="Motor No"
                [ngModel]="selectedAracMotorNo"
                (ngModelChange)="onAracMotorNoChange($event)"
                >
              </mat-form-field>
              
              <mat-form-field appearance="outline" id="sasiNo">
                <mat-label  class="formLabel">Şasi No:</mat-label>
                <input oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" name="aracSasiNoInputElement" matInput formControlName="aracSasiNoInputElement" placeholder="Şasi No"
                [ngModel]="selectedAracSasiNo"
                (ngModelChange)="onAracSasiNoChange($event)"
               >
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;">
                <mat-label>Yolcu Sayısı:</mat-label>
                <input oninput="this.value = this.value.replace(/[^0-9]/g, '')" style="margin-top: -2.5px !important;" type="number" matInput formControlName="aracYolcuSayisiInputElement" placeholder="Yolcu Sayısı"
                  [ngModel]="selectedAracYolcuSayisi" (ngModelChange)="onAracYolcuSayisiChange($event)">
              </mat-form-field>
              <!-- <mat-form-field>
                <mat-label>Model Yılı:</mat-label>
                <input matInput formControlName="aracModelYiliInputElement" placeholder="Model Yılı"
                [ngModel]="selectedAracModelYili"
                (ngModelChange)="onAracModelYiliChange($event)" required>
              </mat-form-field> -->
              <br>
            </mat-card-content>
          </mat-card>

          <br>
          <mat-card  class="cardBorder">
            <mat-card-header class="cardHeader">
              <p class="cardHeaderTitle">Önceki Poliçe Bilgileri</p>
            </mat-card-header>
            <mat-card-content>

              <mat-form-field appearance="outline" style="width: 270px;" id="sigortaSirketiEskiPolice">
                <mat-label>Sigorta Şirketi:</mat-label>
                <mat-select formControlName="oncekiSigortaSirketiControl" [ngModel]="oncekiPoliceModel.sigortaSirket"
                  (ngModelChange)="onOncekiSigortaSirketiChange($event)">
                  <mat-option>Seçiniz</mat-option>
                  <mat-option *ngFor="let oncekiSirket of sigortaSirketleriData" [value]="oncekiSirket.tramerKodu">
                    {{oncekiSirket.adi}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 100px;" id="sigortaSirketiEskiAcentaNo">
                <mat-label>Acente No:</mat-label>
                <input matInput formControlName="eskiPoliceAcenteNoInputElement" placeholder="Acente No"
                  [ngModel]="oncekiPoliceModel.acenteNo" (ngModelChange)="onOncekiPoliceSigortaAcenteNoChange($event)"
                  >
              </mat-form-field>

              <mat-form-field appearance="outline" id="sigortaSirketiEskiPoliceNo">
                <mat-label>Poliçe No:</mat-label>
                <input matInput formControlName="eskiPoliceNoInputElement" placeholder="Poliçe No"
                  [ngModel]="oncekiPoliceModel.policeNo" (ngModelChange)="onOncekiPoliceNoChange($event)"
                  >
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 50px;" id="sigortaSirketiEskiYenilemeNo"> 
                <mat-label>Y. No:</mat-label>
                <input oninput="this.value = this.value.replace(/[^0-9]/g, '')" matInput formControlName="eskiPoliceYenilemeNoInputElement" placeholder="Yen No"
                  [ngModel]="oncekiPoliceModel.yenilemeNo"
                  (ngModelChange)="onOncekiPoliceSigortaYenilemeNoChange($event)" >
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 110px;" id="oncekiPoliceBitisTarihiFormField">
                <mat-label>Ön Pol Btş Trh:</mat-label>
                <input matInput formControlName="oncekiPoliceBitisTarihiDTPElement"
                  [ngModel]="oncekiPoliceModel.bitisTarihi" (ngModelChange)="onOncekiPoliceBitisTarihiChange($event)"
                  [matDatepicker]="oncekiPoliceBitisTarihiPicker" readonly="readonly">
                <mat-datepicker-toggle matSuffix [for]="oncekiPoliceBitisTarihiPicker" class="color"></mat-datepicker-toggle>
                <mat-datepicker #oncekiPoliceBitisTarihiPicker></mat-datepicker>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <section class="example-section">
            <button class="buttonHight" mat-raised-button color="primary" matStepperPrevious
              style="display: none;">Önceki Adıma Dön</button>
            <!-- <button color="primary" mat-button matStepperNext (click)="onOnBilgiSaveChange()">Devam Et</button> -->

            <button (click)="scrollForStep3() " id="onbilgiGuncelleNextbutton" class="buttonHight" mat-raised-button
              color="primary" matStepperNext style="display: none;">Devam Et</button>
          </section>
        </form>
      </mat-step>

      <mat-step [stepControl]="teklifAlmaFormGroup" [optional]="isOptional">
        <ng-template matStepLabel>
          <h5 ><b>Teklifler</b></h5>
        </ng-template>
        <form [formGroup]="teklifAlmaFormGroup">
          <mat-card class="cardBorder">
            <mat-card-content  id="test">

              <div class="container col-md-12"  #content>
                <button class="buttonHight" mat-raised-button  (click)="trafikTeklifAl()" [disabled]="disableButton===true">Teklif Al</button>
                <!--<button color="primary" mat-button (click)="call()">Konsola Bas</button>-->

                <table style="margin-top: 8px;" mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8"
                  id="ExampleMaterialTable" matSort (matSortChange)="sortData($event)">
                  <!--['-', 'status', 'sigortaSirketKisaAdi', 'urunAdi', 'teklifNo', 'brutPrim', 'print', 'İşlem'];-->

                  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; index as i">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-label *ngIf="i === 0">{{column === 'checkBox' ? '' : ''}}</mat-label>
                      <div style=" width:15px"><mat-label  *ngIf="i === 1">{{column === 'status' ? '' : ''}}</mat-label></div>
                    <mat-label  *ngIf="i === 2">{{column === 'bilgi' ? '' : ''}}</mat-label> 
                      <mat-label mat-sort-header="sigortaSirketKisaAdi" *ngIf="i === 3">{{column === 'sigortaSirketKisaAdi' ? 'Sigorta Şirketi' : 'Sigorta
                        Şirketi'}}</mat-label>
                      <mat-label mat-sort-header="urunAdi" *ngIf="i === 4">{{column === 'urunAdi' ? 'Ürün Adı' : 'Ürün Adı'}}</mat-label>
                      <mat-label mat-sort-header="teklifNo" *ngIf="i === 5">{{column === 'teklifNo' ? 'Teklif No' : 'Teklif No'}}</mat-label>
                      <mat-label mat-sort-header="brutPrim" style=" float: right; width: 60px;" *ngIf="i === 6">{{column === 'brutPrim' ? 'Prim' :
                        'Prim'}}</mat-label>
                      <mat-label  *ngIf="i === 7">{{column === 'print' ? 'Yazdır' : 'Yazdır'}}</mat-label>
                      <mat-label  *ngIf="i === 8">{{column === 'islem' ? 'İşlem' : 'İşlem'}}</mat-label>
                      <mat-checkbox  matTooltip="Hepsini Seç" matTooltipPosition='left'  *ngIf="i === 0"
                        (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let urun" >
                      <mat-checkbox *ngIf="i === 0" (click)="$event.stopPropagation()"
                        (change)="$event ? onChangeUrunCheckBox(urun) : null" [checked]="selection.isSelected(urun.id)">
                      </mat-checkbox>
                      <mat-spinner *ngIf="i === 1" id="urunSpinner-{{urun.id}}" style="display: none; " [diameter]="25">
                      </mat-spinner>
                      <span *ngIf="i === 1&&teklifIconVisibility" id="urunDone-{{urun.id}}" class="material-icons urunDoneIcon"
                        style="display: none;">done</span>
                      <span data-placement='left' data-toggle="tooltip" data-original-title="" *ngIf="i === 1&&teklifIconVisibility"
                        id="urunError-{{urun.id}}" class="material-icons urunErrorIcon"
                        style="display: none;">close</span>
                       <span *ngIf="i === 2&&teklifIconVisibility" id="urunWarning-{{urun.id}}" data-placement='left' data-toggle="tooltip" data-original-title="" class="material-icons urunBilgiIcon" style="display: none;">error</span> 
                      <!--<mat-spinner *ngIf="i === 1" id="spinner-{{urun.id}}" style="width: 30px; height: 30px; display: none;" [diameter]="30"></mat-spinner>-->
                      <div align="right" *ngIf="i===6" style="font-size: 13px;">{{urun[column]}}</div>
                      <div *ngIf="i!=6" style="font-size: 13px;">{{urun[column]}}</div>
                    </td>                  
                  </ng-container>
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let urun" [attr.colspan]="columnsToDisplay.length">
                      <div class="example-element-detail"
                        [@detailExpand]="urun == expandedElement ? 'expanded' : 'collapsed'">
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr mat-row *matRowDef="let urun; columns: columnsToDisplay;" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === urun"
                    (click)="expandedElement = expandedElement === urun ? null : urun">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
              </div>
            </mat-card-content>
            <button style="margin-left: 15px; margin-top: 6px;" class="buttonHight" mat-raised-button  (click)="trafikTeklifAl()" [disabled]="disableButton===true">Teklif Al</button>
                <button style="margin-left: 15px; margin-top: 6px;" class="buttonHight" mat-raised-button  (click)="sendToPdf()">
                  <span class="material-icons iconhover">
                    picture_as_pdf
                  </span>
                 Pdf Kaydet</button>
            <!-- <button class="buttonHight" mat-raised-button color="primary" (click)="exportTable()">
              <span class="material-icons iconhover" style="margin-top: -5px;">
                cloud_download</span>Excele Aktar</button> -->
          </mat-card>
        </form>
        <br>
        <section class="example-section">
          <button class="buttonHight" mat-raised-button color="primary" matStepperPrevious style="display: none;">Önceki
            Adıma Dön</button>
        </section>        
        <!--<section class="example-section">
          <div *ngIf="!onTeklifSpinner" id="teklifLoader">
            <mat-spinner></mat-spinner>
          </div>
          &lt;!&ndash; <div id="spinner" style="display: none;">
              <mat-spinner></mat-spinner>
          </div> &ndash;&gt;
        </section>-->
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
</div>


