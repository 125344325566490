import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(girilenGSM:string) {
    var resultGsm=girilenGSM.length==1?"0 ("+girilenGSM
    :((girilenGSM.length==7)&&(!girilenGSM.includes(")")))?girilenGSM.substring(0, 6)+") "+girilenGSM.slice(-1)
    :(girilenGSM.length==11&&girilenGSM.includes("(")&&girilenGSM.includes(")"))?girilenGSM+ " "
    :(girilenGSM.length==14)?girilenGSM.replace(girilenGSM, girilenGSM+' ')
    :girilenGSM 
    if((girilenGSM.length==10&&!girilenGSM.includes("("))||(girilenGSM.length==11&&!girilenGSM.includes("(")))
    {
     var a=girilenGSM.slice(girilenGSM.length - 10);
     resultGsm="0 ("+a.slice(0,3)+") "+a.slice(3,6)
     +" "+a.slice(6,8)+" "+a.slice(8,10)
    }

    return resultGsm;
  }
}
