import { Hata } from "../../OnBilgi/hata";
import SirketOdemeCoreModel from "../sirketOdemeCoreModel/sirketOdemeCoreModel";

export default class EslestirilenOdemeList {
    eslestirilenOdemeler: SirketOdemeCoreModel[];
    basariliMi: any;
    hata: Hata;
  
    constructor() {
      this.eslestirilenOdemeler = [];
      this.hata = new Hata();
    }
  }