import { SifremiUnuttumRequestModel } from 'src/app/models/Auth/PassordForget/sifremi-unuttum-request-model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginRequestModel } from '../models/Auth/Login/login-request-model';
import { authApiBaseUrl, uiHelperApiBaseUrl } from '../../environments/environment';
import { LoginResponseModel } from '../models/Auth/Login/login-response-model';
import { Ilce } from '../models/Adres/ilce';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SifremiUnuttumResponseModel } from '../models/Auth/PassordForget/sifremi-unuttum-response-model';
import { SifreOlusturRequestModel } from '../models/Auth/SifreOlustur/sifre-olustur-request-model';
import { SifreOlusturResponseModel } from '../models/Auth/SifreOlustur/sifre-olustur-response-model';
import { JwtClaimTypeRequestModel } from '../models/Auth/claim/jwt-claim-type-request-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  resResult: boolean;
  validResult: LoginResponseModel;
  constructor(private _http: HttpClient, private router: Router, private _snackBar: MatSnackBar) {
    this.resResult = false;
    this.validResult = new LoginResponseModel();
  }

  login(loginRM: LoginRequestModel): Observable<LoginResponseModel> {
    return this._http.post<LoginResponseModel>(`${authApiBaseUrl}api/Login`,
      {
        email: loginRM.email,
        sifre: loginRM.sifre,
        captchaToken: loginRM.captchaToken
      }).pipe(
        map((response: LoginResponseModel) => {
          return response;
        })
      );
  }

  sifreYenile(sifremiUnuttumRM: SifremiUnuttumRequestModel): Observable<SifremiUnuttumResponseModel> {
    return this._http.post<SifremiUnuttumResponseModel>(`${authApiBaseUrl}api/SifremiUnuttum`,
    {
      email: sifremiUnuttumRM.email,
      googleCaptchaToken: sifremiUnuttumRM.googleCaptchaToken
    }).pipe(
      map((response: SifremiUnuttumResponseModel) => {
        return response;
      })
    );
  }

  sifreOlustur(sifremiUnuttumRM: SifreOlusturRequestModel, encodedToken: any): Observable<SifreOlusturResponseModel> {
    return this._http.post<SifremiUnuttumResponseModel>(`${authApiBaseUrl}api/SifremiDegistir`,
    {
      sifre: sifremiUnuttumRM.sifre,
      sifreTekrar:sifremiUnuttumRM.sifreTekrarla,
      sifreToken: encodedToken,
      googleCaptchaToken: sifremiUnuttumRM.googleCaptchaToken
    }).pipe(
      map((response: SifreOlusturResponseModel) => {
        return response;
      })
    );
  }

  isValidateToken(token: string) {
    return this._http.post<any>(`${authApiBaseUrl}api/ValidateToken`, { authToken: token }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  isLoggedIn(): boolean {
    let reqToken = localStorage.getItem('access_token');
    if (reqToken == null || reqToken == '') {
      return false;
    } else {
      let cevap = this.isValidateToken(reqToken).subscribe(
        (data) => {
          if (data.result) {
            return true;
          } else {
            this.router.navigate(["login"]);
            return false;
          }
        },
        (error) => {
          return false;
        }
      );
      if (cevap) {
        return true;
      } else {
        return false;
      }
    }
  }

  logOut() {
    localStorage.removeItem("userName")
    localStorage.removeItem("access_token")
    this.router.navigate(["login"]);
  }

  getUserClaimValue(jwtClaimTypeRequestModel: JwtClaimTypeRequestModel) {
    return this._http.post<string>(`${authApiBaseUrl}api/GetUserClaimValue`, 
    { 
      authToken: jwtClaimTypeRequestModel.authToken,
      claimType:jwtClaimTypeRequestModel.claimType
    }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
