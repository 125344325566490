import { SigortaSirket } from "../SigortaSirket/sigorta-sirket";

export class OncekiPolice {
    sigortaSirket: SigortaSirket;
    acenteNo: any;
    policeNo: any;
    bitisTarihi: any;
    yenilemeNo: any;

    constructor(){
        this.sigortaSirket = new SigortaSirket();
    }
}


