import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(val) {
    if (val) {
      val = this.format_number(val, '');
    }
    return val;
  }

  format_number(number, prefix) {
    let thousand_separator = '.',
      decimal_separator = ',',
      regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
      number_string = number.replace(regex, '').toString(),
      split = number_string.split(decimal_separator),
      rest = split[0].length % 3,
      result = split[0].substr(0, rest),
      thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      let separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result =
      split[1] != undefined ? result + decimal_separator + split[1] : result;
    return prefix == undefined ? result : result ? prefix + result : '';
  }
}


/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

/* import { Pipe, PipeTransform } from '@angular/core';

 @Pipe({ 
     name: 'numberFormat'
  })
 export class NumberFormatPipe implements PipeTransform {
     transform(value: any): number {
         return this.localeString(value);
     }
 
     missingOneDecimalCheck(nStr) {
         nStr += '';
         const x = nStr.split(',')[1];
         if (x && x.length === 1) return true;   
         return false;
     }
 
     missingAllDecimalsCheck(nStr) {
         nStr += '';
         const x = nStr.split(',')[1];
         if (!x) return true;    
         return false;
     }
 
     localeString(nStr) {
         if (nStr === '') return ''; 
         let x, x1, x2, rgx, y1, y2;
         nStr += '';
         x = nStr.split('.');
         x1 = x[0];
         x2 = x.length > 1 ? ',' + x[1] : '';
         rgx = /(\d+)(\d{3})/;
         while (rgx.test(x1)) {
             x1 = x1.replace(rgx, '$1' + '.' + '$2');
         }
 
         /** If value was inputed by user, it could have many decimals(up to 7)
             so we need to reformat previous x1 results */
  /*       if (x1.indexOf(',') !== -1) {
             y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');
 
             y2 = x1.split(',');
             x = y2[0] +  y1;
         } else {
             x = x1 + x2;
             if (this.missingOneDecimalCheck(x)) return x += '0';
             if (this.missingAllDecimalsCheck(x)) return x += ',00';
         }
 
         return x;
     }
 }
 */