import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sirketAuthApiBaseUrl } from 'src/environments/environment';
import { EkranBilgisiList } from '../models/SigortaSirket/ekran-bilgisi-list';

@Injectable({
  providedIn: 'root'
})
export class SirketAuthService {

  constructor(private http: HttpClient) { }

  SirketEkranBilgileriniGetir(token: any): Observable<EkranBilgisiList> {
    return this.http.post<any>(`${sirketAuthApiBaseUrl}api/SirketEkranBilgileriniGetir`, {
      token: token
    }).pipe(map(
      (response: EkranBilgisiList) => {
        return response;
      }
    ));
  }
}
