<div class="container pb-5" >
    <mat-card class="rowCard" id="rowCard01">
        <mat-card-header class="card-header col-md-12 text-wrap"
            style=" background-color: #009688;color:rgb(253, 253, 253); margin: auto; height: 40px;border-top-left-radius: 10px;border-top-right-radius: 10px;">
            <mat-label class="h5 " style="position: absolute; width: 70px; margin-left: 45%;">Şirketler</mat-label>
        </mat-card-header>
        <mat-card-content class="py-4 pl-4 pr-2" id="content01">

            <mat-form-field appearance="outline" style="margin-left: 0px;">
                <mat-label>Şirket Ara</mat-label>
                <input matInput placeholder="Şirket ara" [(ngModel)]="text">
                <span matSuffix>
                    <fa-icon *ngIf="text==''||text==null" [icon]="faSearch" style="color: #009688; font-size: 18px;margin-right: 5px;">
                    </fa-icon> 
                    <fa-icon *ngIf="text!=''&&text!=null" [icon]="faTimes" style="color: #009688; font-size: 20px;margin-right: 5px;" (click)="resetText()">
                    </fa-icon> 
                </span>
            </mat-form-field>           
            <div class="row ">
                <div class="col-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let ekranBilgi of ekranBilgiList | filterPipe:text">
                    <div class="card cardSize">
                        <button mat-button class="border sirketButon card-img-top">
                            <img src="{{ekranBilgi.logoLinki}}" class="imgSirketler"
                                (click)="sendToken(ekranBilgi.robotLinki)">
                        </button>
                        <div class="card-body">
                            <h5 class="card-title">{{ekranBilgi.linkAdi}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>