import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TrafikTeklifRequestModel} from '../models/Teklif/Trafik/trafik-teklif-request-model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {trafikCoreApiBaseUrl} from '../../environments/environment';
import { UniqueTeklifNoRequestModel } from '../models/Teklif/Trafik/unique-teklif-no-request-model';
import { Trafikonbilgi } from '../models/OnBilgi/Trafik/trafikonbilgi';
import { UniqueTeklifNoResultModel } from '../models/Teklif/Trafik/unique-teklif-no-result-model';
import { TrafikTeklifResponseModel } from '../models/Teklif/Trafik/trafikTeklifResponseModel';

@Injectable({
  providedIn: 'root'
})
export class TrafikteklifService {

  constructor(private http: HttpClient) { }

  trafikTeklifAl(trafikTeklifRequest: TrafikTeklifRequestModel): Observable<TrafikTeklifResponseModel> {    
    return this.http.post<TrafikTeklifResponseModel>(`${trafikCoreApiBaseUrl}api/TeklifAl`,    
      {
        onBilgi: trafikTeklifRequest.onBilgi,
        urun: trafikTeklifRequest.urun,
        urunWsBilgi: trafikTeklifRequest.urunWSBilgi,
        uniqueTeklifNo: trafikTeklifRequest.uniqueTeklifNo
      }
    )
  }

  trafikUniqueTeklifNoAll(requestModel: UniqueTeklifNoRequestModel): Observable<UniqueTeklifNoResultModel> {
    return this.http.post<UniqueTeklifNoResultModel>(`${trafikCoreApiBaseUrl}api/TrafikUniqueTeklifNoUret`,
      {
        authToken: requestModel.authToken,
        sigortaliId: requestModel.sigortaliId,
        sigortaliAracId: requestModel.sigortaliAracId
      }
    ).pipe(
      map((response: UniqueTeklifNoResultModel) => {
        return response;
      })
    );
  }

  trafikUniqueTeklifNoAl(requestModel: UniqueTeklifNoRequestModel):Promise<UniqueTeklifNoResultModel>{
    return this.http.post<UniqueTeklifNoResultModel>(`${trafikCoreApiBaseUrl}api/TrafikUniqueTeklifNoUret`,
    {
      authToken: requestModel.authToken,
      sigortaliId: requestModel.sigortaliId,
      sigortaliAracId: requestModel.sigortaliAracId
    }).toPromise()
  }
}
