export class LoginRequestModel {
  email: string;
  sifre: string;
  captchaToken: string;
  ip: string;

  constructor() {
    this.email = "";
    this.sifre = "";
    this.captchaToken = "";
    this.ip = "";
  }
}
