import KaskoUrunResultModel from '../../Urun/kasko-urun-result-model';
// @ts-ignore
import KaskoOnBilgi from '../../OnBilgi/Kasko/kaskoonbilgi';
import {UrunWSBilgiModel} from '../../Urun/urun-wsbilgi-model';

export default class KaskoTeklifRequestModel {
  onBilgi: KaskoOnBilgi;
  urun: KaskoUrunResultModel;
  urunWSBilgi: UrunWSBilgiModel;
  uniqueTeklifNo: any;
}