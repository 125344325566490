<!-- <div align="center" style="margin-top: 45px;"> <div  style="width: 10%; font-weight:599; ;font-size: 15px;border-color: #673ab7; border: 7px solid #673ab7; background-color: #673ab7; color: #fff" >Anasayfa</div></div> -->
<section style="background-color: rgba(68, 68, 68, 0.5); height: 100%; position: fixed; width: 100%; z-index: 998;" *ngIf="loadingService.isLoading|async">
  <mat-spinner [diameter]="50" style="  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;"></mat-spinner>
</section>
<div class="ortalatmaDiv">
  <div align="center" class="row">
 
  <div class="col-md-3">
    <button mat-button class="iconButton">
      <fa-icon [icon]="faCarCrash" class="faIcon" routerLink="/kasko" matTooltipPosition="above" matTooltip="Kasko" >
      </fa-icon>
    </button>
  </div>
  <div class="col-md-3 iconButton">
    <button mat-button style="background-color: inherit; border-color: inherit; " >
      <fa-icon [icon]="faTraffic" class="faIcon" routerLink="/trafik" matTooltipPosition="above"
        matTooltip="Trafik Sigortası"></fa-icon>
    </button>
  </div>
  <div class="col-md-3 iconButton">
    <button  mat-button style="background-color: inherit; border-color: inherit;" >

      <fa-icon [icon]="faHome" class="faIcon" matTooltipPosition="above" matTooltip="Konut Sigortası"
        (click)="disablePolice('Konut Sigortası')"></fa-icon>
    </button>
  </div>
  <div class="col-md-3 iconButton">
    <button mat-button style="background-color: inherit; border-color: inherit;">

      <fa-icon [icon]="faBuilding" class="faIcon" matTooltipPosition="above" matTooltip="İşyeri Sigortası"
        (click)="disablePolice('İşyeri Sigortası')"></fa-icon><br><br>
    </button>
  </div>
  <div class="col-md-3 iconButton">
    <button  mat-button style="background-color: inherit; border-color: inherit; ">

      <fa-icon [icon]="faHeartBeat" class="faIcon" matTooltipPosition="above" matTooltip="Sağlık Sigortası"
        (click)="disablePolice('Sağlık Sigortası')"></fa-icon>
    </button>
  </div>
  <div class="col-md-3 iconButton">
    <button  mat-button style="background-color: inherit; border-color: inherit; ">

      <fa-icon [icon]="faWheelchair" class="faIcon" matTooltipPosition="above" matTooltip="Ferdi Kaza Poliçesi"
        (click)="disablePolice('Ferdi Kaza Sigortası')"></fa-icon>
    </button>
  </div>
  <div class="col-md-3 iconButton">
    <button  mat-button style="background-color: inherit; border-color: inherit; ">
      <fa-icon [icon]="faRoute" class="faIcon" matTooltipPosition="above" matTooltip="Seyehat Sağlık Sigortası"
        (click)="disablePolice('Seyehat Sağlık Sigortası')"></fa-icon>
    </button>
  </div>
    <div class="col-md-3 iconButton">
    <button  mat-button style="background-color: inherit; border-color: inherit; ">
      <fa-icon [icon]="faMobile" class="faIcon" matTooltipPosition="above" matTooltip="Cep Telefonu Sigortası"
        (click)="disablePolice('Cep Telefonu Sigortası')">
      </fa-icon>
    </button>
  </div>
  </div>
  <!-- <mat-card>
        <table>
          <tr>
            <td>
              <button mat-raised-button color="primary" routerLink="/trafik" id="trafikTeklifComponent" class="button" >
                Trafik Teklif
              </button>
            </td>
            <td>&nbsp;</td>
            <td>
              <button mat-raised-button color="primary"  routerLink="/kasko" id="kaskoTeklifComponent" class="button" >
                Kasko Teklif
              </button>
            </td>
            <td>&nbsp;</td>
            <td>
              <button mat-raised-button color="primary"  routerLink="/tekliflistesi" id="teklifListeleComponent" class="button" >
                Teklif Listesi
              </button>
            </td>
          </tr>
        </table> 
      </mat-card>-->

  <!--<div id="kaskoTeklifComponent">
          <h3>Kasko Teklif</h3>
          <span routerLink="/kaskoteklif" id="kaskoComponentIcon" class="material-icons">
        electric_car
        </span>
        </div>

        <div id="trafikTeklifComponent">
          <h3>Trafik Teklif</h3>
          <span routerLink="/trafikteklif" id="trafikComponentIcon" class="material-icons">
        electric_car
        </span>
        </div>-->


</div>