
<section style="background-color: rgba(68, 68, 68, 0.5); height: 100%; position: fixed; width: 100%; z-index: 998;" *ngIf="loadingService.isLoading|async">
  <mat-spinner [diameter]="50" style="  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;"></mat-spinner>
</section>
<mat-card>

  <mat-card-content>

    <mat-card class="col-md-12">

      <mat-card-content>
        <form [formGroup]="teklifListForm" class="example-form" (ngSubmit)="filtreleOnClick()">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Sigortalı: </mat-label>
            <input class="upperCase" type="text" placeholder="Kimlik No veya Ad/Unvan Giriniz" aria-label="Number" matInput (ngModelChange)="onSigortaliChange($event)"
              [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="kimlikNo">
            <mat-autocomplete #auto="matAutocomplete" >
              <ng-container *ngIf="sigortaliSelectsVsblty" >              
              <mat-option class="sigortaliSelect" *ngFor="let item of sigortaliFilterData"
                [value]="item.kimlikNo + '  '+ item.adUnvan">
                {{item.kimlikNo.toUpperCase()}} - {{item.adUnvan.toUpperCase()}}
              </mat-option>
            </ng-container>
            </mat-autocomplete>
          </mat-form-field>

          &nbsp;&nbsp;
          <mat-form-field appearance="outline" style="width: 140px;" > 
            <mat-label>Plaka:</mat-label>
            <input oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')"  class="upperCase" [(ngModel)]="plaka" formControlName="plakaInput" matInput placeholder="Plaka">
          </mat-form-field>

          &nbsp;&nbsp;
          <mat-form-field appearance="outline" style="width: 110px;">
            <mat-label>Teklif No:</mat-label>
            <input class="upperCase" oninput="this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')"  [(ngModel)]="hizliTeklifNo" formControlName="hizliTeklifNoInput" matInput placeholder="Teklif No">
          </mat-form-field>

          &nbsp;&nbsp;
          <div style="display: inline;">
          
            <mat-form-field appearance="outline" style="width: 200px;">
              <mat-label>Tarih Aralığı:</mat-label>
              <mat-select formControlName="tarihAraligi" [(ngModel)]="selectedTarih" (ngModelChange)="onTarihAraligiChange($event)">
                <mat-option *ngFor="let tarih of girilenTarihAraligi" [value]="tarih">
                  {{tarih.tanim}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" id="tarihAraligiSecinizInput">
              <mat-label>Tarih Aralığı Seçiniz:</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="baslangicTarihiFormElement" [(ngModel)]="baslangicTarihi"
                  (ngModelChange)="onChangeBaslangicTarihi($event)" placeholder="Başlangıç">
                <input matEndDate formControlName="bitisTarihiFormElement" [(ngModel)]="bitisTarihi"
                  (ngModelChange)="onChangeBitisTarihi($event)" placeholder="Bitiş">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker" style="color: #009688;"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
          
              <mat-error *ngIf="range.controls.baslangicTarihiFormElement.hasError('matStartDateInvalid')">Geçersiz
                Başlangıç Tarihi</mat-error>
              <mat-error *ngIf="range.controls.bitisTarihiFormElement.hasError('matEndDateInvalid')">Geçersiz Bitiş Tarihi
              </mat-error>
            </mat-form-field>
          </div>
          &nbsp;&nbsp;

          <mat-form-field appearance="outline" style="width: 70px;">
            <!-- <mat-label *ngIf="ifBranshSelected()">Branş Seçiniz</mat-label> -->
            <mat-label>Branş</mat-label>
            <mat-select formControlName="bransh" matInput [(ngModel)]="selectedSorguTipi" name="bransSecim">
              <mat-option *ngFor="let tip of sorguTipleri" [value]="tip.id">
                {{tip.tanim}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          &nbsp;&nbsp;
          &nbsp;&nbsp;

          <mat-form-field *ngIf="isOrtagiFilterData.length != 0" appearance="outline" style="width: 140px;">
            <mat-label>İş Ortağı Seçiniz</mat-label>
            <mat-select formControlName="isOrtagiControl" [(ngModel)]="selectedIsOrtagiFilter" name="isOrtagiSecim">
              <mat-option *ngFor="let isOrtagi of isOrtagiFilterData" [value]="isOrtagi">
                {{isOrtagi.kisaAdi}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          &nbsp;&nbsp;
              <br class="brForFiltreleButton">
          <button type="submit" class="filtreleVeTemizle" mat-raised-button ><span class="material-icons iconhover" >search</span>
            Filtrele
          </button>

          <button (click)="filtrelemeFormunuTemizle()"  type="button" class="filtreleVeTemizle"
            mat-raised-button>
            <span class="material-icons iconhover">cleaning_services</span> Temizle
          </button>
          
          <section class="example-section">
            <div *ngIf="onSpinner" id="loader ">
              <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
            </div>           
          </section>
        </form>
      </mat-card-content>
    </mat-card>
 
    <dx-data-grid #grid id="grid" [dataSource]="dataSource" keyExpr="teklifNo" [rowAlternationEnabled]="false" (onExporting)="onExporting($event)"  [hoverStateEnabled]="true" 
      [allowColumnReordering]="false"  [showBorders]="true" [columnAutoWidth]="true" > 
      <!-- <dxo-selection mode="single"></dxo-selection> -->
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-paging [pageSize]="100"></dxo-paging>
      <!-- <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[ 150,200]"></dxo-pager> -->
      <dxo-export [enabled]="true" fileName="Teklif" [allowExportSelectedData]="false" ></dxo-export>
      <dxi-column dataField="teklifTarihi" dataType="date" format="d.MM.yyyy HH:mm" [allowHiding]="false"
        caption="Tarih / Saat">
      </dxi-column>
      <dxi-column dataField="teklifNo" dataType="string" [allowHiding]="false" caption="Teklif No"></dxi-column>
      <dxi-column dataField="bransAdi" dataType="string" caption="Branş"></dxi-column>
      <dxi-column dataField="kimlikNo" dataType="string" caption="Kimlik No" [width]="120"></dxi-column>
      <dxi-column dataField="adUnvan" dataType="string" caption="Adı / Ünvanı" [width]="250" [sortingMethod]="compare"></dxi-column>
      <dxi-column dataField="plaka" dataType="string" caption="Plaka"></dxi-column>
      <dxi-column dataField="ruhsat" dataType="string" caption="Ruhsat Belge No"></dxi-column>
      <ng-container *ngIf="isOrtagiFilterData.length != 0">
        <dxi-column  dataField="isOrtagiKisaAdi" dataType="string" caption="İş Ortağı" [sortingMethod]="compare"></dxi-column>
      </ng-container>
      <dxi-column dataField="kullaniciEmailAdresi" dataType="string" caption="Kullanıcı"></dxi-column>

      <!-- <dxi-column type="buttons" caption="Kaydet" [width]="70" alignment="center"> <dxi-button  icon="download" style="color: black;"  [onClick]="cloneIconClick"></dxi-button></dxi-column> -->

      <!-- <div *dxTemplate="let data of 'groupCellTemplate'">
        {{data.key}}
      </div> -->

      <dxo-master-detail  [autoExpandAll]="false" [enabled]="true" template="teklifler"></dxo-master-detail>
      <div *dxTemplate="let teklif of 'teklifler'">
        <!-- <button  icon="exportpdf" text="Tabloyu PDF olarak kaydet" (click)="onPdfExport()" style="width: 120px; height: 40px;">
        </button> 
           <dx-button id="pdfButton" icon="export" text="Tabloyu Excel olarak kaydet" (click)="excelExport()">
        </dx-button>     -->
        <dx-data-grid    #export id="gridContainer" [dataSource]="teklif.data.teklifler" keyExpr="id"
          [rowAlternationEnabled]="false" [allowColumnReordering]="true" [remoteOperations]="true" [showBorders]="true"
          [columnAutoWidth]="true" >
        <!-- <dxo-export [enabled]="true" #exx fileName="Teklif" [allowExportSelectedData]="false"></dxo-export> -->
          <!--    <dxo-selection mode="multiple" ></dxo-selection> -->
          <dxi-column dataField="sigortaSirketiAdi" dataType="string" caption="Sigorta Şirketi" sortOrder="asc"></dxi-column>
          <dxi-column dataField="urunAdi" dataType="string" caption="Ürün" ></dxi-column>
          <dxi-column dataField="teklifNo" dataType="string" caption="Şirket Teklif No"></dxi-column>
          <dxi-column dataField="brutPrim" alignment="right" caption="Brüt Prim" [customizeText]="brutPrimColumnCustomizeText"  [sortingMethod]="sortStringsConsideringCulture">
            <!--[format]="{ currency: 'TL', maximumFractionDigits: 2, showClearButton: true }"-->
          </dxi-column>
          <!-- <dxi-column dataField="teklifTarihi" dataType="date" caption="Teklif Tarihi"></dxi-column> -->
          <div *ngIf="teklif.data.bransAdi=='KSK'">
            <dxo-master-detail [enabled]="true" template="teminatlar"></dxo-master-detail>
            <div *ngFor="let teklifDetay of teklif.data.teklifler">
              <div *dxTemplate="let teminat of 'teminatlar'">
                <table id="teklifler" *ngIf="teklifDetay.bransId === 2" class="table table-bordered border-dark">
                  <thead class="table-secondary">
                    <tr>
                      <th scope="col">Teminat Türü</th>
                      <th scope="col">Seçilen Değer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="teminat.data.teminatlar.secilenMeslek.tanim != null">
                      <td>MESLEK</td>
                      <td>{{teminat.data.teminatlar.secilenMeslek.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenIMM.tanim != null">
                      <td>IMM</td>
                      <td>{{teminat.data.teminatlar.secilenIMM.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenFerdiKaza.tanim != null">
                      <td>FERDİ KAZA</td>
                      <td>{{teminat.data.teminatlar.secilenFerdiKaza.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenManeviTazminat.tanim != null">
                      <td>MANEVİ TAZMİNAT</td>
                      <td>{{teminat.data.teminatlar.secilenManeviTazminat.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenIkameArac.tanim != null">
                      <td>İKAME ARAÇ</td>
                      <td>{{teminat.data.teminatlar.secilenIkameArac.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenAnahtarKaybi.tanim != null">
                      <td>ANAHTAR KAYBI</td>
                      <td>{{teminat.data.teminatlar.secilenAnahtarKaybi.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenYetkiliServis.tanim != null">
                      <td>YETKİLİ SERVİS</td>
                      <td>{{teminat.data.teminatlar.secilenYetkiliServis.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenDepremSel.tanim != null">
                      <td>DEPREM & SEL</td>
                      <td>{{teminat.data.teminatlar.secilenDepremSel.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenCamMuafiyeti.tanim != null">
                      <td>CAM MUAFİYETİ</td>
                      <td>{{teminat.data.teminatlar.secilenCamMuafiyeti.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenKiralikArac.tanim != null">
                      <td>KİRALIK ARAÇ</td>
                      <td>{{teminat.data.teminatlar.secilenKiralikArac.tanim}}</td>
                    </tr>
                    <tr *ngIf="teminat.data.teminatlar.secilenYanlisAkaryakitDolumu.tanim != null">
                      <td>YANLIŞ AKARYAKIT DOLUMU</td>
                      <td>{{teminat.data.teminatlar.secilenYanlisAkaryakitDolumu.tanim}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--<div *dxTemplate="let teminat of 'teminatlar'">
                <div *ngFor="let teklifDetay of teklif.data.teklifler">
                </div>
              </div>-->
        </dx-data-grid>
      </div>
    </dx-data-grid>

    <!--<detail-grid [teklifResultList]="dataSource"></detail-grid>-->

    <!--<dx-data-grid id="gridContainer" [dataSource]="gridDataSource" [rowAlternationEnabled]="true" [allowColumnReordering]="true" [remoteOperations]="true" [showBorders]="true">

        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-group-panel [visible]="true" ></dxo-group-panel>
        <dxo-paging [pageSize]="8"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
        <dxo-filter-row [visible]="true" ></dxo-filter-row>

        <dxo-paging [pageSize]="15"></dxo-paging>
        <dxi-column dataField="KimlikNo"></dxi-column>
        <dxi-column dataField="AdUnvan"></dxi-column>
        <dxi-column dataField="Plaka"></dxi-column>
        <dxi-column dataField="Ruhsat"></dxi-column>
        <dxo-master-detail [enabled]="true" template="teklifler"></dxo-master-detail>



        <div *dxTemplate="let teklifItem of ''">
          &lt;!&ndash;<app-teklifgecmisi [key]="masterDetailItem.id" [rowData]="masterDetailItem" ></app-teklifgecmisi>&ndash;&gt;

          <dx-form labelLocation="top" class="form-container">
            &lt;!&ndash;<dxi-item template="productSelectBox">
              <dxo-label text="Product"></dxo-label>
            </dxi-item>&ndash;&gt;
            &lt;!&ndash;<div *dxTemplate="let selectBoxItem of 'productSelectBox'">
              <dx-select-box
                [value]="productIdBySupplier"
                [dataSource]="productsData"
                [deferRendering]="false"
                valueExpr="ProductID"
                displayExpr="ProductName"
                (onValueChanged)="handleValueChange($event)"
              >
              </dx-select-box>
            </div>&ndash;&gt;

            <dxi-item template="teklifGecmisi">
              <dxo-label text="Teklif Geçmişi"></dxo-label>
            </dxi-item>
            <dx-data-grid
              [dataSource]="teklifItem"
              [showBorders]="true">

              <dxo-paging [pageSize]="5"></dxo-paging>

              <dxi-column dataField="id"></dxi-column>
              <dxi-column dataField="urunAdi"></dxi-column>
              <dxi-column dataField="brutPrim" format="currency"></dxi-column>
              <dxi-column dataField="teklifNo"></dxi-column>
              <dxi-column dataField="teklifTarihi" dataType="date"></dxi-column>

              <dxo-summary>
                <dxi-total-item
                  column="brutPrim"
                  summaryType="sum">
                  &lt;!&ndash;<dxo-value-format
                    format="currency"
                    [precision]="2"
                  ></dxo-value-format>&ndash;&gt;
                </dxi-total-item>
                &lt;!&ndash;<dxi-total-item column="Quantity" summaryType="count"></dxi-total-item>&ndash;&gt;
              </dxo-summary>
            </dx-data-grid>
          </dx-form>
        </div>
      </dx-data-grid>-->
  </mat-card-content>
</mat-card>
<br>
<br>