// @ts-ignore
import Arac from "../Arac";
// @ts-ignore
import { Sigortali } from "../sigortali";
// @ts-ignore
import { Hata } from "../Hata";

export class Trafikonbilgi {
  Sigortali: Sigortali;
  Arac: Arac;
  Hata: Hata;
  BasariliMi: boolean;
  PoliceVadesiGecerliMi: any;
  Token: string;
  ClaimType: any;
  ToplamSorguSuresi: any;
  SorgulayanKullaniciId: any;
  TeklifId: any;
}
