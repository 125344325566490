import {  CanActivate,  ActivatedRouteSnapshot,  RouterStateSnapshot,  Router} from "@angular/router";
import { Injectable } from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';
import {waitForAsync} from '@angular/core/testing';
import { AuthService } from "src/app/service/auth.service";

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private _authService: AuthService, private router: Router, private _snackBar: MatSnackBar) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let logged = this._authService.isLoggedIn();

    if (logged) {
      return true;
    }

    this.router.navigate(["login"]);

    return false;

    }

}
