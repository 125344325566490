<!-- <div class="container">
  <h1>Hızlı Teklif v1</h1>

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link"
    routerLink="/"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
    >Anasayfa</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/login" >Kullanıcı Girişi</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/onbilgisorgu" >Ön Bilgi Sorgula</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/onbilgiduzenle" >Ön Bilgi Düzenle</a>
  </li>
</ul>

</div> -->

<!--  <mat-toolbar color="primary">

    &lt;!&ndash;<button routerLink="/home" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon >menu</mat-icon>

    </button>&ndash;&gt;
    <span>Hızlı Teklif</span>
      <span class="example-spacer"></span>
    &lt;!&ndash; <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button> &ndash;&gt;
   </mat-toolbar>-->

<!-- <sidenav [openedSubject]="openedSubject"></sidenav> -->

<!-- <app-main-nav ></app-main-nav> -->
<div>
  <mat-sidenav-container fullscreen>
    <mat-sidenav #drawer class="sidenav" [ngClass]="{hide: !(isHandset$ | async)}" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'push'"
      [(opened)]="sidenavOpened">
      <mat-toolbar color="primary" style="height: 45px;background-color: #009688;">Menu</mat-toolbar>
      <mat-nav-list >        
        <a mat-list-item (click)="subMenuOpen()" style="font-weight:400;" (click)="caretCircleDownIcon=!caretCircleDownIcon" > Online İşlemler
          <fa-icon [icon]="faCaretCircleDown" style="color: #009688; position:absolute;right: 15px;" [ngClass]="caretCircleDownIcon ? 'toTheRightIcon' : 'toTheLeftIcon'">
          </fa-icon>
        </a>
        <a *ngIf="subMenuVisbility" class="sub-menu" mat-list-item routerLink="/kasko" (click)="drawer.toggle()">
          <fa-icon [icon]="faKasko" class="faIcon" style="color: #009688;">
          </fa-icon>
          <p class="sub-yazi">Kasko</p>
        </a>

        <a *ngIf="subMenuVisbility" class="sub-menu" mat-list-item routerLink="/trafik" (click)="drawer.toggle()">
          <fa-icon [icon]="faTraffic" class="faIcon" style="color: #009688;">
          </fa-icon>
          <p class="sub-yazi">Trafik Sigortası</p>
        </a>
        <a *ngIf="subMenuVisbility" class="sub-menu" mat-list-item routerLink="/tekliflistesi"
          (click)="drawer.toggle()">
          <fa-icon [icon]="faTeklifListesi" class="faIcon" style="color: #009688;">
          </fa-icon>
          <p class="sub-yazi">Teklif Listesi</p>
        </a>
      </mat-nav-list>
      <!-- <mat-nav-list>
        <a mat-list-item  routerLink="/sirketler" style="font-weight:400;" (click)="drawer.toggle()"> Şirketler
          <fa-icon [icon]="faBuilding" style="color: #009688; position:absolute;right: 15px;">
          </fa-icon>
        </a>
      </mat-nav-list> -->
      <mat-nav-list>
        <a mat-list-item style="font-weight:400;" (click)="helpMenuVisbility=!helpMenuVisbility"> Yardım
          <fa-icon [icon]="faHandsHelping" style="color: #009688; position:absolute;right: 15px;">
          </fa-icon>
        </a>
        <a *ngIf="helpMenuVisbility" class="sub-menu" mat-list-item (click)="drawer.toggle()" (click)="openRequest()">
          <fa-icon [icon]="faClipboardList" class="faIcon" style="color: #009688;">
          </fa-icon>
          <p class="sub-yazi"  >Talep Ekranı</p>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary" style="height: 45px; position: fixed;z-index: 998;background-color: #009688;">
        
        <mat-toolbar-row >
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawerOpen()">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <button style="display: none;" (click)="drawer.open()" id="drawerOpen"></button>
          <span>SigortaSepeti</span>
          <div id="headerTitle" style="position:absolute; left: 50%; margin-left: -25px;"
            *ngIf="navbarAtributeVisibility===true">{{headerTitle}}</div>
 
          <button *ngIf="navbarAtributeVisibility===true" class="accountClass" (click)="getUser()" mat-icon-button
            [matMenuTriggerFor]="stationMenu">
            <mat-icon style="font-size: 30px;">account_circle</mat-icon>
          </button>
          <mat-menu #stationMenu="matMenu">
            <button mat-menu-item><fa-icon [icon]="faLogin" style="color: #009688; font-size: 15px;margin-right: 10px;">
            </fa-icon>{{kullaniciAdi}}</button>
            <button mat-menu-item (click)="logout()"><fa-icon [icon]="faLogout"  style="color: #009688;font-size: 18px;margin-right: 10px;">
            </fa-icon>Çıkış</button>
          </mat-menu>
        </mat-toolbar-row>
        <mat-toolbar-row id="mat-toolbar-row">
          <div style="margin: auto; font-size: 18px; "
            *ngIf="navbarAtributeVisibility===true">{{headerTitle}}</div>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="routerHight"></div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>

    </mat-sidenav-content>
    <!-- Add Content Here -->
  </mat-sidenav-container>
</div>